<template>
  <div
    class="container-table"
    :class="{ 'max-height-72': !mapStore.isMapMode }"
  >
    <table class="table">
      <thead>
        <tr>
          <th>
            <TableCheckbox
              :model-value="scannerControlStore.isAllResultsScannersSelected"
              :indeterminate="isCheckboxIndeterminate"
              color="info"
              density="compact"
              @update:modelValue="scannerControlStore.toggleAllResultsScanners"
            />
          </th>
          <th v-if="showAnalyser"></th>
          <THead title="id"></THead>
          <THead title="inspection_date"></THead>
          <THead
            title="construction_year"
            sortingKeys="constructionyear"
          ></THead>
          <THead title="material"></THead>
          <THead title="diameter" unit="mm"></THead>
          <THead title="exp_wt"></THead>
          <THead title="max_wt" sortingKeys="stats.max"></THead>
          <THead title="min_wt" sortingKeys="stats.min"></THead>
          <THead title="avg_wt" sortingKeys="stats.mean"></THead>
          <THead title="std_wt" sortingKeys="stats.std"></THead>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="scanner in sortedScanners"
          :key="scanner.id"
          :class="{
            active: scannerControlStore.activeScannerId === scanner.id,
          }"
          @click="handleTableRowClick(scanner)"
          @dblclick="$emit('scanner-details', scanner.id)"
        >
          <td>
            <div class="td-actions">
              <TableCheckbox
                :model-value="
                  scannerControlStore.selectedResultScannerIds.includes(
                    scanner.id
                  )
                "
                @update:modelValue="
                  scannerControlStore.toggleResultsScannerSelection(scanner.id)
                "
                color="info"
                density="compact"
                hide-details
                @click.stop
              />

              <EditScannerButton
                v-if="showEdit"
                @click="$emit('scanner-edit', scanner.id)"
              />
            </div>
          </td>
          <td v-if="showAnalyser">
            <v-btn
              size="x-small"
              variant="flat"
              color="#24e3c6"
              @click="goToAnalyser(scanner.id)"
              >Analyser</v-btn
            >
          </td>
          <td>{{ scanner.tag }}</td>
          <td>{{ scanner.inspection_date }}</td>
          <td>{{ scanner.constructionyear }}</td>
          <td>{{ scanner.material }}</td>
          <td>{{ scanner.diameter }}</td>
          <td>{{ scanner.wallthickness_expected_validation.toFixed(2) }}</td>
          <td>{{ scanner.stats.max.toFixed(2) }}</td>
          <td>{{ scanner.stats.min.toFixed(2) }}</td>
          <td>{{ scanner.stats.mean.toFixed(2) }}</td>
          <td>{{ scanner.stats.std.toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
    <v-badge
      class="xcell-button"
      :class="{ 'map-mode': mapStore.isMapMode }"
      :color="
        scannerControlStore.selectedResultsScannersCount > 0 ? 'red' : undefined
      "
      :content="scannerControlStore.selectedResultsScannersCount"
      offsetX="3"
      offsetY="3"
      :style="{
        cursor:
          scannerControlStore.selectedResultsScannersCount > 0
            ? 'pointer'
            : 'default',
      }"
    >
      <v-btn
        @click="scannerControlStore.exportResultsScanners"
        :disabled="scannerControlStore.selectedResultsScannersCount === 0"
        :loading="scannerControlStore.isAwaitingInspectionExport"
        color="green"
        icon="mdi-microsoft-excel"
        title="export to Excel file"
      ></v-btn>
    </v-badge>
    <div v-if="sortedScanners.length === 0" class="blank">
      {{ $t("common.no_items") }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

import { THead } from "@/components";
import { TableCheckbox } from "@/components/table-components/controls";
import EditScannerButton from "@/pages/scanner-control/components/EditScannerButton.vue";

import { getSortedArray, getNextSortingOrder } from "@/utils/tableHelpers.js";
import { useAuthStore, useMapStore, useScannerControlStore } from "@/store";
import { PAGES } from "@/constants/pagesNames";

export default {
  name: "ResultsScannersTable",

  emits: ["scanner-edit", "scanner-details", "scanner-select"],

  components: { THead, TableCheckbox, EditScannerButton },

  provide() {
    return {
      sortedBy: computed(() => this.sortedBy),
      sortFunction: this.setSortedBy,
      localePath: "pipe_scanner.table_titles.",
    };
  },

  setup() {
    return {
      authStore: useAuthStore(),
      mapStore: useMapStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  data: () => ({
    sortedBy: {
      keyName: null,
      order: "asc",
    },
  }),

  computed: {
    showEdit() {
      return this.authStore.userPermissions.includes("edit:upload");
    },

    showAnalyser() {
      return this.authStore.userPermissions.includes("use:analyser");
    },

    sortedScanners() {
      return getSortedArray(
        this.scannerControlStore.filteredResultsScanners,
        this.sortedBy.order,
        this.sortedBy.keyName
      );
    },

    isCheckboxIndeterminate() {
      return (
        this.scannerControlStore.selectedResultsScannersCount > 0 &&
        !this.scannerControlStore.isAllResultsScannersSelected
      );
    },
  },

  methods: {
    setSortedBy(key) {
      const sortedBy = getNextSortingOrder(this.sortedBy, key);
      this.sortedBy = sortedBy;
    },

    goToAnalyser(id) {
      this.$router.push(`${PAGES.SCANNER_CONTROL.PATH}/${id}/analyser`);
    },

    handleTableRowClick(scanner) {
      this.scannerControlStore.toggleActiveScannerId(scanner.id);
      this.$emit("scanner-select", scanner);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-table {
  &.max-height-72 {
    max-height: 72vh !important;
  }

  .td-actions {
    cursor: default;
    display: flex;
    flex-direction: row;

    .mdi-square-edit-outline {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  .xcell-button {
    position: absolute;
    right: 70px;
    bottom: 40px;

    &.map-mode {
      right: 30px;
      bottom: 40px;
    }
  }

  .blank {
    text-align: center;
    letter-spacing: 0.5px;
    padding: 15px 0;
    width: 100%;
    cursor: default;
    font-weight: 700;
    color: gray;
  }
}
</style>
