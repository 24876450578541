<template>
  <BaseWidget
    :title="$t('pipe_parts.widgets.table.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <FilterPopupMenu
        v-model:show="filtersVisible"
        :typeOptions="typeOptions"
        :materialOptions="materialOptions"
        :hasActiveFilters="pipePartsStore.hasActiveFilters"
      />
    </template>

    <PipePartsTable />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { PipePartsTable } from "@/pages/pipe-parts/components";
import FilterPopupMenu from "../FilterPopupMenu";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsTableWidget",

  components: {
    BaseWidget,
    PipePartsTable,
    FilterPopupMenu,
  },

  data() {
    return {
      filtersVisible: false,
    };
  },

  props: {
    typeOptions: {
      type: Array,
      required: true,
    },

    materialOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },
};
</script>
