<template>
  <VBtn
    variant="text"
    density="compact"
    icon="mdi-information-variant-circle-outline"
    @click="$emit('click:info')"
    @mousedown.stop
  />
</template>

<script>
export default {
  name: "GradientLegendToolbar",

  emits: {
    "click:info": null,
  },
};
</script>
