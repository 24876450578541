<template>
  <BaseWidget
    :title="$t('pipe_parts.widgets.heightProfile.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <div
        class="toggle-anomalies-container"
        @click="pipePartsStore.toggleAnomaliesVisibility"
      >
        <v-switch
          :modelValue="pipePartsStore.showAnomalies"
          density="compact"
          hide-details
        />
        {{ $t("pipe_parts.widgets.heightProfile.show_anomalies") }}
      </div>
    </template>

    <PipePartsHeightChart :visibleParts="visibleParts" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { PipePartsHeightChart } from "@/pages/pipe-parts/components";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsHeightProfile",

  components: {
    BaseWidget,
    PipePartsHeightChart,
  },

  props: {
    visibleParts: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },
};
</script>

<style scoped lang="scss">
.toggle-anomalies-container {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;

  .v-switch {
    margin-right: 1rem;
  }
}
</style>
