import { baseApi } from "@/baseApi";

import CancaledAPI from "@/utils/CanceledApi.js";

export class AdministrationAPI extends CancaledAPI {
  async getServiceClients(service) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/client/administration/v1/groups-complete/${service}`,
      method: "get",
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async postInlineInspections(form) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/client/administration/v1/inline-inspections/`,
      method: "post",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      data: form,
    });

    return data;
  }

  async postPipescannerInspections(form) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/client/administration/v1/pipescanner-inspections/`,
      method: "post",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      data: form,
    });

    return data;
  }

  async editPipeline(form) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/inline-inspections/edit-pipeline`,
      method: "post",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      data: form,
    });

    return data;
  }
}

export const administrationAPI = new AdministrationAPI();
