<template>
  <BaseWidget
    :title="$t('joints.widgets.gradient.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <GradientLegendToolbar @click:info="$emit('click:info')" />
    </template>

    <JointsGradient
      :gradientMode="gradientMode"
      :gradientColor="gradientColor"
    />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { JointsGradient } from "@/pages/joints/components";
import GradientLegendToolbar from "../GradientLegendToolbar";

export default {
  name: "GradientWidgetLegend",

  components: {
    BaseWidget,
    JointsGradient,
    GradientLegendToolbar,
  },

  props: {
    gradientMode: {
      type: String,
      required: true,
    },

    gradientColor: {
      type: Array,
      required: true,
    },
  },

  emits: {
    "click:info": null,
  },
};
</script>
