<template>
  <TableContainer
    v-if="modifiedAnomalies.length > 0"
    :resizable="mapStore.isMapMode"
  >
    <AnomaliesTableMapmode
      v-if="mapStore.isMapMode"
      :modified-anomalies="modifiedAnomalies"
      :is-anomaly-active="isAnomalyActive"
      @click:row="handleAnomalySelect"
      @toggle:checkbox="handleRowCheckboxSelect"
    />
    <AnomaliesTableRegular
      v-else
      :modified-anomalies="modifiedAnomalies"
      :is-anomaly-active="isAnomalyActive"
      @click:row="handleAnomalySelect"
      @toggle:checkbox="handleRowCheckboxSelect"
    />
    <DigUpTableBtn
      v-if="checkedLength > 0"
      :badgeCount="checkedLength"
      :isMapMode="mapStore.isMapMode"
    />
  </TableContainer>
</template>

<script>
import AnomaliesTableMapmode from "./AnomaliesTableMapmode.vue";
import AnomaliesTableRegular from "./AnomaliesTableRegular.vue";
import { TableContainer } from "@/components/table-components/containers";
import { DigUpTableBtn } from "@/features/dig-up/components";
import { useAnomaliesStore, useMapStore } from "@/store";

export default {
  name: "AnomaliesTable",

  components: {
    DigUpTableBtn,
    AnomaliesTableMapmode,
    AnomaliesTableRegular,
    TableContainer,
  },

  setup() {
    return {
      mapStore: useMapStore(),
      anomaliesStore: useAnomaliesStore(),
    };
  },

  computed: {
    modifiedAnomalies() {
      return this.anomaliesStore.filteredAnomalies.map((anomaly) => {
        const pipe_book_id = anomaly.pipe_parts[0].pipe_book_id;
        const [longitude, latitude] = anomaly.centerGeom;
        return {
          ...anomaly,
          pipe_book_id,
          longitude,
          latitude,
        };
      });
    },

    checkedLength() {
      return this.anomaliesStore.checkedAnomaliesSelector.size;
    },
  },

  methods: {
    handleAnomalySelect(id) {
      this.anomaliesStore.toggleSelectedAnomaly(id);
    },

    handleRowCheckboxSelect(id) {
      if (this.anomaliesStore.checkedAnomaliesSelector.has(id)) {
        this.anomaliesStore.checkedAnomaliesSelector.clear();
      } else {
        this.anomaliesStore.checkedAnomaliesSelector.set([id]);
      }
    },

    isAnomalyActive(anomaly) {
      return anomaly.id === this.anomaliesStore.selectedAnomalyId;
    },
  },
};
</script>
