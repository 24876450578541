<template>
  <BaseWidget
    :title="$t('dashboard.widgets.color.maximize_title')"
    v-bind="$attrs"
  >
    <WMSSelect
      :wmsLayers="wmsLayers"
      :colorMode="colorMode"
      :colorOptions="colorOptions"
      @update:wmsLayers="$emit('update:wmsLayers', $event)"
      @update:colorMode="$emit('update:colorMode', $event)"
    />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { WMSSelect } from "@/features/wms/components";

export default {
  name: "WMSWidget",

  components: {
    BaseWidget,
    WMSSelect,
  },

  emits: {
    "update:wmsLayers": (value) => Array.isArray(value),
    "update:colorMode": (value) => typeof value === "string",
  },

  props: {
    wmsLayers: {
      type: Array,
      required: true,
    },

    colorMode: {
      type: String,
    },

    colorOptions: {
      type: Array,
    },
  },
};
</script>
