<template>
  <div class="wrapper">
    <span class="title">{{ title }}</span>
    <div
      :class="computeClass(getValues(key, 'text'))"
      v-for="key in legendList"
      :key="key"
      @click="pipePartsStore.toggleSelectedLegend(getValues(key, 'text'))"
    >
      <div
        class="dot"
        :style="{
          backgroundColor: getValues(key, 'color'),
        }"
      ></div>
      <div>{{ getValues(key, "text") }}</div>
    </div>
  </div>
</template>

<script>
import {
  anomalyTypeColors,
  getDegradationColorPP,
  getRemainingLifeColor,
} from "@/colorLegends.js";
import {
  materialColors,
  roadTypeColors,
  soilTypeColors,
  jointTypeColors,
} from "@/colorLegends.js";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsLegend",

  inject: {
    materials: { default: [] },
    pipeParts: { default: [] },
  },

  props: {
    title: String,
  },

  setup() {
    return {
      anomalyTypeColors,
      pipePartsStore: usePipePartsStore(),
    };
  },

  computed: {
    legendList() {
      let allList = [];

      switch (this.pipePartsStore.selectedGradientMode) {
        case "material":
          return this.materials;

        case "remaining_life_calculation.soil_type":
          allList = this.pipeParts.map(
            (pp) => pp.remaining_life_calculation.soil_type
          );
          return Array.from(new Set(allList));

        case "remaining_life_calculation.road_type":
          allList = this.pipeParts.map(
            (pp) => pp.remaining_life_calculation.road_type
          );
          allList = allList.map((type) => {
            if (roadTypeColors.hasOwnProperty(type)) {
              return type;
            }
            return "other";
          });
          return Array.from(new Set(allList));

        case "degradation_level":
          return getDegradationColorPP(this.$t);

        case "remaining_life_calculation.remaining_life_group":
          return getRemainingLifeColor(this.$t);

        default:
          return [];
      }
    },
  },

  methods: {
    computeClass(item) {
      if (!this.pipePartsStore.selectedLegend) {
        return ["type", "selected"];
      }

      return [
        "type",
        item === this.pipePartsStore.selectedLegend ? "selected" : "",
      ];
    },

    getValues(key, type) {
      switch (this.pipePartsStore.selectedGradientMode) {
        case "material":
          if (type === "text") {
            return this.$t(`materials.${materialColors[key][type]}`);
          } else {
            return materialColors[key][type];
          }

        case "remaining_life_calculation.soil_type":
          return soilTypeColors[key][type];

        case "remaining_life_calculation.road_type":
          return roadTypeColors[key][type];

        case "degradation_level":
        case "remaining_life_calculation.remaining_life_group":
          return key[type];

        case "joint_type":
          if (type === "text") {
            return this.$t(`joints.types.${jointTypeColors[key][type]}`);
          } else {
            return jointTypeColors[key][type];
          }

        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 8px 12px;
  border-radius: 6px;
  width: fit-content;
  background-color: whitesmoke;

  display: flex;
  flex-direction: column;
  .title {
    font-size: 1rem;
  }

  .type {
    display: flex;
    align-items: center;
    margin-top: 6px;
    cursor: pointer;
    opacity: 0.3;
    &.selected {
      opacity: 1;
    }

    .dot {
      margin-right: 6px;
      margin-bottom: 3px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }
}
</style>
