<template>
  <ContainedModal v-model="show" width="50%" min-width="650px">
    <v-card rounded="lg">
      <v-card-title>
        {{ infoList["title"] ? infoList["title"] : "Information" }}
        <v-icon icon="mdi-close" @click="close" />
      </v-card-title>
      <v-card-text>
        <div class="md-layout-item info-container">
          <template v-for="(value, key, index) in infoList">
            <!-- Subtitle -->
            <p v-if="key.includes('subtitle')" :key="index">
              <strong>{{ value }}</strong>
            </p>
            <!-- Text -->
            <p v-if="key.includes('text')" :key="index">{{ value }}</p>
            <!-- List -->
            <ol v-else-if="key.includes('list')" :key="index + 'list'">
              <li v-for="(item, i) in value" :key="i">{{ item }}</li>
            </ol>
            <!-- Table -->
            <table
              v-else-if="key.includes('table')"
              :key="index + 'table'"
              class="info-table"
            >
              <thead>
                <tr>
                  <th v-for="(header, i) in value.headers" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in value.rows" :key="i">
                  <td v-for="(cell, j) in row" :key="j">{{ cell }}</td>
                </tr>
              </tbody>
            </table>
            <!-- Image -->
            <img
              v-else-if="key.includes('image')"
              class="legend-img"
              :src="require(`@/assets/img/legends/${value.file}`)"
              :style="{ width: `${value.size}% !important` }"
              alt="image"
              :key="index + 'image'"
            />
          </template>
        </div>
      </v-card-text>
    </v-card>
  </ContainedModal>
</template>

<script>
import ContainedModal from "../ContainedModal.vue";

import legendsInformation from "@/legendsInfo.json";

export default {
  name: "LegendInfoModal",

  components: { ContainedModal },

  props: {
    page: {
      type: String,
      required: true,
    },
    selectMode: {
      type: String,
      required: true,
    },
    materials: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },

  computed: {
    /**
     * Computed selected info by page and select mode
     * @return {string || null} selected info
     */
    selectedInfo() {
      // FIXME: unreliable code.
      if (this.page === "joints") {
        switch (this.selectMode) {
          case "degradation_level":
            return this.selectMode;

          case "angle_vertical":
          case "angle_horizontal":
            return "angles";

          case "gap_width_mean":
          case "gap_width_max":
            return "gap_width";

          default:
            return null;
        }
      } else if (this.page === "pipeParts") {
        switch (this.selectMode) {
          case "degradation_level":
            return this.selectMode;

          case "ovality_mean":
          case "ovality_max":
            return "ovality";

          case "horizontal_out_of_straight":
          case "vertical_out_of_straight":
            return "axial_deformation";

          case "remaining_mean":
          case "remaining_min":
          case "remaining_max":
          case "remaining_std":
          case "degradated_total_mean":
          case "degradated_total_min":
          case "degradated_total_max":
          case "degradated_total_std":
            return "wall_thickness";

          default:
            return null;
        }
      } else {
        return null;
      }
    },
    /**
     * Constructs information list from legendsInfo.json by selected info
     * @return {array} array of information list for rendering
     */
    infoList() {
      if (this.selectedInfo) {
        if (this.selectedInfo === "wall_thickness" && this.materials) {
          const wallThicknessList = structuredClone(
            legendsInformation[this.page][this.selectedInfo][this.$i18n.locale]
          );
          if (!this.materials.includes("Plate reinforced Concrete")) {
            delete wallThicknessList.text_1;
          }
          if (!this.materials.includes("AC")) {
            delete wallThicknessList.subtitle;
            delete wallThicknessList.text_2;
          }
          return wallThicknessList;
        }
        return legendsInformation[this.page][this.selectedInfo][
          this.$i18n.locale
        ];
      }
      return { text: "No information!" };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.v-card-title {
  font-size: 1.1rem;
  color: $cyan-main;
  background: $black-primary;
  .v-icon {
    position: absolute;
    font-size: 24px;
    top: 12px;
    right: 12px;
    color: aliceblue;
    cursor: pointer;
  }
}

.v-card-text {
  padding: 1.6rem 2rem 2rem !important;
  font-size: 14px !important;
  p {
    margin-bottom: 14px;
  }
  ol {
    margin: 20px;
  }

  .info-table {
    border: 2px solid #bbbbbb;
    border-spacing: 0;
    margin: 0 auto;

    thead {
      tr {
        th {
          font-weight: 600 !important;
          border-bottom: 2px solid #bbbbbb;
          padding: 2px 8px;
        }
      }
    }

    tbody {
      overflow-y: auto;

      tr {
        td {
          border-top: 1px solid #dddddd;
          padding: 2px 8px;
          text-align: center;
        }
      }
    }
  }
}
</style>
