<template>
  <BaseWidget
    :title="$t('dashboard.widgets.heightProfile.maximize_title')"
    v-bind="$attrs"
  >
    <AnomaliesHeightChart :visibleDistance="visibleDistance" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import AnomaliesHeightChart from "@/pages/anomaly/components/AnomaliesHeightChart.vue";

export default {
  name: "AnomaliesTableWidget",

  components: {
    BaseWidget,
    AnomaliesHeightChart,
  },

  props: {
    visibleDistance: {
      type: Array,
      required: true,
    },
  },
};
</script>
