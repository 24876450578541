<template>
  <div class="gradient-block">
    <div class="gradient-scale" :style="gradientScaleStyle"></div>
    <div class="gradient-ruler">
      <span>{{ min }}</span>
      <span>{{ mid.toFixed(2) }}</span>
      <span>{{ max }}</span>
    </div>
  </div>
</template>

<script>
import { gradientColorArr } from "@/colorLegends.js";
import { useSafetyFactorStore } from "@/store";

export default {
  name: "SafetyGradient",

  setup() {
    return {
      safetyFactorStore: useSafetyFactorStore(),
    };
  },

  computed: {
    min() {
      return +this.safetyFactorStore.gradientMinMaxValues[0].toFixed(2);
    },

    max() {
      return +this.safetyFactorStore.gradientMinMaxValues[1].toFixed(2);
    },

    mid() {
      return (this.min + this.max) / 2;
    },

    gradientScaleStyle() {
      if (this.min === this.max) {
        return {
          background: gradientColorArr[0],
        };
      }

      return {
        background: `linear-gradient(to top,
                            ${gradientColorArr[0]} 0%,
                            ${gradientColorArr[1]} 50%,
                            ${gradientColorArr[2]} 100%)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.gradient-block {
  user-select: none;
  min-height: 250px;
  height: 100%;
  display: flex;
  margin-left: 1rem;
  padding: 1.2rem 0;

  .gradient-scale {
    width: 1.5rem;
  }

  .gradient-ruler {
    font-size: 1rem;
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    span {
      white-space: nowrap;
    }
  }
}
</style>
