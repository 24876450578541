<template>
  <RangeSlider
    :disabled="scannerControlStore.selectedScannerType !== ScannerType.Results"
    :title="`${$t('pipe_scanner.filters.diameter')}:`"
    :model-value="scannerControlStore.filters.diameterRange"
    @update:modelValue="
      (value) => scannerControlStore.setFilters({ diameterRange: value })
    "
    :min="scannerControlStore.minMaxDiameters[0]"
    :max="scannerControlStore.minMaxDiameters[1]"
    :step="1"
  />
</template>

<script>
import { RangeSlider } from "@/components";
import { ScannerType } from "@/pages/scanner-control/config";
import { useScannerControlStore } from "@/store";

export default {
  setup() {
    return {
      ScannerType,
      scannerControlStore: useScannerControlStore(),
    };
  },
  components: {
    RangeSlider,
  },
};
</script>
