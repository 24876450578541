<template>
  <v-list density="compact">
    <v-list-item
      v-if="showOpenDetailed"
      value="detailed"
      @click="onOpenDetailedClick"
    >
      <v-list-item-title>
        {{ $t("context_menu.open_detailed") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="showEdit"
      value="edit-scanner"
      @click="onEditScannerClick"
    >
      <v-list-item-title>
        {{ $t("context_menu.edit_scanner") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { RESULTS } from "@/features/pipescanner/constants";
import { useAuthStore } from "@/store";

export default {
  name: "ScannerMenu",
  props: {
    contextMenuParams: Object,
    openDetailedModal: Function,
    openEditModel: Function,
  },

  setup() {
    return {
      authStore: useAuthStore(),
    };
  },

  computed: {
    showOpenDetailed() {
      return this.contextMenuParams.status === RESULTS;
    },

    showEdit() {
      return this.authStore.userPermissions.includes("edit:upload");
    },

    scannersOnMarker() {
      return this.contextMenuParams.scanners;
    },

    firstScannerId() {
      return this.scannersOnMarker[0].id;
    },
  },

  methods: {
    onOpenDetailedClick() {
      this.openDetailedModal(this.firstScannerId, this.scannersOnMarker);
    },

    onEditScannerClick() {
      this.openEditModel(
        this.firstScannerId,
        this.contextMenuParams.status,
        this.scannersOnMarker
      );
    },
  },
};
</script>
