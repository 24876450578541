<template>
  <BaseWidget
    :title="$t('joints.widgets.gradient.maximize_title')"
    v-bind="$attrs"
  >
    <SafetyGradient />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { SafetyGradient } from "@/pages/safety-factor/components";

export default {
  name: "SafetyFactorGradientWidget",

  components: {
    BaseWidget,
    SafetyGradient,
  },
};
</script>
