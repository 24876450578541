import { getNestedValueFromObject } from "./objectHelpers";

export const getValuesOfComparedObjects = (propertyPath, a, b) => {
  const val1 = getNestedValueFromObject(a, propertyPath);
  const val2 = getNestedValueFromObject(b, propertyPath);

  return { val1, val2 };
};

export const getSortedArray = (array = [], order = "asc", sortKey) => {
  if (!array) return [];
  const sortedArray = [...array];

  if (order === "asc" && sortKey) {
    sortedArray.sort((a, b) => {
      const { val1, val2 } = getValuesOfComparedObjects(sortKey, a, b);
      if (val1 > val2) {
        return 1;
      }
      if (val1 < val2) {
        return -1;
      }
      return 0;
    });
  }

  if (order === "desc" && sortKey) {
    sortedArray.sort((a, b) => {
      const { val1, val2 } = getValuesOfComparedObjects(sortKey, a, b);
      if (val1 < val2) {
        return 1;
      }
      if (val1 > val2) {
        return -1;
      }
      return 0;
    });
  }

  return sortedArray;
};

export function filterExpandableHeaders(headers, expandableHeadersState) {
  function fitlerChildren(children, isExpanded) {
    return children.filter(
      (child) => child.expandable === undefined || isExpanded
    );
  }

  return headers.map((header) => {
    if (expandableHeadersState[header.key] !== undefined) {
      return {
        ...header,
        children: fitlerChildren(
          header.children,
          expandableHeadersState[header.key]
        ),
      };
    }

    return header;
  });
}

export function getNextSortingOrder(sortedBy, key) {
  if (sortedBy.keyName !== key) {
    return {
      keyName: key,
      order: "asc",
    };
  }

  switch (sortedBy.order) {
    case "asc":
      return {
        ...sortedBy,
        order: "desc",
      };

    case "desc":
      return {
        keyName: null,
        order: "asc",
      };

    default:
      break;
  }
}
