<template>
  <!-- if height gets undefined for whatever reason, virtual scroll breaks -->
  <VirtualTable
    :height="containerHeight || 400"
    :header-slots="headerSlots"
    :item-slots="itemSlots"
    @click:row="(item) => $emit('click:row', item)"
    @toggle:checkbox="(itemId) => $emit('toggle:checkbox', itemId)"
    v-bind="$attrs"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </VirtualTable>
</template>

<script>
import VirtualTable from "./VirtualTable";

export default {
  name: "ResizableVirtualTable",

  components: { VirtualTable },

  props: {
    headerSlots: Array,
    itemSlots: Array,
  },

  inject: ["containerHeight"],

  emits: ["click:row", "toggle:checkbox"],
};
</script>
