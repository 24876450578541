<template>
  <PipelinesMapmode
    v-if="mapStore.isMapMode"
    :onSelectPipelineId="onSelectPipelineId"
  />
  <Pipelines v-else :onSelectPipelineId="onSelectPipelineId" />
</template>

<script>
import { Pipelines, PipelinesMapmode } from "./views";
import { useGlobalStore, useMapStore, usePipelinesStore } from "@/store";
import { useSelectedPipelineIdInURL } from "@/utils/urlHelpers";

export default {
  components: { PipelinesMapmode, Pipelines },
  data() {
    return {
      requests: new Map(),
    };
  },

  setup() {
    const { setPipelineIdToURL } = useSelectedPipelineIdInURL();

    return {
      setPipelineIdToURL,
      pipelinesStore: usePipelinesStore(),
      mapStore: useMapStore(),
      globalStore: useGlobalStore(),
    };
  },

  methods: {
    onSelectPipelineId(pipelineId) {
      this.setPipelineIdToURL(pipelineId);
      this.pipelinesStore.setSelectedPipelineId(pipelineId);
    },
  },

  watch: {
    "pipelinesStore.selectedPipeline"(pipeline) {
      this.globalStore.resetFilters();
      this.pipelinesStore.setSelectedInspectionId(pipeline?.inspections[0].id);
      if (pipeline) {
        this.pipelinesStore.getPipeComments(pipeline.id);
      }
    },

    "pipelinesStore.selectedInspectionId"(newId) {
      this.pipelinesStore.cancelRequests();
      if (newId) {
        this.pipelinesStore.getInspectionStats();
      }
    },
  },
};
</script>
