<template>
  <BaseWidget
    :title="$t('dashboard.widgets.heightProfile.maximize_title')"
    v-bind="$attrs"
  >
    <DashboardHeightChart @changePoint="$emit('changePoint', $event)" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { DashboardHeightChart } from "@/pages/dashboard/components";

export default {
  name: "HeightProfileWidget",

  components: {
    BaseWidget,
    DashboardHeightChart,
  },

  emits: {
    changePoint: (coordinates) => Array.isArray(coordinates),
  },
};
</script>
