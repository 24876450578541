<template>
  <div style="height: 450px; margin-bottom: 1.2rem">
    <ModifiedMapbox ref="mapboxMap" hideMapControls style="border-radius: 4px">
      <MapboxMarker v-if="marker" :lng-lat="marker" color="orange" />
      <template v-slot:sources>
        <MapboxSource
          v-for="stats in statsMapData"
          :key="stats.id"
          :id="stats.id"
          :options="stats.sourceOptions"
        />
        <MapboxLayer
          v-for="stats in statsMapData"
          :key="stats.id"
          :id="`layer_${stats.id}`"
          :options="stats.layerOptions"
        />
      </template>
    </ModifiedMapbox>
  </div>
</template>

<script>
import {
  MapboxLayer,
  MapboxSource,
  MapboxMarker,
} from "@studiometa/vue-mapbox-gl";
import { ModifiedMapbox } from "@/components";
import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";
import { usePipelinesStore } from "@/store";

export default {
  name: "PipelineMap",
  components: {
    ModifiedMapbox,
    MapboxLayer,
    MapboxSource,
    MapboxMarker,
  },
  props: {
    isLoading: Boolean,
    marker: null || Array,
  },
  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
    };
  },
  data() {
    return {
      mapMarker: null,
    };
  },

  computed: {
    h2sMapData() {
      return {
        id: "geoJsonSourceH2s",
        type: "linestring",
        sourceOptions: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: this.pipelinesStore.selectedInspectionStats.h2s_geom?.map(
              (el) => {
                return {
                  type: "Feature",
                  geometry: el,
                };
              }
            ),
          },
        },
        layerOptions: {
          source: "geoJsonSourceH2s",
          type: "line",
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "#FC614F",
            "line-width": 10,
            "line-opacity": 1,
          },
        },
      };
    },

    localLeachingMapData() {
      return {
        id: "geoJsonSourceLocalLeaching",
        type: "points",
        sourceOptions: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features:
              this.pipelinesStore.selectedInspectionStats.local_leaching_geom?.map(
                (el) => {
                  return {
                    type: "Feature",
                    geometry: el,
                  };
                }
              ),
          },
        },
        layerOptions: {
          source: "geoJsonSourceLocalLeaching",
          type: "circle",
          layout: {
            visibility: "visible",
          },
          paint: {
            "circle-radius": {
              base: 1.75,
              stops: [
                [12, 2],
                [22, 180],
              ],
            },
            "circle-color": "#AB63FA",
            "circle-opacity": 0.2,
          },
        },
      };
    },

    statsMapData() {
      const mapData = [];
      mapData.push(getPipeMapData(this.pipelinesStore.selectedInspectionStats));
      mapData.push(this.h2sMapData);
      mapData.push(this.localLeachingMapData);

      return mapData;
    },
  },
};
</script>
