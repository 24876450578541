<template>
  <div class="widgets-bar">
    <WidgetTab
      v-for="tab in tabs"
      :key="tab.key"
      :tab="tab"
      :config="tabsConfig[tab.key]"
      @maximize="$emit('maximize', $event)"
    />
  </div>
</template>

<script>
import WidgetTab from "./WidgetTab/WidgetTab.vue";

export default {
  name: "WidgetsBar",

  emits: ["maximize"],

  components: {
    WidgetTab,
  },

  props: {
    tabs: {
      type: Array,
      required: true,
    },

    tabsConfig: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.widgets-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 4px 10px;
  z-index: 25;
}
</style>
