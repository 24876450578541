<template>
  <div class="slider">
    <RangeSlider
      :title="$t('joints.filters.gap_width_max')"
      :model-value="jointsStore.jointFilters.selectedGapMax"
      @update:modelValue="
        (value) => jointsStore.setFilters({ selectedGapMax: value })
      "
      :min="jointsStore.minMaxGapMax[0]"
      :max="jointsStore.minMaxGapMax[1]"
      :step="$units.current === 'metric' ? 1 : 0.01"
    />
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { useJointsStore } from "@/store";

export default {
  setup() {
    return {
      jointsStore: useJointsStore(),
    };
  },
  components: {
    RangeSlider,
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 25%;
}
</style>
