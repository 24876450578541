<template>
  <WidgetCard
    @minimize="minimizeWidget"
    v-bind="{ ...widgetConfig, ...$attrs }"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </WidgetCard>
</template>

<script>
import { WidgetCard } from "./WidgetCard";

export default {
  name: "BaseWidget",

  components: {
    WidgetCard,
  },

  inject: ["minimizeWidget", "widgetConfig"],
};
</script>
