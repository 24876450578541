<template>
  <MainLayoutVue v-if="isAuthenticated">
    <router-view />
  </MainLayoutVue>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

import MainLayoutVue from "./layouts/MainLayout/MainLayout.vue";

export default {
  components: {
    MainLayoutVue,
  },
  data() {
    return {
      isAuthenticated: useAuth0().isAuthenticated,
    };
  },
};
</script>

<style>
/* TODO: move this import into main.js */
@import "vue-skeletor/dist/vue-skeletor.css";
</style>
