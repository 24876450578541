<template>
  <v-select
    :label="$t('anomalies.filters.materials')"
    :model-value="anomaliesStore.anomalyFilters.selectedMaterials"
    @update:modelValue="
      (value) => anomaliesStore.setFilters({ selectedMaterials: value })
    "
    :items="materialsOptions"
    item-title="title"
    item-value="value"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { useAnomaliesStore } from "@/store";

export default {
  setup() {
    return {
      anomaliesStore: useAnomaliesStore(),
    };
  },

  computed: {
    anomaliesTypesOptions() {
      return this.anomaliesStore.anomaliesTypes.map((type) => ({
        value: type,
        title: this.$t(`anomalies.types.${type}`),
      }));
    },

    materialsOptions() {
      return this.anomaliesStore.filterMaterials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },
};
</script>
