<template>
  <HeightChart
    :additionalTraces="additionalTraces"
    :layoutChanges="layoutChanges"
  />
</template>

<script setup>
import { HeightChart } from "@/components/charts/shared";

import { anomalyTypeColors } from "@/colorLegends.js";
import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { watchDebounced } from "@vueuse/core";
import {
  useAnomaliesStore,
  usePipelinesStore,
  usePipePartsStore,
} from "@/store";

const props = defineProps(["visibleParts"]);
defineEmits(["graph-mousedown"]);

const { t } = useI18n();

const anomaliesStore = useAnomaliesStore();
const pipelinesStore = usePipelinesStore();
const pipePartsStore = usePipePartsStore();

const units = inject("units");

const heightRangeWithAnomalies = computed(() => {
  if (!anomaliesStore.anomalies) {
    return pipelinesStore.heightRange;
  }

  const zAnomalies = anomaliesStore.anomalies.map(({ z_coordinate }) =>
    units.convert(z_coordinate, "m")
  );

  const [minHeight, maxHeight] = pipelinesStore.heightRange;
  const newMin = Math.min(minHeight, ...zAnomalies);
  const newMax = Math.max(maxHeight, ...zAnomalies);

  return [newMin, newMax];
});

const chartHeightRange = computed(() => {
  const [minHeight, maxHeight] = heightRangeWithAnomalies.value;
  return [Math.trunc(minHeight - 5), Math.trunc(maxHeight + 2)];
});

const anomaliesTraces = computed(() => {
  if (!pipePartsStore.showAnomalies || !anomaliesStore.anomalies.length > 0) {
    return null;
  }

  const anomaliesTraces = [];
  const showLegendArr = [];

  anomaliesStore.anomalies.forEach((an) => {
    let showlegend = false;

    if (!showLegendArr.includes(an.anomaly_type)) {
      showLegendArr.push(an.anomaly_type);
      showlegend = true;
    }

    anomaliesTraces.push({
      x: [units.convert(an.distance, "m"), units.convert(an.distance, "m")],
      y: chartHeightRange.value,
      fill: "tozeroy",
      type: "scatter",
      mode: "none",
      fillcolor: anomalyTypeColors[an.anomaly_type],
      line: {
        color: anomalyTypeColors[an.anomaly_type],
      },
      name: t(`anomalies.types.${an.anomaly_type}`),
      legendgroup: an.anomaly_type,
      opacity: 1.0,
      fillopacity: 1.0,
      showlegend,
    });

    anomaliesTraces.push({
      x: [
        units.convert(an.distance + an.length, "m"),
        units.convert(an.distance + an.length, "m"),
      ],
      y: chartHeightRange.value,
      fill: "tonexty",
      type: "scatter",
      name: an.anomaly_type,
      legendgroup: an.anomaly_type,
      fillcolor: anomalyTypeColors[an.anomaly_type],
      line: {
        color: anomalyTypeColors[an.anomaly_type],
      },
      opacity: 1.0,
      fillopacity: 1.0,
      mode: "none",
      showlegend: false,
    });
  });

  return anomaliesTraces;
});

const pipePartsTraces = computed(() => {
  if (!pipePartsStore.selectedPipePart) {
    return null;
  }

  const pipePartsTraces = [];
  let showlegend = true;

  pipePartsTraces.push({
    x: [
      pipePartsStore.selectedPipePart.distance,
      pipePartsStore.selectedPipePart.distance,
    ],
    y: chartHeightRange.value,
    fill: "tozeroy",
    type: "scatter",
    mode: "none",
    fillcolor: "#6b0b0b",
    line: {
      color: "#6b0b0b",
    },
    name: t("pipe_parts.details.plotly.pipe_part"),
    legendgroup: "PipePart",
    opacity: 1.0,
    fillopacity: 1.0,
    showlegend,
  });

  showlegend = false;

  pipePartsTraces.push({
    x: [
      pipePartsStore.selectedPipePart.distance + 1,
      pipePartsStore.selectedPipePart.distance + 1,
    ],
    y: chartHeightRange.value,
    fill: "tonexty",
    type: "scatter",
    name: t("pipe_parts.details.plotly.pipe_part"),
    legendgroup: "PipePart",
    fillcolor: "#6b0b0b",
    line: {
      color: "#6b0b0b",
    },
    opacity: 1.0,
    fillopacity: 1.0,
    mode: "none",
    showlegend,
  });

  return pipePartsTraces;
});

const additionalTraces = computed(() => {
  let additionalTraces = [];

  additionalTraces = additionalTraces.concat(
    anomaliesTraces.value,
    pipePartsTraces.value
  );
  additionalTraces = additionalTraces.filter((trace) => trace !== null);

  return additionalTraces;
});

const layoutChanges = ref({});
watchDebounced(
  () => props.visibleParts,
  () => {
    const newLayoutChanges = {
      xaxis: {
        title: `${t(
          "pipe_parts.details.height_profile.xaxis_name"
        )} (${units.getAbbr("m")})`,
      },

      yaxis: {
        title: `${t(
          "pipe_parts.details.height_profile.yaxis_name"
        )} (${units.getAbbr("m")})`,
      },
    };

    if (props.visibleParts && props.visibleParts.length) {
      const visibleDistance = props.visibleParts.map((pp) => pp.distance);
      const dMax = Math.max(...visibleDistance);
      const dMin = Math.min(...visibleDistance);
      newLayoutChanges.xaxis["range"] = [dMin, dMax];
    }

    layoutChanges.value = newLayoutChanges;
  },
  { debounce: 1000, immediate: true }
);
</script>
