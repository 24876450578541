<template>
  <PlotlyGraph
    v-if="chartReady"
    :data="data"
    :layout="layout"
    :config="{ displayModeBar: false }"
    @mousedown.stop
    v-bind="$attrs"
  />
  <ChartSkeleton v-else />
</template>

<script>
import PlotlyGraph from "@/components/PlotlyGraph/PlotlyGraph.vue";
import { ChartSkeleton } from "@/components/skeletons";

import { mergeChangesRecursively } from "@/utils/objects";
import { SharedHeightChartTrace } from "./config";
import { usePipelinesStore } from "@/store";

export default {
  name: "HeightChart",

  components: {
    PlotlyGraph,
    ChartSkeleton,
  },

  props: {
    additionalTraces: {
      type: Array,
      required: true,
    },

    layoutChanges: {
      type: Object,
      required: true,
    },

    sharedTraces: {
      type: Array,
      default: () => [
        SharedHeightChartTrace.Elevation,
        SharedHeightChartTrace.HeightProfile,
        SharedHeightChartTrace.Depth,
      ],
    },
  },

  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
    };
  },

  computed: {
    chartReady() {
      return (
        !this.pipelinesStore.isLoadingStats &&
        !this.pipelinesStore.isLoadingPipelineElevation
      );
    },

    distance() {
      return this.pipelinesStore.selectedInspectionStats.height_profile.distance.map(
        (d) => this.$units.convert(d, "m")
      );
    },

    zHeightProfile() {
      return this.pipelinesStore.selectedInspectionStats.height_profile.z.map(
        (zH) => this.$units.convert(zH, "m")
      );
    },

    convertedElevation() {
      if (this.pipelinesStore.pipelineElevation.length === 0) {
        return [];
      }

      return this.pipelinesStore.pipelineElevation.map((e) =>
        this.$units.convert(e, "m")
      );
    },

    convertedDepth() {
      if (this.pipelinesStore.pipelineDepth.length === 0) {
        return [];
      }

      return this.pipelinesStore.pipelineDepth.map((e) =>
        this.$units.convert(e, "m")
      );
    },

    heightRange() {
      const minHeight = Math.min(
        ...this.zHeightProfile,
        ...this.convertedElevation,
        ...this.convertedDepth
      );

      const maxHeight = Math.max(
        ...this.zHeightProfile,
        ...this.convertedElevation,
        ...this.convertedDepth
      );

      return [minHeight, maxHeight];
    },

    elevationTrace() {
      if (
        this.convertedElevation.length === 0 ||
        !this.sharedTraceIncluded(SharedHeightChartTrace.Elevation)
      ) {
        return null;
      }

      const elevationTrace = {
        x: this.distance,
        y: this.convertedElevation,
        type: "scatter",
        name: this.$t("dashboard.pipelines.height_profile.elevation"),
        line: {
          color: "#6e4f00",
        },
        showlegend: true,
      };

      return elevationTrace;
    },

    heightProfileTrace() {
      if (!this.sharedTraceIncluded(SharedHeightChartTrace.HeightProfile)) {
        return null;
      }

      const heightProfileTrace = {
        x: this.distance,
        y: this.zHeightProfile,
        type: "scatter",
        name: this.$t("dashboard.pipelines.height_profile.title"),
        line: {
          color: "#1c243d",
        },
        showlegend: true,
      };

      return heightProfileTrace;
    },

    depthTrace() {
      if (
        this.convertedDepth.length === 0 ||
        !this.sharedTraceIncluded(SharedHeightChartTrace.Depth)
      ) {
        return null;
      }

      const depthTrace = {
        x: this.distance,
        y: this.convertedDepth,
        type: "scatter",
        fill: "tonexty",
        fillcolor: "#f78b8b",
        name: this.$t("dashboard.pipelines.height_profile.depth"),
        mode: "none",
        showlegend: true,
      };

      return depthTrace;
    },

    data() {
      let chartTraces = [
        this.elevationTrace,
        this.heightProfileTrace,
        this.depthTrace,
      ].filter((trace) => trace !== null);

      chartTraces = chartTraces.concat(...this.additionalTraces);

      return chartTraces;
    },

    layout() {
      const layout = {
        xaxis: {
          showline: true,
        },

        yaxis: {
          showline: true,
          range: [0, 1],
        },

        margin: {
          t: 30,
          b: 70,
          l: 70,
          r: 50,
        },
      };

      const [minHeight, maxHeight] = this.heightRange;
      layout.yaxis.range = [minHeight - 5.0, maxHeight + 2.0];

      mergeChangesRecursively(layout, this.layoutChanges);

      return layout;
    },
  },

  methods: {
    sharedTraceIncluded(key) {
      return this.sharedTraces.includes(key);
    },
  },
};
</script>
