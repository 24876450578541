<template>
  <BaseWidget
    :title="$t('joints.widgets.gradient.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <GradientLegendToolbar @click:info="$emit('click:info')" />
    </template>

    <PipePartsGradient :gradientColor="gradientColor" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { PipePartsGradient } from "@/pages/pipe-parts/components";
import GradientLegendToolbar from "../GradientLegendToolbar.vue";

export default {
  name: "PipePartsGradientWidget",

  components: {
    BaseWidget,
    PipePartsGradient,
    GradientLegendToolbar,
  },

  props: {
    gradientColor: {
      type: Array,
      required: true,
    },
  },

  emits: {
    "click:info": null,
  },
};
</script>
