<template>
  <v-card-text id="image-gallery">
    <div v-if="isAuthor" style="display: flex; margin-bottom: 0.3rem">
      <v-file-input
        v-model="awaitingFiles"
        show-size
        multiple
        accept="image/*,.pdf"
        :label="$t('comments_modal.input_label')"
        :hint="$t('comments_modal.input_hint')"
        persistent-hint
        variant="outlined"
      ></v-file-input>
      <v-btn
        v-if="!uploadingProgress.isUploading"
        color="green"
        prepend-icon="mdi-cloud-upload-outline"
        style="margin-left: 1rem; margin-top: 7px"
        :disabled="isDisableUpload"
        @click="uploadFiles"
        >{{ $t("common.upload") }}</v-btn
      >
      <v-btn
        v-else
        color="green"
        style="margin-left: 1rem; margin-top: 7px"
        width="120"
      >
        <template v-slot:prepend>
          <v-progress-circular
            size="20"
            width="1"
            indeterminate
          ></v-progress-circular>
        </template>
        {{ `${uploadingProgress.percent}%` }}</v-btn
      >
    </div>

    <template v-if="hasAwaitingFiles">
      <h3>{{ $t("comments_modal.awaiting_upload") }}:</h3>
      <v-list :disabled="uploadingProgress.isUploading">
        <v-list-item
          v-for="file in awaitingFiles"
          :key="file"
          :value="file"
          :base-color="checkValidSize(file.size) ? undefined : 'red'"
        >
          <template v-slot:prepend>
            <v-icon size="large" :icon="getFileTypeIcon(file.type)"></v-icon>
          </template>
          <template v-slot:append>
            <v-btn
              color="blue"
              icon="mdi-eye"
              variant="text"
              @click="previewAwaitedFile(file)"
            ></v-btn>
            <v-btn
              color="red"
              icon="mdi-trash-can-outline"
              variant="text"
              @click="deleteFile(file.name)"
            ></v-btn>
          </template>
          <v-list-item-title v-text="file.name"></v-list-item-title>
          <v-list-item-subtitle>
            {{ getFileSubtitle(file, false) }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </template>

    <h3>{{ $t("comments_modal.saved") }}:</h3>
    <h4
      v-if="savedFiles.length === 0 && !isGettingFiles"
      style="opacity: 0.6; margin: 0.5rem"
    >
      {{ $t("comments_modal.no_saved_files") }}
    </h4>
    <div v-if="isGettingFiles" class="loader">
      <v-progress-circular
        color="dark-blue"
        indeterminate
        :size="52"
        :width="5"
      ></v-progress-circular>
    </div>
    <v-list v-else>
      <v-list-item v-for="file in savedFiles" :key="file" :value="file">
        <template v-slot:prepend>
          <v-icon size="large" :icon="getFileTypeIcon(file.extension)"></v-icon>
        </template>
        <template v-slot:append>
          <v-btn
            color="blue"
            icon="mdi-eye"
            variant="text"
            @click="previewSavedFile(file)"
          ></v-btn>
          <v-btn
            color="blue"
            icon="mdi-download"
            variant="text"
            :href="file.url"
          ></v-btn>
        </template>
        <v-list-item-title v-text="file.file_name"></v-list-item-title>
        <v-list-item-subtitle>
          {{ getFileSubtitle(file, true) }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

    <v-dialog
      v-if="pdfPreview.src"
      v-model="pdfPreview.show"
      width="auto"
      style="z-index: 10001"
    >
      <v-btn
        size="x-large"
        variant="text"
        style="position: absolute; top: -30px; right: -80px; color: white"
        icon="mdi-close"
        @click="pdfPreview.show = false"
      />
      <embed
        :src="pdfPreview.src"
        type="application/pdf"
        style="width: 80vw; height: 90vh"
      />
    </v-dialog>
  </v-card-text>
</template>

<script>
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

import { userCommentsAPI } from "@/features/comments/api";
import { useAuthStore } from "@/store";

export default {
  name: "FilesTab",
  props: {
    commentId: String,
    isAuthor: Boolean,
    isNewComment: Boolean,
    postComment: Function,
  },
  setup() {
    return {
      authStore: useAuthStore(),
    };
  },
  data() {
    return {
      uploadingProgress: {
        isUploading: false,
        percent: 0,
      },
      awaitingFiles: [],

      isGettingFiles: false,
      savedFiles: [],

      pdfPreview: {
        src: undefined,
        show: false,
      },

      lightbox: null,
    };
  },

  mounted() {
    if (!this.isNewComment) {
      this.getDocuments();
    }
  },

  computed: {
    hasAwaitingFiles() {
      return this.awaitingFiles.length > 0;
    },

    isDisableUpload() {
      return (
        !this.hasAwaitingFiles ||
        this.awaitingFiles.some(({ size }) => !this.checkValidSize(size))
      );
    },
  },

  methods: {
    previewAwaitedFile(file) {
      const src = URL.createObjectURL(file);
      if (file.type.includes("pdf")) {
        this.openPDF(src);
      } else {
        this.openLightbox(src);
      }
    },

    async previewSavedFile({ url, extension }) {
      if (extension.includes("pdf")) {
        const blobPdf = await this.extractRemoutedPdf(url);
        const src = URL.createObjectURL(blobPdf);
        this.openPDF(src);
      } else {
        this.openLightbox(url);
      }
    },

    async extractRemoutedPdf(url) {
      const response = await fetch(url);
      const blobData = await response.blob();

      return new Blob([blobData], { type: "application/pdf" });
    },

    openPDF(src) {
      this.pdfPreview.src = src;
      this.pdfPreview.show = true;
    },

    async openLightbox(src) {
      const image = await new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = function () {
          const height = img.height;
          const width = img.width;
          resolve({ src, height, width });
        };
      });

      await this.rebuildGallery(image);
      this.lightbox.loadAndOpen(0);
    },

    checkValidSize(size) {
      const ONE_HUNDRED_MEGABYTES = 1e8;
      return size < ONE_HUNDRED_MEGABYTES;
    },

    convertFileSize(bytes) {
      if (bytes < 1024 * 1024) {
        const kilobytes = (bytes / 1024).toFixed(1);
        return kilobytes + " KB";
      }
      const megabytes = (bytes / (1024 * 1024)).toFixed(1);
      return megabytes + " MB";
    },

    getFormattedDate(seconds) {
      const date = new Date(seconds * 1000);
      return date.toLocaleString().slice(0, -3);
    },

    getFileSubtitle(file, isSavedFile) {
      const size = this.$t("comments_modal.size");
      const fileSize = this.convertFileSize(file.size);
      const sizeError = this.checkValidSize(file.size)
        ? ""
        : `; ${this.$t("comments_modal.size_error")}`;

      if (isSavedFile) {
        const date = this.$t("comments_modal.date");
        const formattedDate = this.getFormattedDate(file.creation_time);
        return `${size}: ${fileSize} ${date}: ${formattedDate}`;
      }

      return `${size}: ${fileSize}${sizeError}`;
    },

    getFileTypeIcon(type) {
      switch (true) {
        case type.includes("pdf"):
          return "mdi-file-pdf-box";
        case type.includes("image"):
        case type.includes("jpg"):
          return "mdi-image";
        default:
          return "mdi-file";
      }
    },

    deleteFile(name) {
      this.awaitingFiles = this.awaitingFiles.filter(
        (file) => file.name !== name
      );
    },

    async getDocuments() {
      try {
        this.isGettingFiles = true;
        this.savedFiles = await userCommentsAPI.getDocuments(
          this.commentId,
          this.authStore.selectedGroup
        );
      } catch (error) {
        console.log(error);
        this.$toast.error(`Reading documents - ${error.message}`, {
          position: "top-right",
        });
      } finally {
        this.isGettingFiles = false;
      }
    },

    async uploadFiles() {
      try {
        this.uploadingProgress.isUploading = true;
        this.uploadingProgress.percent = 0;

        if (this.isNewComment) {
          await this.postComment();
        }

        const formData = new FormData();
        this.awaitingFiles.forEach((file) => {
          formData.append("upload_files", file);
        });
        await userCommentsAPI.uploadDocument(
          this.commentId,
          formData,
          this.onUploadProgress,
          this.authStore.selectedGroup
        );

        this.awaitingFiles = [];
        this.getDocuments();
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.log(error);
          this.$toast.error(`Upload documents - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.uploadingProgress.isUploading = false;
      }
    },

    onUploadProgress(progressEvent) {
      if (progressEvent) {
        this.uploadingProgress.percent = (
          progressEvent.progress * 100
        ).toFixed();
      }
    },

    async removeGallery() {
      await this.$nextTick();
      if (this.lightbox) {
        this.lightbox.destroy();
        this.lightbox = null;
      }
    },

    async rebuildGallery(photo) {
      this.removeGallery();
      await this.$nextTick();
      this.lightbox = new PhotoSwipeLightbox({
        gallery: "#image-gallery",
        dataSource: [photo],
        pswpModule: () => import("photoswipe"),
      });
      this.lightbox.on("close", () => {
        this.removeGallery();
      });
      this.lightbox.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  margin-top: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
