import { auth0 } from "@/config/auth0";

export default class CancaledAPI {
  constructor() {
    this.token = null;
    this.controllers = new Map();
  }

  async updateToken() {
    this.token = await auth0.getAccessTokenSilently();
  }

  refrershController(controllerName) {
    if (this.controllers.has(controllerName)) {
      this.controllers.get(controllerName).abort();
    }

    const controller = new AbortController();
    this.controllers.set(controllerName, controller);

    return controller;
  }

  abort(controllerName) {
    const controller = this.controllers.get(controllerName);
    if (controller) {
      controller.abort();
    }
  }

  abortAll() {
    for (const controller of this.controllers.values()) {
      controller.abort();
    }
  }
}
