<template>
  <v-menu
    :model-value="show"
    :close-on-content-click="false"
    @update:modelValue="(value) => $emit('update:show', value)"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="
          scannerControlStore.hasFilteredScanners ? '#d9534f' : '#ffffff00'
        "
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("pipe_parts.filters.title") }}
        </v-btn>
      </v-badge>
    </template>

    <template v-slot:default>
      <VCard>
        <VCardText class="filters-card">
          <ScannerFilters />
        </VCardText>
      </VCard>
    </template>
  </v-menu>
</template>

<script>
import ScannerFilters from "@/pages/scanner-control/components/ScannerFilters.vue";
import { useScannerControlStore } from "@/store";

export default {
  name: "FiltersPopupMenu",

  components: {
    ScannerFilters,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  emits: {
    "update:show": (newValue) => typeof newValue === "boolean",
  },

  setup() {
    return {
      scannerControlStore: useScannerControlStore(),
    };
  },
};
</script>

<style scoped lang="scss">
.filters-card {
  width: 725px;
}
</style>
