import { ref } from "vue";

import { useResizeObserver } from "@vueuse/core";
import { debounce } from "@/utils/debounce";

export function useDebouncedElementSize(target, options) {
  const { delay } = options;

  const width = ref(0);
  const height = ref(0);

  function handleResize(entries) {
    const entry = entries.at(0);

    width.value = entry.borderBoxSize[0].inlineSize;
    height.value = entry.borderBoxSize[0].blockSize;
  }

  const debouncedHandleResize = debounce(handleResize, { delay });

  useResizeObserver(target, debouncedHandleResize);

  return {
    width,
    height,
  };
}
