<template>
  <v-form @mousedown.stop>
    <v-row no-gutters>
      <v-col class="mx-3">
        <v-text-field
          :disabled="
            scannerControlStore.selectedScannerType === ScannerType.InfoPending
          "
          :model-value="scannerControlStore.filters.search"
          @update:modelValue="
            (value) => scannerControlStore.setFilters({ search: value })
          "
          @click:clear="scannerControlStore.setFilters({ search: '' })"
          :label="$t('pipe_scanner.filters.search')"
          append-inner-icon="mdi-magnify"
          clearable
          variant="underlined"
        ></v-text-field>
      </v-col>
      <v-col class="mx-3">
        <v-select
          :label="$t('pipe_scanner.filters.materials')"
          :model-value="scannerControlStore.filters.materials"
          @update:modelValue="
            (value) => scannerControlStore.setFilters({ materials: value })
          "
          :items="materialOptions"
          clearable
          multiple
          single-line
          variant="underlined"
          hide-details
        >
          <template
            v-if="mapStore.isMapMode"
            v-slot:selection="{ item, index }"
          >
            <span v-if="index === 0">{{ item.title }} &nbsp;</span>
            <span v-if="index === 1" style="font-size: 0.9rem"
              >(+{{ scannerControlStore.filters.materials.length - 1 }})</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col class="mx-3" style="max-width: 200px">
        <DatePicker
          :date-value="scannerControlStore.filters.inspectionDateRange[0]"
          @update:dateValue="
            (value) =>
              scannerControlStore.setFilters({
                inspectionDateRange: [
                  value,
                  scannerControlStore.filters.inspectionDateRange[1],
                ],
              })
          "
          :max="new Date(scannerControlStore.filters.inspectionDateRange[1])"
          :label="$t('pipe_scanner.filters.inspection_from')"
        />
      </v-col>
      <v-col class="mx-3" style="max-width: 200px">
        <DatePicker
          :date-value="scannerControlStore.filters.inspectionDateRange[1]"
          @update:dateValue="
            (value) =>
              scannerControlStore.setFilters({
                inspectionDateRange: [
                  scannerControlStore.filters.inspectionDateRange[0],
                  value,
                ],
              })
          "
          :min="new Date(scannerControlStore.filters.inspectionDateRange[0])"
          :label="$t('pipe_scanner.filters.inspection_to')"
      /></v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col class="mx-3" style="max-width: 300px; text-align: center">
        <PipeScannerDiameterSlider />
      </v-col>
      <v-col class="constuction_year_range" style="max-width: 350px">
        <div class="title">
          {{ $t("pipe_scanner.filters.construction_year") }}:
        </div>
        <v-text-field
          :disabled="
            scannerControlStore.selectedScannerType !== ScannerType.Results
          "
          :model-value="scannerControlStore.filters.constuctionYearRange[0]"
          @update:modelValue="
            (value) =>
              scannerControlStore.setFilters({
                constuctionYearRange: [
                  value,
                  scannerControlStore.filters.constuctionYearRange[1],
                ],
              })
          "
          :label="$t('pipe_scanner.filters.from')"
          type="number"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
        <v-text-field
          :disabled="
            scannerControlStore.selectedScannerType !== ScannerType.Results
          "
          :model-value="scannerControlStore.filters.constuctionYearRange[1]"
          @update:modelValue="
            (value) =>
              scannerControlStore.setFilters({
                constuctionYearRange: [
                  scannerControlStore.filters.constuctionYearRange[0],
                  value,
                ],
              })
          "
          :label="$t('pipe_scanner.filters.to')"
          type="number"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { DatePicker } from "@/components";
import { ScannerType } from "@/pages/scanner-control/config";
import { PipeScannerDiameterSlider } from "@/pages/scanner-control/components";
import { getUniqueValues } from "@/utils/filterHelpers";
import {
  useMapStore,
  usePipeScannersStore,
  useScannerControlStore,
} from "@/store";

export default {
  name: "ScannerFilters",

  components: {
    DatePicker,
    PipeScannerDiameterSlider,
  },

  setup() {
    return {
      ScannerType,
      mapStore: useMapStore(),
      scannerControlStore: useScannerControlStore(),
      pipeScannersStore: usePipeScannersStore(),
    };
  },

  computed: {
    materialOptions() {
      return getUniqueValues(
        this.pipeScannersStore.mergedPipeScanners,
        "material"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.constuction_year_range {
  text-align: center;
  display: flex;
  align-items: flex-start;
  padding: 0 12px;

  & > div.title {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .v-text-field {
    margin: 0 8px;
  }
}
</style>
