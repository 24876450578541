import { createApp } from "vue";

import App from "./App.vue";

import router from "./router";
import { auth0 } from "@/config/auth0";
import { i18n } from "@/config/i18n";
import { unitsConverter } from "@/config/units";
import { vuetify } from "@/config/vuetify";
import toast from "vue-toast-notification";
import { OpenAPI } from "@/open-api-code/ili-api";

import "@/assets/scss/custom-style.scss";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "./assets/index.css";
import { createPinia } from "pinia";

const app = createApp(App);
const pinia = createPinia();

app
  .use(pinia)
  .use(router)
  .use(auth0)
  .use(i18n)
  .use(unitsConverter)
  .use(vuetify)
  .use(toast)
  .mount("#app");

OpenAPI.BASE = process.env.VUE_APP_API_SERVER || window.location.origin;
