<template>
  <v-select
    :label="$t('joints.filters.all_types')"
    :model-value="jointsStore.jointFilters.selectedJointTypes"
    @update:modelValue="
      (value) => jointsStore.setFilters({ selectedJointTypes: value })
    "
    :items="jointTypeOptions"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { useJointsStore } from "@/store";

export default {
  setup() {
    return {
      jointsStore: useJointsStore(),
    };
  },

  computed: {
    jointTypeOptions() {
      return this.jointsStore.jointTypes.map((type) => ({
        value: type,
        title: this.$t(`joints.types.${type}`),
      }));
    },
  },
};
</script>
