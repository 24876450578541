import { ref } from "vue";

import convertUnits from "convert-units";

function roundNumber(num) {
  var decimalPart = num - Math.floor(num);
  if (decimalPart >= 0.99 || decimalPart <= 0.02) {
    return Math.round(num);
  } else {
    return +num.toFixed(2);
  }
}

// kilometers -> miles (km -> mi)
// meters -> feet (m -> ft)
// millimeters -> inches (mm -> in)
const fromTo = {
  km: "mi",
  m: "ft",
  mm: "in",
  mi: "km",
  ft: "m",
  in: "mm",
};

const unitsList = ["metric", "imperial"];

function createInstance(options) {
  const { units, fallbackUnits } = options;

  if (!units && !fallbackUnits) {
    throw new Error(
      "No units was provided to initialize units conversion plugin"
    );
  }

  const instance = {
    get list() {
      return unitsList;
    },

    current: ref(units || fallbackUnits),

    convert: function (val, from) {
      if (
        this.current.value !== "metric" &&
        Object.prototype.hasOwnProperty.call(fromTo, from)
      ) {
        const convertVal = +convertUnits(val)
          .from(from)
          .to(fromTo[from])
          .toFixed(3);

        return from === "in" ? roundNumber(convertVal) : convertVal;
      }
      return val;
    },

    getAbbr: function (from) {
      if (
        this.current.value !== "metric" &&
        Object.prototype.hasOwnProperty.call(fromTo, from)
      ) {
        return fromTo[from];
      }
      return from;
    },
  };

  return instance;
}

export function createUnitsPlugin(options) {
  const instance = createInstance(options);

  const plugin = {
    install(app) {
      app.config.globalProperties.$units = instance;
      app.provide("units", instance);
    },

    get instance() {
      return instance;
    },
  };

  return plugin;
}
