<template>
  <VDataTableVirtual
    :height="700"
    :headers="internalHeaders"
    :row-props="({ item }) => getRowProps(item)"
    @click:row.stop="(_, { item }) => $emit('click:row', item)"
    item-keys="id"
    hide-default-footer
    fixed-header
    class="virtual-table"
    v-bind="$attrs"
  >
    <template v-slot:item="{ itemRef, item, props: vuetifyProps, ...rest }">
      <TableRow
        :ref="itemRef"
        :rowProps="getRowProps(item)"
        :item="item"
        :vuetifyProps="vuetifyProps"
        v-bind="rest"
      >
        <template v-slot:[`item.prepend`]="props">
          <slot name="item.prepend" v-bind="props" />
        </template>

        <template v-slot:[`item.select`]="props" v-if="showSelect">
          <slot name="item.select" v-bind="props" />
        </template>

        <template v-for="slotName in itemSlots" v-slot:[slotName]="props">
          <slot :name="slotName" v-bind="props" />
        </template>

        <template v-slot:[`item.append`]="props">
          <slot name="item.append" v-bind="props" />
        </template>
      </TableRow>
    </template>

    <template v-slot:[`header.select`]="props" v-if="showSelect">
      <slot name="header.select" v-bind="props" />
    </template>

    <template v-for="slotName in headerSlots" v-slot:[slotName]="props">
      <slot :name="slotName" v-bind="props" />
    </template>
  </VDataTableVirtual>
</template>

<script>
import TableRow from "./TableRow.vue";

import { CONVERTIBLE_UNITS } from "./config";

export default {
  name: "VirtualTable",

  components: { TableRow },

  emits: ["click:row", "toggle:checkbox"],

  props: {
    headerSlots: Array,
    itemSlots: Array,
    headers: Array,
    isItemActive: Function,
    rowProps: Object,
    showSelect: Boolean,
  },

  computed: {
    internalHeaders() {
      const internalHeaders = this.appendUnits(this.headers);

      if (this.showSelect) {
        internalHeaders.unshift({ key: "select", sortable: false });
      }

      return internalHeaders;
    },
  },

  methods: {
    appendUnits(headers) {
      const newHeaders = headers.map((header) => {
        if (header.children) {
          header.children = this.appendUnits(header.children);
        }

        if (!header.units) {
          return header;
        }

        let newTitle = `${header.title} (${header.units})`;

        if (CONVERTIBLE_UNITS.includes(header.units)) {
          newTitle = `${header.title} (${this.$units.getAbbr(header.units)})`;
        }

        return { ...header, title: newTitle };
      });

      return newHeaders;
    },

    getRowProps(item) {
      if (this.isItemActive && this.isItemActive(item)) {
        return { "data-active": true, ...this.rowProps };
      }

      return this.rowProps;
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-table {
  :deep(.v-table__wrapper) {
    overflow-x: unset;

    & th {
      white-space: nowrap;
    }

    & tr:hover {
      background-color: #f3fcf8;
    }

    & tr[data-active] {
      background-color: #ddf9ee;
    }
  }
}
</style>
