<template>
  <div class="wrapper">
    <span v-if="!mapStore.isMapMode" class="title">{{
      $t("anomalies.details.anomaly_type")
    }}</span>
    <div
      v-for="type in anomaliesStore.anomaliesTypes"
      :class="computeClassName(type)"
      :key="type"
      @click="onSelectType(type)"
    >
      <div
        class="dot"
        :style="{
          backgroundColor: anomalyTypeColors[type],
        }"
      ></div>
      <div>{{ $t(`anomalies.types.${type}`) }}</div>
    </div>
  </div>
</template>

<script>
import { anomalyTypeColors } from "@/colorLegends.js";
import { useAnomaliesStore, useMapStore } from "@/store";

export default {
  name: "AnomaliesLegend",
  emits: ["updateSelectedTypes"],
  setup() {
    return {
      anomalyTypeColors,
      mapStore: useMapStore(),
      anomaliesStore: useAnomaliesStore(),
    };
  },

  methods: {
    onSelectType(type) {
      const newSelectedTypes = [
        ...this.anomaliesStore.anomalyFilters.selectedAnomaliesTypes,
      ];

      if (newSelectedTypes.includes(type)) {
        newSelectedTypes.filter((item) => item !== type);
      } else {
        newSelectedTypes.push(type);
      }

      this.anomaliesStore.setFilters({
        selectedAnomaliesTypes: newSelectedTypes,
      });
    },

    computeClassName(type) {
      const selectedTypes =
        this.anomaliesStore.anomalyFilters.selectedAnomaliesTypes;
      const isSelected = !selectedTypes.length || selectedTypes.includes(type);
      return ["type", "pointer", { selected: isSelected }];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 8px 12px 12px;
  border-radius: 6px;
  background-color: white;
  .title {
    font-size: 1rem;
    user-select: none;
  }

  .type {
    opacity: 0.3;
    user-select: none;
    display: flex;
    align-items: center;
    margin-top: 6px;

    &.pointer {
      cursor: pointer;
    }
    &.selected {
      opacity: 1;
    }
    .dot {
      margin-right: 6px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }
}
</style>
