<template>
  <div>
    <slot name="title"></slot>
    <RangeSlider
      :title="title"
      :model-value="safetyFactorStore.filters.selectedLowerSafetyRange"
      @update:modelValue="
        (value) =>
          safetyFactorStore.setFilters({ selectedLowerSafetyRange: value })
      "
      :min="safetyFactorStore.minMaxLowerSafetyRange[0]"
      :max="safetyFactorStore.minMaxLowerSafetyRange[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { useSafetyFactorStore } from "@/store";

export default {
  setup() {
    return {
      safetyFactorStore: useSafetyFactorStore(),
    };
  },
  data() {
    return {
      title: undefined,
    };
  },
  mounted() {
    if (!this.$slots.title || !this.$slots.title()?.length) {
      this.title = this.$t("safety_factor.filters.safety_factor_lower");
    }
  },
  components: {
    RangeSlider,
  },
};
</script>
