<template>
  <VCheckboxBtn v-bind="$attrs" class="table-checkbox" />
</template>

<script>
export default {
  name: "TableCheckbox",
};
</script>

<style scoped lang="scss">
@use "./variables" as *;

.table-checkbox {
  width: $table-control-width;
  height: $table-control-height;

  :deep(.v-icon) {
    opacity: $table-control-opacity;
  }
}
</style>
