import { auth0 } from "@/config/auth0";
import { ScannerType } from "@/pages/scanner-control/config";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "./useAuthStore";
import {
  getScannersAnalysisPending,
  getScannersInfoPending,
  getScannersResult,
} from "@/features/pipescanner/api";
import { mergeChanges } from "@/utils/objects";

const requests = new Map();
const toast = useToast();

const INITIAL_PIPE_SCANNERS_STATE = {
  [ScannerType.Results]: [],
  [ScannerType.InfoPending]: [],
  [ScannerType.AnalysisPending]: [],
};

export const usePipeScannersStore = defineStore("pipeScanners", () => {
  const authStore = useAuthStore();

  const pipeScanners = ref(INITIAL_PIPE_SCANNERS_STATE);

  const mergedPipeScanners = computed(() => {
    let merged = [];

    for (const item of Object.values(pipeScanners.value)) {
      merged = merged.concat(item);
    }

    return merged;
  });

  function setPipeScanners(changes) {
    mergeChanges(pipeScanners.value, changes);
  }
  function setScanners({ key, scanners }) {
    pipeScanners.value[key] = scanners;
  }
  function updateScanner({ key, scannerId, updateForm }) {
    pipeScanners.value[key] = pipeScanners.value[key].map((scanner) => {
      if (scanner.id !== scannerId) {
        return scanner;
      }
      const updatedScanner = { ...scanner };
      const fields = Object.getOwnPropertyNames(updateForm);
      fields.forEach((field) => {
        if (updatedScanner.hasOwnProperty(field)) {
          updatedScanner[field] = updateForm[field];
        }
      });

      return updatedScanner;
    });
  }
  function resetScanners() {
    pipeScanners.value = INITIAL_PIPE_SCANNERS_STATE;
  }

  const isLoading = ref({
    [ScannerType.Results]: false,
    [ScannerType.InfoPending]: false,
    [ScannerType.AnalysisPending]: false,
  });

  function setIsLoading(changes) {
    mergeChanges(isLoading.value, changes);
  }

  // TODO: There should be a single endpoint for fetching pipe scanners
  async function fetchResultsScanners() {
    try {
      setIsLoading({ [ScannerType.Results]: true });
      setPipeScanners({ [ScannerType.Results]: [] });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("resultsScanners", controller);

      const scanners = await getScannersResult(
        token,
        authStore.selectedGroup,
        controller
      );

      setPipeScanners({ [ScannerType.Results]: scanners });
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read results scanner - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading({ [ScannerType.Results]: false });
    }
  }
  async function fetchInfoScanners() {
    try {
      setIsLoading({ [ScannerType.InfoPending]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("infoScanners", controller);

      const scanners = await getScannersInfoPending(
        token,
        authStore.selectedGroup,
        controller
      );

      setPipeScanners({ [ScannerType.InfoPending]: scanners });
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read pending_info scanners - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading({ [ScannerType.InfoPending]: false });
    }
  }
  async function fetchAnalysisPendingScanners() {
    try {
      setIsLoading({ [ScannerType.AnalysisPending]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("analysisPendingScanners", controller);

      const scanners = await getScannersAnalysisPending(
        token,
        authStore.selectedGroup,
        controller
      );

      setPipeScanners({ [ScannerType.AnalysisPending]: scanners });
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read pending_analysis scanners - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading({ [ScannerType.AnalysisPending]: false });
    }
  }
  function cancelAllRequests() {
    for (const request of requests.values()) {
      request.abort();
    }
  }

  return {
    pipeScanners,
    isLoading,
    mergedPipeScanners,
    setPipeScanners,
    setScanners,
    updateScanner,
    resetScanners,
    setIsLoading,
    fetchResultsScanners,
    fetchInfoScanners,
    fetchAnalysisPendingScanners,
    cancelAllRequests,
  };
});
