<template>
  <v-card rounded="lg">
    <v-card-title class="font-weight-thin">
      <span>
        {{ $t("dashboard.pipelines.overview") }} -
        <i class="font-italic">{{
          pipelinesStore.selectedPipeline.pipeline_name
        }}</i>
      </span>
      <v-btn
        color="red"
        size="small"
        variant="outlined"
        @click="onClosePipeline"
      >
        {{ $t("dashboard.pipelines.close") }}
      </v-btn>
    </v-card-title>
    <v-tabs v-model="tabs" :mandatory="false" bg-color="primary">
      <v-tab
        v-for="inspect in inspections"
        :key="inspect.id"
        :value="inspect.id"
        >{{ inspect.project_reference }} : {{ inspect.inspection_date }}</v-tab
      >
    </v-tabs>
    <v-card-text>
      <div v-if="isLoading" class="text-center loading-container">
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </div>
      <div v-if="!isLoading && pipelinesStore.selectedInspectionStats">
        <p class="text-h6">{{ $t("dashboard.pipelines.statistics.title") }}</p>
        <PipeStatistics />
        <p class="text-h6">
          {{ $t("dashboard.pipelines.map") }}
        </p>
        <PipelineMap :marker="mapMarker" />
        <p class="text-h6">
          {{ $t("dashboard.pipelines.height_profile.title") }}
        </p>
        <DashboardHeightChart @changePoint="setMapMarker" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  PipeStatistics,
  DashboardHeightChart,
} from "@/pages/dashboard/components";
import PipelineMap from "./PipelineMap.vue";
import { useGlobalStore, usePipelinesStore } from "@/store";

export default {
  name: "PipelineOverview",
  components: {
    PipelineMap,
    PipeStatistics,
    DashboardHeightChart,
  },
  props: {
    isLoading: Boolean,
  },
  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
      globalStore: useGlobalStore(),
    };
  },
  data() {
    return {
      mapMarker: null,
    };
  },
  computed: {
    tabs: {
      get() {
        // eslint-disable-next-line vue/no-undef-properties
        return this.pipelinesStore.selectedInspectionId;
      },
      set(value) {
        // eslint-disable-next-line vue/no-undef-properties
        this.pipelinesStore.setSelectedInspectionId(value);
      },
    },

    inspections() {
      // eslint-disable-next-line vue/no-undef-properties
      return this.pipelinesStore.selectedPipeline.inspections;
    },
  },
  methods: {
    onClosePipeline() {
      this.globalStore.clearSelectedPipeData();
    },

    setMapMarker(lngLat) {
      this.mapMarker = lngLat;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

p {
  margin-bottom: 1rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  height: 200px;
}

.v-card-title {
  display: flex;
  justify-content: space-between;
}
</style>
