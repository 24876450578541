<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="safetyFactorStore.isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      forceHideScanners
      @mb-moveend="onMoveend"
    >
      <MapboxMarker
        v-if="safetyFactorStore.selectedPipePart"
        :lng-lat="safetyFactorStore.selectedPipePart.geom.coordinates[0]"
        :scale="0.9"
        color="orange"
      />

      <template v-slot:sources>
        <template
          v-for="{ id, sourceOptions, layerOptions } in mapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
          />
        </template>
      </template>

      <template v-slot:append>
        <WidgetsList
          :widgets="widgets"
          :widgetsBarConfig="widgetsBarConfig"
          v-if="!safetyFactorStore.isLoading"
        >
          <template v-slot:[SafetyFactorWidget.PipePartsTable]="props">
            <SafetyFactorTableWidget
              :uncertaintiesOptions="uncertaintiesOptions"
              v-bind="props"
            />
          </template>

          <template v-slot:[SafetyFactorWidget.SafetyChart]="props">
            <SafetyFactorChartWidget
              :visibleParts="visibleParts"
              :title="safetyChartTitle"
              v-bind="props"
            />
          </template>

          <template v-slot:[SafetyFactorWidget.ContributionsChart]="props">
            <SafetyFactorContributionsWidget
              :visibleParts="visibleParts"
              v-bind="props"
            />
          </template>

          <template v-slot:[SafetyFactorWidget.HeightProfileChart]="props">
            <SafetyFactorHeightProfileWidget
              :visibleParts="visibleParts"
              v-bind="props"
            />
          </template>

          <template v-slot:[SafetyFactorWidget.ColorsLegend]="props">
            <SafetyFactorColorsWidget :title="legendTitle" v-bind="props" />
          </template>

          <template v-slot:[SafetyFactorWidget.GradientLegend]="props">
            <SafetyFactorGradientWidget v-bind="props" />
          </template>

          <template v-slot:[SafetyFactorWidget.WMSWidget]="props">
            <WMSWidget
              v-model:wmsLayers="wmsLayers"
              :colorMode="safetyFactorStore.selectedViewFactor"
              :colorOptions="safetyFactorOptions"
              @update:colorMode="safetyFactorStore.setSelectedViewFactor"
              v-bind="props"
            />
          </template>

          <template v-slot:[SafetyFactorWidget.WMSLegend]="props">
            <WMSLegendWidget
              :wmsLayers="wmsLayers"
              :currentZoom="currentZoom"
              v-bind="props"
            />
          </template>
        </WidgetsList>
      </template>
    </ModifiedMapbox>
  </div>
</template>

<script>
import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { ModifiedMapbox } from "@/components";
import { WidgetsList } from "@/components/map-componets/widgets";
import {
  SafetyFactorChartWidget,
  SafetyFactorColorsWidget,
  SafetyFactorContributionsWidget,
  SafetyFactorGradientWidget,
  SafetyFactorHeightProfileWidget,
  SafetyFactorTableWidget,
} from "./components/widgets";
import {
  WMSWidget,
  WMSLegendWidget,
} from "@/components/map-componets/widgets/shared";

import { VIEW_FACTORS_WITHOUT_GRADIENT } from "@/pages/safety-factor/config";
import { SafetyFactorWidget } from "./config";
import { usePipePartsStore, useSafetyFactorStore } from "@/store";

export default {
  name: "SafetyFactorMapmode",

  components: {
    MapboxMarker,
    MapboxLayer,
    MapboxSource,
    ModifiedMapbox,

    WidgetsList,
    SafetyFactorChartWidget,
    SafetyFactorColorsWidget,
    SafetyFactorContributionsWidget,
    SafetyFactorGradientWidget,
    SafetyFactorHeightProfileWidget,
    SafetyFactorTableWidget,
    WMSWidget,
    WMSLegendWidget,
  },

  props: {
    mapData: Array,
    safetyFactorOptions: Array,
    uncertaintiesOptions: Array,
    safetyChartTitle: String,
    legendTitle: String,
  },

  setup() {
    return {
      SafetyFactorWidget,
      safetyFactorStore: useSafetyFactorStore(),
      pipePartsStore: usePipePartsStore(),
    };
  },

  data() {
    return {
      visibleParts: [],
      wmsLayers: [],
      currentZoom: null,
      showFilter: false,
    };
  },

  computed: {
    widgets() {
      return {
        [SafetyFactorWidget.PipePartsTable]: {
          key: SafetyFactorWidget.PipePartsTable,
          title: this.$t("safety_factor.widgets.table"),
          initialWidth: 840,
          initialHeight: 355,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[SafetyFactorWidget.PipePartsTable].initialWidth + 10),
          getInitialY: () => 175,
          initiallyMinimized: false,
        },

        [SafetyFactorWidget.SafetyChart]: {
          key: SafetyFactorWidget.SafetyChart,
          title: this.safetyChartTitle,
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[SafetyFactorWidget.SafetyChart].initialHeight + 35),
          initiallyMinimized: false,
        },

        [SafetyFactorWidget.ContributionsChart]: {
          key: SafetyFactorWidget.ContributionsChart,
          title: this.$t("safety_factor.widgets.contributions_chart"),
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[SafetyFactorWidget.ContributionsChart].initialWidth + 30),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[SafetyFactorWidget.ContributionsChart].initialHeight + 35),
          initiallyMinimized: true,
        },

        [SafetyFactorWidget.HeightProfileChart]: {
          key: SafetyFactorWidget.HeightProfileChart,
          title: this.$t("safety_factor.widgets.height_profile"),
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[SafetyFactorWidget.HeightProfileChart].initialHeight +
              180),
          initiallyMinimized: true,
        },

        [SafetyFactorWidget.GradientLegend]: {
          key: SafetyFactorWidget.GradientLegend,
          title: this.$t("joints.widgets.gradient.maximize_title"),
          initialWidth: 130,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: !this.showGradient,
          resizable: false,
          draggable: false,
          minimizable: false,
        },

        [SafetyFactorWidget.ColorsLegend]: {
          key: SafetyFactorWidget.ColorsLegend,
          title: this.legendTitle,
          initialWidth: 180,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: this.showGradient,
          resizable: false,
          draggable: false,
          minimizable: false,
        },

        [SafetyFactorWidget.WMSWidget]: {
          key: SafetyFactorWidget.WMSWidget,
          title: this.$t("dashboard.widgets.color.maximize_title"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[SafetyFactorWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 5,
          initiallyMinimized: false,
          resizable: false,
        },

        [SafetyFactorWidget.WMSLegend]: {
          key: SafetyFactorWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    widgetsBarConfig() {
      return {
        [SafetyFactorWidget.PipePartsTable]: {
          showFiltersIndicator: this.safetyFactorStore.hasActiveFilters,
        },
      };
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    showGradient() {
      return !VIEW_FACTORS_WITHOUT_GRADIENT.includes(
        this.safetyFactorStore.selectedViewFactor
      );
    },
  },

  methods: {
    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      const layerId = this.mapData.find(({ layerOptions }) =>
        layerOptions.id.includes("layer_pipe_parts")
      )?.layerOptions.id;

      if (map.getLayer(layerId)) {
        const visiblePartsIds = map
          .queryRenderedFeatures({ layers: [layerId] })
          .map((part) => part.properties.id);
        const sorted = [];
        this.pipePartsStore.pipeParts.forEach((part, index) => {
          if (visiblePartsIds.includes(part.id)) {
            sorted.push({ ...part, order_index: index });
          }
        });

        this.visibleParts = sorted;
      }
    },

    onLayerClick({ features }) {
      if (features && features[0].source.includes("pipe_parts")) {
        this.safetyFactorStore.setSelectedPipePartId(features[0].properties.id);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    "safetyFactorStore.selectedPipePart"(selected, prevSelected) {
      if (selected && selected.id !== prevSelected?.id) {
        this.map.flyTo({
          center: selected.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
