<template>
  <FiltersWidgetTab
    v-if="config.showFiltersIndicator"
    :tab="tab"
    @maximize="$emit('maximize', $event)"
  />
  <WidgetTabBase v-else :tab="tab" @maximize="$emit('maximize', $event)" />
</template>

<script>
import WidgetTabBase from "./WidgetTabBase.vue";
import { FiltersWidgetTab } from "./FiltersWidgetTab";

export default {
  name: "WidgetTab",

  components: {
    WidgetTabBase,
    FiltersWidgetTab,
  },

  props: {
    tab: {
      type: Object,
      required: true,
    },

    config: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: {
    maximize: (key) => typeof key === "string",
  },
};
</script>

<style scoped lang="scss"></style>
