<template>
  <TableButton icon="mdi-square-edit-outline" variant="text" />
</template>

<script>
import { TableButton } from "@/components/table-components/controls";

export default {
  name: "EditScannerButton",

  components: { TableButton },
};
</script>
