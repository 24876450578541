<template>
  <div>
    <RangeSlider
      :title="$t('pipe_parts.filters.axial_deform_ver')"
      :model-value="pipePartsStore.filters.selectedDeformationVer"
      @update:modelValue="
        (value) => pipePartsStore.setFilters({ selectedDeformationVer: value })
      "
      :min="pipePartsStore.minMaxDeformationVer[0]"
      :max="pipePartsStore.minMaxDeformationVer[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { usePipePartsStore } from "@/store";

export default {
  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },

  components: {
    RangeSlider,
  },
};
</script>
