<template>
  <Dashboard v-if="!canShowSafetyFactorPage" />
  <SafetyFactorMapmode
    v-else-if="mapStore.isMapMode"
    :mapData="mapData"
    :safetyFactorOptions="safetyFactorOptions"
    :uncertaintiesOptions="uncertaintiesOptions"
    :safetyChartTitle="safetyChartTitle"
    :legendTitle="legendTitle"
  />
  <SafetyFactor
    v-else
    :mapData="mapData"
    :safetyFactorOptions="safetyFactorOptions"
    :uncertaintiesOptions="uncertaintiesOptions"
    :safetyChartTitle="safetyChartTitle"
  />
</template>

<script>
import { SafetyFactor, SafetyFactorMapmode } from "./views";

import Dashboard from "@/pages/dashboard/DashboardPage.vue";
import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";
import { v4 as uuidv4 } from "uuid";
import {
  getUncertaintiesWithColors,
  gradientColorArr,
  soilTypeColors,
} from "@/colorLegends.js";
import { getSafetyFactorOptions } from "./config";
import { findMainUncertainty, getSafetyLevel } from "./helpers";
import {
  useMapStore,
  usePipelinesStore,
  usePipePartsStore,
  useSafetyFactorStore,
} from "@/store";

const nonGradientOptions = [
  "safety_factor",
  "safety_factor_lower",
  "safety_factor_upper",
  "main_contributor",
  "soil_type",
];

export default {
  name: "SafetyFactorPage",

  components: {
    Dashboard,
    SafetyFactorMapmode,
    SafetyFactor,
  },

  beforeUnmount() {
    this.safetyFactorStore.cancelAllRequests();
  },

  setup() {
    return {
      mapStore: useMapStore(),
      pipelinesStore: usePipelinesStore(),
      safetyFactorStore: useSafetyFactorStore(),
      pipePartsStore: usePipePartsStore(),
    };
  },

  computed: {
    canShowSafetyFactorPage() {
      return this.pipelinesStore.selectedInspectionId;
    },

    safetyFactorOptions() {
      return getSafetyFactorOptions(this.$t);
    },

    uncertaintiesOptions() {
      const allMainUncertainties = this.pipePartsStore.pipeParts.map((part) =>
        findMainUncertainty(part)
      );
      const uniqueList = Array.from(new Set(allMainUncertainties));
      const uncertaintiesWithColors = getUncertaintiesWithColors(this.$t);
      return uniqueList.map((key) => uncertaintiesWithColors[key]);
    },

    safetyChartTitle() {
      return (
        this.safetyFactorOptions.find(
          (opt) => opt.value === this.safetyFactorStore.selectedViewFactor
        )?.title || ""
      );
    },

    legendTitle() {
      let translationKey;

      switch (this.safetyFactorStore.selectedViewFactor) {
        case "main_contributor":
          translationKey = "safety_factor.widgets.uncertainty_legend";
          break;
        case "soil_type":
          translationKey = "safety_factor.widgets.soil_type_legend";
          break;
        case "safety_factor":
        case "safety_factor_lower":
        case "safety_factor_upper":
          translationKey = "safety_factor.widgets.safety_level_legend";
          break;
        default:
          return "";
      }

      return this.$t(translationKey);
    },

    mapData() {
      const mapData = [];

      const pipeMapData = getPipeMapData(
        this.pipelinesStore.selectedInspectionStats
      );
      mapData.push(pipeMapData);

      if (
        this.safetyFactorStore.filteredPipeParts.length &&
        this.safetyFactorStore.selectedViewFactor.includes("safety_factor")
      ) {
        const pipe_parts_id = `pipe_parts_${uuidv4()}`;
        mapData.push({
          id: pipe_parts_id,
          sourceOptions: {
            type: "geojson",
            tolerance: 0,
            data: {
              type: "FeatureCollection",
              features: this.safetyFactorStore.filteredPipeParts.map((part) => {
                if (!part.safety_factor_calculation) return;
                const { color, text } = getSafetyLevel(
                  part.safety_factor_calculation[
                    this.safetyFactorStore.selectedViewFactor
                  ],
                  this.$t
                );
                return {
                  type: "Feature",
                  properties: {
                    color,
                    text,
                    id: part.id,
                  },
                  geometry: part.geom,
                };
              }),
            },
          },
          layerOptions: {
            id: "layer_" + pipe_parts_id,
            source: pipe_parts_id,
            layout: {
              visibility: "visible",
            },
            type: "line",
            paint: {
              "line-color": ["get", "color"],
              "line-width": this.safetyFactorStore.selectedLegend
                ? [
                    "case",
                    [
                      "==",
                      ["get", "text"],
                      this.safetyFactorStore.selectedLegend,
                    ],
                    10,
                    3,
                  ]
                : ["case", ["==", ["get", "color"], "green"], 4, 10],
              "line-opacity": this.safetyFactorStore.selectedLegend
                ? [
                    "case",
                    [
                      "==",
                      ["get", "text"],
                      this.safetyFactorStore.selectedLegend,
                    ],
                    1,
                    0.5,
                  ]
                : 1,
            },
          },
        });
      }

      if (
        this.safetyFactorStore.filteredPipeParts.length &&
        ["main_contributor", "soil_type"].includes(
          this.safetyFactorStore.selectedViewFactor
        )
      ) {
        let features = [];
        if (this.safetyFactorStore.selectedViewFactor === "main_contributor") {
          features = this.safetyFactorStore.filteredPipeParts.map((part) => {
            if (!part.safety_factor_calculation) return;
            const { text, color } =
              part.safety_factor_calculation.main_uncertainty;

            return {
              type: "Feature",
              properties: {
                color,
                text,
                id: part.id,
              },
              geometry: part.geom,
            };
          });
        }
        if (this.safetyFactorStore.selectedViewFactor === "soil_type") {
          features = this.safetyFactorStore.filteredPipeParts.map((part) => {
            if (!part.safety_factor_calculation) return;
            const { text, color } =
              soilTypeColors[part.safety_factor_calculation.soil_type];
            return {
              type: "Feature",
              properties: {
                color,
                text,
                id: part.id,
              },
              geometry: part.geom,
            };
          });
        }
        const pipe_parts_id = `pipe_parts_${uuidv4()}`;

        mapData.push({
          id: pipe_parts_id,
          sourceOptions: {
            type: "geojson",
            tolerance: 0,
            data: {
              type: "FeatureCollection",
              features,
            },
          },
          layerOptions: {
            id: "layer_" + pipe_parts_id,
            source: pipe_parts_id,
            layout: {
              visibility: "visible",
            },
            type: "line",
            paint: {
              "line-color": ["get", "color"],
              "line-width": this.safetyFactorStore.selectedLegend
                ? [
                    "case",
                    [
                      "==",
                      ["get", "text"],
                      this.safetyFactorStore.selectedLegend,
                    ],
                    10,
                    3,
                  ]
                : 3,
              "line-opacity": this.safetyFactorStore.selectedLegend
                ? [
                    "case",
                    [
                      "==",
                      ["get", "text"],
                      this.safetyFactorStore.selectedLegend,
                    ],
                    1,
                    0.5,
                  ]
                : 1,
            },
          },
        });
      }

      if (
        !nonGradientOptions.includes(this.safetyFactorStore.selectedViewFactor)
      ) {
        const features = this.safetyFactorStore.filteredPipeParts.map(
          (part) => {
            if (!part.safety_factor_calculation) return;
            const value =
              part.safety_factor_calculation[
                this.safetyFactorStore.selectedViewFactor
              ];
            return {
              type: "Feature",
              properties: {
                value,
                id: part.id,
              },
              geometry: part.geom,
            };
          }
        );

        const [min, max] = this.safetyFactorStore.gradientMinMaxValues;

        let lineColor = gradientColorArr[0];
        if (min !== max) {
          const mid = min + max / 2;

          lineColor = [
            "interpolate",
            ["linear"],
            ["get", "value"],
            min,
            gradientColorArr[0],
            mid,
            gradientColorArr[1],
            max,
            gradientColorArr[2],
          ];
        }

        const pipe_parts_id = `pipe_parts_${uuidv4()}`;

        mapData.push({
          id: pipe_parts_id,
          sourceOptions: {
            type: "geojson",
            tolerance: 0,
            data: {
              type: "FeatureCollection",
              features,
            },
          },
          layerOptions: {
            id: "layer_" + pipe_parts_id,
            source: pipe_parts_id,
            layout: {
              visibility: "visible",
            },
            type: "line",
            paint: {
              "line-color": lineColor,
              "line-width": 3,
              "line-opacity": 1,
            },
          },
        });
      }
      return mapData;
    },
  },

  watch: {
    "pipelinesStore.selectedInspectionId": {
      handler(id) {
        if (id) this.safetyFactorStore.initSafetyFactor();
      },
      immediate: true,
    },
    "safetyFactorStore.selectedViewFactor"() {
      this.safetyFactorStore.resetPlotlyFilter();
      this.safetyFactorStore.setSelectedLegend(null);
    },
  },
};
</script>
