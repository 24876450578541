<template>
  <BaseWidget :title="title" v-bind="$attrs">
    <JointsHistogramChart :name="title" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { JointsHistogramChart } from "@/pages/joints/components";

export default {
  name: "HistogramWidget",

  components: {
    BaseWidget,
    JointsHistogramChart,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
