import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/pages/dashboard/DashboardPage.vue";
import Anomalies from "@/pages/anomaly/AnomaliesPage.vue";
import Joints from "@/pages/joints/JointsPage.vue";
import PipeParts from "@/pages/pipe-parts/PipePartsPage.vue";
import SafetyFactor from "@/pages/safety-factor/SafetyFactorPage.vue";
import ScannerControl from "@/pages/scanner-control/ScannerControlPage.vue";
import { ScannerAnalyser } from "@/features/analyser/components";
// import HomeAuth from "../views/HomeAuth.vue";
import { authGuard } from "@auth0/auth0-vue";
import { PAGES } from "@/constants/pagesNames";

const ScannerControlView = {
  template: `
      <router-view></router-view>
  `,
};

const {
  DASHBOARD,
  ANOMALIES,
  JOINTS,
  PIPE_PARTS,
  SAFETY_FACTOR,
  SCANNER_CONTROL,
} = PAGES;

const routes = [
  {
    path: DASHBOARD.PATH,
    name: DASHBOARD.NAME,
    beforeEnter: authGuard,
    component: Dashboard,
  },
  {
    path: ANOMALIES.PATH,
    name: ANOMALIES.NAME,
    beforeEnter: authGuard,
    component: Anomalies,
  },
  {
    path: JOINTS.PATH,
    name: JOINTS.NAME,
    beforeEnter: authGuard,
    component: Joints,
  },
  {
    path: PIPE_PARTS.PATH,
    name: PIPE_PARTS.NAME,
    beforeEnter: authGuard,
    component: PipeParts,
  },
  {
    path: SAFETY_FACTOR.PATH,
    name: SAFETY_FACTOR.NAME,
    beforeEnter: authGuard,
    component: SafetyFactor,
  },
  {
    path: SCANNER_CONTROL.PATH,
    beforeEnter: authGuard,
    component: ScannerControlView,
    children: [
      {
        path: SCANNER_CONTROL.CHILDREN.SCANNER_CONTROL.PATH,
        name: SCANNER_CONTROL.CHILDREN.SCANNER_CONTROL.NAME,
        component: ScannerControl,
      },
      {
        path: SCANNER_CONTROL.CHILDREN.ANALYSER.PATH,
        name: SCANNER_CONTROL.CHILDREN.ANALYSER.NAME,
        component: ScannerAnalyser,
      },
    ],
  },
  {
    path: PAGES.SETTINGS.PATH,
    name: PAGES.SETTINGS.NAME,
    beforeEnter: authGuard,
    component: () => import("../pages/settings/SettingsPage.vue"),
  },
  {
    path: PAGES.CLIENT_ADMINISTRATION.PATH,
    name: PAGES.CLIENT_ADMINISTRATION.NAME,
    beforeEnter: authGuard,
    component: () =>
      import(
        "@/features/administration/pages/client-admin/ClientAdminPage.vue"
      ),
  },
  {
    path: PAGES.PROJECT_ADMINISTRATION.PATH,
    name: PAGES.PROJECT_ADMINISTRATION.NAME,
    beforeEnter: authGuard,
    component: () =>
      import(
        "@/features/administration/pages/project-admin/ProjectAdminPage.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
