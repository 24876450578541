<template>
  <BaseWidget :title="$t('wms_layers.layers')" v-bind="$attrs">
    <WMSLegend :wmsLayers="wmsLayers" :currentZoom="currentZoom" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { WMSLegend } from "@/features/wms/components";

export default {
  name: "WMSLegendWidget",

  components: {
    BaseWidget,
    WMSLegend,
  },

  props: {
    wmsLayers: {
      type: Array,
      required: true,
    },

    currentZoom: {
      type: Number,
      required: true,
    },
  },
};
</script>
