<template>
  <div class="chart-skeleton-container">
    <div class="body">
      <div class="y-axis">
        <PillSkeleton class="y-axis-skeleton" />
      </div>

      <div class="chart">
        <RectSkeleton class="chart-skeleton" />
      </div>

      <div class="x-axis">
        <PillSkeleton class="x-axis-skeleton" />
      </div>
    </div>

    <div class="legend">
      <RectSkeleton class="legend-skeleton" />
    </div>
  </div>
</template>

<script>
import { PillSkeleton, RectSkeleton } from "@/components/skeletons/figures";

export default {
  name: "ChartSkeleton",

  components: {
    PillSkeleton,
    RectSkeleton,
  },

  props: {
    x: {
      type: Object,
      default: () => ({ name: true }),
    },

    y: {
      type: Object,
      default: () => ({ name: true }),
    },

    legend: {
      type: Object,
      default: () => ({ visible: true }),
    },
  },
};
</script>

<style scoped lang="scss">
.chart-skeleton-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
  padding: 12px;
}

.body {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  gap: 10px;
  flex-grow: 4;
}

.y-axis,
.x-axis {
  display: flex;
  align-items: center;
  justify-content: center;
}

.y-axis {
  grid-area: 1 / 1 / 2 / 2;
}

.x-axis {
  grid-area: 2 / 2 / -1 / -1;
}

.y-axis-skeleton {
  height: 75%;
  width: 25px;
}

.x-axis-skeleton {
  height: 25px;
  width: 75%;
}

.chart-skeleton {
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.legend {
  flex-grow: 1;
}

.legend-skeleton {
  height: 50%;
  border-radius: 12px;
}
</style>
