import { defineStore } from "pinia";

import {
  useAnomaliesStore,
  useJointsStore,
  usePipelinesStore,
  usePipePartsStore,
  usePipeScannersStore,
  useSafetyFactorStore,
  useScannerControlStore,
} from "@/store";

export const useGlobalStore = defineStore("global", () => {
  const anomaliesStore = useAnomaliesStore();
  const jointsStore = useJointsStore();
  const pipePartsStore = usePipePartsStore();
  const pipeScannersStore = usePipeScannersStore();
  const safetyFactorStore = useSafetyFactorStore();
  const scannerControlStore = useScannerControlStore();
  const pipelinesStore = usePipelinesStore();

  function resetFilters() {
    anomaliesStore.resetFilters();
    jointsStore.resetFilters();
    pipePartsStore.resetFilters();
    safetyFactorStore.resetFilters();
    anomaliesStore.resetFilters();
  }
  function clearSelectedPipeData() {
    pipelinesStore.setSelectedPipelineId(null);
    pipelinesStore.setSelectedInspectionId(null);
    pipelinesStore.setSelectedInspectionStats(null);
    pipelinesStore.setComments([]);
    pipelinesStore.setPipelineElevation([]);
  }
  function clearAllSelectedData() {
    clearSelectedPipeData();
    pipelinesStore.setGroupPipelines([]);
    pipeScannersStore.resetScanners();
    scannerControlStore.resetState();
  }

  return {
    resetFilters,
    clearSelectedPipeData,
    clearAllSelectedData,
  };
});
