<template>
  <BaseWidget :title="title" v-bind="$attrs">
    <PipePartsHistogram :title="title" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { PipePartsHistogram } from "@/pages/pipe-parts/components";

export default {
  name: "PipePartsHistogramWidget",

  components: {
    BaseWidget,
    PipePartsHistogram,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
