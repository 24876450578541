<template>
  <HeightChart
    :additionalTraces="additionalTraces"
    :layoutChanges="layoutChanges"
    @hover="hoverHeightProfile"
  />
</template>

<script setup>
import { HeightChart } from "@/components/charts/shared";
import { useJointsStore, useMapStore, usePipelinesStore } from "@/store";
import { watchDebounced } from "@vueuse/core";
import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps(["visibleDistance"]);
defineEmits(["changePoint", "graph-mousedown"]);

const { t } = useI18n();
const pipelinesStore = usePipelinesStore();
const jointsStore = useJointsStore();
const mapStore = useMapStore();

const units = inject("units");

const jointsTrace = computed(() => {
  const [minHeight, maxHeight] = pipelinesStore.heightRange;

  const errorValue =
    Math.abs(minHeight) > Math.abs(maxHeight) ? minHeight : maxHeight;

  const jointColor = "#fc614f33";
  const selectedJointColor = "#6b0b0b33";

  let jointsToPlot = jointsStore.filteredJoints;

  if (mapStore.isMapMode) {
    jointsToPlot = jointsStore.selectedJoint ? [jointsStore.selectedJoint] : [];
  }

  if (jointsToPlot.length === 0) {
    return null;
  }

  const xValues = jointsToPlot.map((joint) => joint.distance);
  const yValues = Array(jointsToPlot.length).fill(minHeight - 5);

  const colors = jointsToPlot.map((joint) => {
    if (joint.id === jointsStore.selectedJoint?.id) {
      return selectedJointColor;
    }

    return jointColor;
  });

  const trace = {
    x: xValues,
    y: yValues,
    mode: "markers",
    name: t("joints.details.plotly.joint"),
    marker: {
      color: colors,
    },
    error_y: {
      type: "constant",
      value: errorValue,
      width: 0,
      thickness: 1,
      color: jointColor,
    },
  };

  return trace;
});

const additionalTraces = computed(() => {
  if (jointsTrace.value !== null) {
    return [jointsTrace.value];
  }

  return [];
});

const layoutChanges = ref({});

function hoverHeightProfile(event) {
  if (event.points[0].data.jointId) {
    jointsStore.setSelectedJointId(event.points[0].data.jointId);
  } else {
    const pointIndex = event.points[0].pointIndex;
    jointsStore.setSelectedJointId(jointsStore.filteredJoints[pointIndex]?.id);
  }
}

watchDebounced(
  () => props.visibleDistance,
  () => {
    const newLayoutChanges = {
      xaxis: {
        title: `${t(
          "joints.details.height_profile.xaxis_name"
        )} (${units.getAbbr("m")})`,
      },

      yaxis: {
        title: `${t(
          "joints.details.height_profile.yaxis_name"
        )} (${units.getAbbr("m")})`,
      },
    };

    if (props.visibleDistance && props.visibleDistance.length) {
      const dMax = Math.max(...props.visibleDistance);
      const dMin = Math.min(...props.visibleDistance);
      newLayoutChanges.xaxis["range"] = [dMin, dMax];
    }

    layoutChanges.value = newLayoutChanges;
  },
  { debounce: 1000, immediate: true }
);
</script>
