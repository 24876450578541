function isObject(value) {
  return value && typeof value === "object";
}

function isSimpleObject(value) {
  return (
    isObject(value) &&
    !Array.isArray(value) &&
    Object.getOwnPropertyNames(value).every((name) => !isObject(value[name]))
  );
}

export function createMergedNestedCopy(destination, source) {
  const deepCopy = JSON.parse(JSON.stringify(destination));

  for (const keyName in source) {
    const currentValue = source[keyName];

    if (currentValue) {
      if (Array.isArray(currentValue)) {
        deepCopy[keyName] = [...currentValue];
      } else if (isSimpleObject(currentValue)) {
        const merged = deepCopy[keyName] || {};
        deepCopy[keyName] = { ...merged, ...currentValue };
      } else if (isObject(currentValue)) {
        deepCopy[keyName] = createMergedNestedCopy(
          deepCopy[keyName] || {},
          currentValue
        );
      } else {
        deepCopy[keyName] = currentValue;
      }
    }
  }

  return deepCopy;
}

export function mergeChanges(object, changes = {}) {
  Object.keys(changes).forEach((key) => (object[key] = changes[key]));
}

export function mergeChangesRecursively(object, changes = {}) {
  Object.keys(changes).forEach((key) => {
    if (
      typeof changes[key] === "object" &&
      changes[key] !== null &&
      !Array.isArray(changes[key])
    ) {
      if (
        !object[key] ||
        typeof object[key] !== "object" ||
        Array.isArray(object[key])
      ) {
        object[key] = {};
      }
      mergeChangesRecursively(object[key], changes[key]);
    } else {
      object[key] = changes[key];
    }
  });
}
