<template>
  <Skeletor circle v-bind="$attrs" />
</template>

<script>
import { Skeletor } from "vue-skeletor";

export default {
  name: "CircleSkeleton",

  components: {
    Skeletor,
  },
};
</script>
