<template>
  <ResizableVirtualTable
    :headers="displayedHeaders"
    :items="pipePartsStore.filteredPipeParts"
    :headerSlots="headerSlots"
    :itemSlots="itemSlots"
    :isItemActive="isPipePartActive"
    :rowProps="{ style: { position: 'relative' } }"
    @click:row="(pipePart) => $emit('click:row', pipePart.id)"
  >
    <template v-slot:[`header.select`]>
      <v-icon
        v-if="checkedLength !== 0"
        icon="mdi-minus-box-outline"
        size="small"
        @click="pipePartsStore.partsSelector.clear()"
      />
    </template>

    <template v-slot:[`item.select`]="{ item: pipePart }">
      <input
        :value="pipePart.id"
        :disabled="
          shouldDisableCheckbox(
            pipePart.id,
            pipePartsStore.filteredPipeParts,
            pipePartsStore.partsSelector
          )
        "
        :checked="pipePartsStore.partsSelector.has(pipePart.id)"
        @input="pipePartsStore.partsSelector.setByCtrl(pipePart.id)"
        @click.stop
        type="checkbox"
      />
    </template>

    <template
      v-for="header in additionalHeaders"
      v-slot:[`header.${header.key}`]="{ column, getSortIcon, toggleSort }"
      :key="header.key"
    >
      <SortableCustomHeader
        :column="column"
        :getSortIcon="getSortIcon"
        :toggleSort="toggleSort"
      >
        {{ selectedColumnName }}
        <LayerSelect
          v-if="MODES_WITH_LAYER_SELECT.includes(header.key)"
          :key="header.key"
          :wtLevel="selectedWTLevel"
        />
      </SortableCustomHeader>
    </template>

    <template
      v-for="header in additionalHeaders"
      v-slot:[`item.${header.key}`]="{ item: pipePart }"
    >
      <span
        :key="header.key"
        class="layers-label"
        v-if="
          selectedWTLevel &&
          pipePart[`wall_thickness_remaining_${selectedWTLevel}`][0].length > 1
        "
      >
        {{ getLayerName(pipePart, selectedWTLevel) }}
      </span>
      {{ getCellValueBySelectedGradient(pipePart) }}
    </template>

    <template v-slot:[`item.append`]="{ item: pipePart }">
      <AnomaliesSubtablePopup
        v-if="pipePart.id === this.pipePartsStore.selectedPipePart?.id"
        :selectedPipePart="pipePartsStore.selectedPipePart"
      />
    </template>
  </ResizableVirtualTable>
</template>

<script>
import { ResizableVirtualTable, SortableCustomHeader } from "@/components";
import LayerSelect from "./LayerSelect.vue";
import AnomaliesSubtablePopup from "./AnomaliesSubtablePopup.vue";

import {
  MODES_WITHOUT_ADDITIONAL_COLUMNS,
  MODES_WITH_LAYER_SELECT,
} from "@/pages/pipe-parts/components/PipePartsTable/config";
import { getPipePartsOptions } from "@/pages/pipe-parts/configs";
import { getRemainingLifeColor, getDegradationColorPP } from "@/colorLegends";
import { shouldDisableCheckbox } from "@/features/dig-up/helpers";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsTableMapmode",

  components: {
    ResizableVirtualTable,
    LayerSelect,
    SortableCustomHeader,
    AnomaliesSubtablePopup,
  },

  props: {
    wallThicknessRemaining: Object,
    selectedWTLevel: String,

    getLayerName: Function,
    checkedLength: Number,
    isPipePartActive: Function,
  },

  setup() {
    return {
      MODES_WITH_LAYER_SELECT,
      shouldDisableCheckbox,
      pipePartsStore: usePipePartsStore(),
    };
  },

  data() {
    return {
      headers: [
        {
          title: this.$t("pipe_parts.table_titles.pipe_book_id"),
          key: "pipe_book_id",
          value: "pipe_book_id",
        },
        {
          title: this.$t("pipe_parts.table_titles.distance"),
          key: "distance",
          value: "distance",
          units: "m",
        },
        {
          title: this.$t("pipe_parts.table_titles.pipe_part_type"),
          key: "pipe_part_type",
          value: (pipePart) =>
            this.$t(`pipe_parts.types.${pipePart.pipe_part_type}`),
        },
        {
          title: this.$t("pipe_parts.table_titles.length"),
          key: "length",
          value: (pipePart) => this.$t(`materials.${pipePart.material}`),
          units: "m",
        },
        {
          title: this.$t("pipe_parts.table_titles.wall_thickness.original"),
          key: "wall_thickness_original",
          value: "wall_thickness_original",
          units: "mm",
        },
        {
          title: this.$t("pipe_parts.table_titles.diameter_class"),
          key: "diameter_class",
          value: "diameter_class",
          units: "mm",
        },
      ],
    };
  },

  computed: {
    selectedColumnName() {
      const options = getPipePartsOptions(true, this.$t, this.$units);
      return options.find(
        ({ value }) => value === this.pipePartsStore.selectedGradientMode
      )?.title;
    },

    additionalHeaders() {
      if (
        MODES_WITHOUT_ADDITIONAL_COLUMNS.includes(
          this.pipePartsStore.selectedGradientMode
        )
      ) {
        return [];
      }

      if (
        this.pipePartsStore.selectedGradientMode === "quality" ||
        this.pipePartsStore.selectedGradientMode === "quality_anomaly"
      ) {
        return [
          {
            title: this.$t("pipe_parts.table_titles.quality"),
            key: "quality",
            value: "quality",
          },
          {
            title: this.$t("pipe_parts.table_titles.quality_anomaly"),
            key: "quality_anomaly",
            value: "quality_anomaly",
          },
        ];
      }

      return [
        {
          title: this.selectedColumnName,
          key: this.pipePartsStore.selectedGradientMode,
        },
      ];
    },

    headerSlots() {
      return this.additionalHeaders.map((header) => `header.${header.key}`);
    },

    itemSlots() {
      return this.additionalHeaders.map((header) => `item.${header.key}`);
    },

    displayedHeaders() {
      return this.headers.concat(this.additionalHeaders);
    },
  },

  methods: {
    getCellValueBySelectedGradient(pipePart) {
      if (!this.pipePartsStore.selectedGradientMode) {
        return "";
      }

      if (
        this.pipePartsStore.selectedGradientMode.includes(
          "remaining_life_calculation"
        )
      ) {
        const keys = this.pipePartsStore.selectedGradientMode.split(".");

        if (
          this.pipePartsStore.selectedGradientMode ===
          "remaining_life_calculation.remaining_life_group"
        ) {
          return getRemainingLifeColor()[pipePart[keys[0]][keys[1]]].text;
        }

        if (
          [
            "remaining_life_calculation.road_distance",
            "remaining_life_calculation.ground_water_level",
          ].includes(this.pipePartsStore.selectedGradientMode)
        ) {
          return pipePart[keys[0]][keys[1]]
            ? pipePart[keys[0]][keys[1]].toFixed(2)
            : pipePart[keys[0]][keys[1]];
        }

        return pipePart[keys[0]][keys[1]];
      }

      if (this.pipePartsStore.selectedGradientMode === "degradation_level") {
        if (
          ["PVC", "HDPE", "HPPE", "PE"].some((el) => el === pipePart.material)
        ) {
          return getDegradationColorPP(this.$t)[
            pipePart[this.pipePartsStore.selectedGradientMode]
          ].textForPlastic;
        }

        return getDegradationColorPP(this.$t)[
          pipePart[this.pipePartsStore.selectedGradientMode]
        ].text;
      }

      return pipePart[this.pipePartsStore.selectedGradientMode];
    },
  },
};
</script>
