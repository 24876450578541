<template>
  <div>
    <v-btn
      v-for="scannerType in scannerTypes"
      :key="scannerType"
      variant="tonal"
      size="small"
      :color="
        scannerControlStore.selectedScannerType === scannerType
          ? '#24e3c6'
          : 'white'
      "
      :prepend-icon="SCANNER_TAB_ICONS[scannerType]"
      @click="scannerControlStore.setSelectedScannerType(scannerType)"
    >
      {{ $t(`pipe_scanner.types.${scannerType}`) }}
    </v-btn>
  </div>
</template>

<script>
import { ScannerType, SCANNER_TAB_ICONS } from "@/pages/scanner-control/config";
import { useScannerControlStore } from "@/store";

export default {
  name: "ScannersTabs",

  setup() {
    const scannerTypes = Object.values(ScannerType);

    return {
      scannerTypes,
      SCANNER_TAB_ICONS,
      scannerControlStore: useScannerControlStore(),
    };
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-left: 0.5rem;
}
</style>
