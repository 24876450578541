<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="jointsStore.hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("anomalies.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <JointsTypesSelector />
        <JointsMaterialsSelector />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {
  JointsTypesSelector,
  JointsMaterialsSelector,
} from "@/pages/joints/views/Joints/components";
import { useJointsStore } from "@/store";

export default {
  name: "FilterPopupMenu",

  emits: ["update:show"],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    JointsTypesSelector,
    JointsMaterialsSelector,
  },

  setup() {
    return {
      jointsStore: useJointsStore(),
    };
  },
};
</script>
