export const SafetyFactorWidget = {
  PipePartsTable: "pipePartsTable",
  SafetyChart: "safetyChart",
  ContributionsChart: "contributionsChart",
  HeightProfileChart: "heightProfileChart",
  ColorsLegend: "colorsLegend",
  GradientLegend: "gradientLegend",
  WMSWidget: "wmsWidget",
  WMSLegend: "wmsLegend",
};
