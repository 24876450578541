<template>
  <BaseWidget
    :title="$t('anomalies.widgets.table.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <FilterPopupMenu v-model:show="filtersVisible" />
    </template>

    <AnomaliesTable />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import FilterPopupMenu from "../FilterPopupMenu.vue";
import AnomaliesTable from "@/pages/anomaly/components/AnomaliesTable/AnomaliesTable.vue";

export default {
  name: "AnomaliesTableWidget",

  components: {
    BaseWidget,
    FilterPopupMenu,
    AnomaliesTable,
  },

  data() {
    return {
      filtersVisible: false,
    };
  },
};
</script>
