<template>
  <ResizableVirtualTableContainer v-if="resizable">
    <slot />
  </ResizableVirtualTableContainer>
  <TableContainerBase v-else>
    <slot />
  </TableContainerBase>
</template>

<script>
import TableContainerBase from "./TableContainerBase.vue";
import ResizableVirtualTableContainer from "./ResizableVirtualTableContainer.vue";

export default {
  name: "TableContainer",

  components: {
    TableContainerBase,
    ResizableVirtualTableContainer,
  },

  props: {
    resizable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
