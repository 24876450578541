<template>
  <BaseWidget :title="$t('anomalies.details.cylinder.title')" v-bind="$attrs">
    <AnomaliesCylinder />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import AnomaliesCylinder from "@/pages/anomaly/components/AnomaliesCylinder.vue";

export default {
  name: "AnomaliesTableWidget",

  components: {
    BaseWidget,
    AnomaliesCylinder,
  },
};
</script>
