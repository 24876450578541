<template>
  <div class="slider">
    <RangeSlider
      :title="$t('anomalies.filters.distance')"
      :model-value="anomaliesStore.anomalyFilters.selectedDistance"
      @update:modelValue="
        (value) => anomaliesStore.setFilters({ selectedDistance: value })
      "
      :min="anomaliesStore.minMaxDistance[0]"
      :max="anomaliesStore.minMaxDistance[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import RangeSlider from "@/components/RangeSlider";
import { useAnomaliesStore } from "@/store";

export default {
  setup() {
    return { anomaliesStore: useAnomaliesStore() };
  },

  components: {
    RangeSlider,
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 25%;
  text-align: center;
}
</style>
