<template>
  <TableContainerBase ref="tableContainerRef">
    <slot />
  </TableContainerBase>
</template>

<script>
import { ref, computed } from "vue";

import TableContainerBase from "./TableContainerBase.vue";

import { useDebouncedElementSize } from "@/composables/useDebouncedElementSize";

export default {
  name: "ResizableVirtualTableContainer",

  components: {
    TableContainerBase,
  },

  setup() {
    const tableContainerRef = ref(null);
    const { height: containerHeight } = useDebouncedElementSize(
      tableContainerRef,
      { delay: 200 }
    );

    return {
      tableContainerRef,
      containerHeight,
    };
  },

  provide() {
    return {
      containerHeight: computed(() => this.containerHeight),
    };
  },
};
</script>
