<template>
  <BaseWidget
    :title="$t('joints.widgets.heightProfile.maximize_title')"
    v-bind="$attrs"
  >
    <JointsHeightChart :visibleDistance="visibleDistance" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { JointsHeightChart } from "@/pages/joints/components";

export default {
  name: "HeightChartWidget",

  components: {
    BaseWidget,
    JointsHeightChart,
  },

  props: {
    visibleDistance: {
      type: Array,
      required: true,
    },
  },
};
</script>
