<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :zoom="7"
      :showLoader="shouldShowLoader"
      :wmsLayers="wmsLayers"
      hideBackToPipeButton
      @mb-load="onLoad"
      @mb-moveend="onMoveend"
    >
      <MapboxMarker
        v-if="mapMarker"
        :lng-lat="mapMarker"
        :scale="0.9"
        color="orange"
      />
      <template v-slot:sources>
        <MapboxSource
          v-for="source in pipelinesMapData.sources"
          :key="source.id"
          :id="source.id"
          :options="source.options"
        />
        <MapboxLayer
          v-for="layer in pipelinesMapData.layers"
          :key="layer.id"
          :id="layer.id"
          :options="layer"
          @mb-mouseenter="onLayerMouseEnter"
          @mb-mouseleave="onLayerMouseLeave"
          @mb-click="onLayerClick"
        />
        <MapboxSource
          v-for="source in statsMapData.sources"
          :key="source.id"
          :id="source.id"
          :options="source.options"
        />
        <MapboxLayer
          v-for="layer in statsMapData.layers"
          :key="layer.id"
          :id="layer.id"
          :options="layer"
        />
      </template>
      <MapboxPopup
        v-if="!!popupPipename"
        :lng-lat="popupPipename.lngLat"
        :closeButton="false"
      >
        <div class="popup-content">{{ popupPipename.name }}</div>
      </MapboxPopup>
      <template v-slot:append>
        <WidgetsList
          :widgets="widgets"
          v-if="!pipelinesStore.isLoadingPipelines"
        >
          <template v-slot:[DashboardWidget.PipelineStatistics]="props">
            <PipeStatisticsWidget
              v-bind="props"
              @click:closePipeline="onClosePipeline"
              @click:moveToPipeline="flyToPipe"
              @click:settings="$refs.refThresholdsModal?.showModal"
            />
          </template>

          <template v-slot:[DashboardWidget.HeightProfileChart]="props">
            <HeightProfileWidget @changePoint="setMapMarker" v-bind="props" />
          </template>

          <template v-slot:[DashboardWidget.WMSWidget]="props">
            <WMSWidget v-model:wmsLayers="wmsLayers" v-bind="props" />
          </template>

          <template v-slot:[DashboardWidget.WMSLegend]="props">
            <WMSLegendWidget
              :wmsLayers="wmsLayers"
              :currentZoom="currentZoom"
              v-bind="props"
            />
          </template>
        </WidgetsList>
      </template>
    </ModifiedMapbox>
    <ThresholdsModal
      ref="refThresholdsModal"
      @save="pipelinesStore.getInspectionStats"
    />
  </div>
</template>

<script>
import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";

import { ModifiedMapbox } from "@/components";
import { ThresholdsModal } from "@/components/modals";
import { WidgetsList } from "@/components/map-componets/widgets";
import {
  HeightProfileWidget,
  PipeStatisticsWidget,
} from "./components/widgets";
import {
  WMSWidget,
  WMSLegendWidget,
} from "@/components/map-componets/widgets/shared";

import { fitMapToCoordinates } from "@/utils/mapHelpers.js";

import { DEFAULT_CENTER } from "@/constants/mapBox";
import { DashboardWidget } from "./config";
import { useAuthStore, useGlobalStore, usePipelinesStore } from "@/store";

const geomColors = {
  h2s_geom: "#ff0303",
  local_leaching_geom: "#7c05ff",
  deformation_geom: "#fce935",
  anomaly_geom: "#3202d1",
  critical_joints_geom: "#f702e7",
  critical_joints_geom_gap: "#ff8400",
};

export default {
  name: "PipelinesMapmode",

  components: {
    ModifiedMapbox,
    MapboxLayer,
    MapboxSource,
    MapboxMarker,
    MapboxPopup,
    ThresholdsModal,
    WidgetsList,

    PipeStatisticsWidget,
    HeightProfileWidget,
    WMSWidget,
    WMSLegendWidget,
  },

  props: {
    onSelectPipelineId: Function,
  },

  setup() {
    return {
      DashboardWidget,
      pipelinesStore: usePipelinesStore(),
      authStore: useAuthStore(),
      globalStore: useGlobalStore(),
    };
  },

  provide() {
    return {
      statsSwitched: this.statsSwitched,
      toggleSwitches: this.toggleStatsLayer,
    };
  },

  data() {
    return {
      mapMarker: null,
      statsSwitched: [],
      popupPipename: null,
      currentZoom: null,
      wmsLayers: [],
    };
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    shouldShowLoader() {
      return (
        this.pipelinesStore.isLoadingPipelines ||
        this.pipelinesStore.isLoadingStats ||
        this.authStore.isLoadingGroupData
      );
    },
    map() {
      return this.$refs.modifiedMapRef.map;
    },

    widgets() {
      return {
        [DashboardWidget.PipelineStatistics]: {
          key: DashboardWidget.PipelineStatistics,
          title: this.$t("dashboard.widgets.dashboardStats.maximize_title"),
          initialWidth: 500,
          initialHeight: 670,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[DashboardWidget.PipelineStatistics].initialWidth + 5),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[DashboardWidget.PipelineStatistics].initialHeight + 30),
          initiallyMinimized: false,
          hidden: !(
            this.pipelinesStore.selectedPipeline &&
            !this.pipelinesStore.isLoadingStats
          ),
        },

        [DashboardWidget.HeightProfileChart]: {
          key: DashboardWidget.HeightProfileChart,
          title: this.$t("dashboard.widgets.heightProfile.maximize_title"),
          initialWidth: 700,
          initialHeight: 400,
          getInitialX: (container, widgets) =>
            container.offsetHeight -
            (widgets[DashboardWidget.HeightProfileChart].initialWidth + 15),
          getInitialY: () => 150,
          initiallyMinimized: true,
          hidden: !(
            this.pipelinesStore.selectedPipeline &&
            !this.pipelinesStore.isLoadingStats
          ),
          loading: true, // TODO: check what it is.
        },

        [DashboardWidget.WMSWidget]: {
          key: DashboardWidget.WMSWidget,
          title: this.$t("dashboard.widgets.color.maximize_title"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[DashboardWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 3,
          initiallyMinimized: false,
        },

        [DashboardWidget.WMSLegend]: {
          key: DashboardWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    pipelinesMapData() {
      const sources = [];
      const layers = [];

      if (this.pipelinesStore.groupPipelines?.length) {
        this.pipelinesStore.groupPipelines.forEach((pipe) => {
          sources.push({
            id: pipe.id,
            type: "geojson",
            options: {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {
                  name: pipe.pipeline_name,
                },
                geometry: {
                  type: pipe.geom.type,
                  coordinates: pipe.geom.coordinates,
                },
              },
            },
          });

          sources.push({
            id: `points_${pipe.id}`,
            type: "geojson",
            options: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: ["start", "end"].map((t) => ({
                  type: "Feature",
                  properties: {
                    type: t,
                    name: pipe.pipeline_name,
                  },
                  geometry: {
                    type: "Point",
                    coordinates:
                      t === "start"
                        ? pipe.geom.coordinates[0]
                        : pipe.geom.coordinates[
                            pipe.geom.coordinates.length - 1
                          ],
                  },
                })),
              },
            },
          });

          layers.push({
            id: `layer_${pipe.id}`,
            type: "line",
            source: pipe.id,
            layout: {
              visibility: "visible",
            },
            paint: {
              "line-color":
                pipe.id === this.pipelinesStore.selectedPipeline?.id
                  ? "#0a9e87"
                  : "#24e3c6",
              "line-width": 5,
              "line-opacity": 1,
            },
          });

          layers.push({
            id: `layer_points_${pipe.id}`,
            type: "circle",
            source: `points_${pipe.id}`,
            layout: {
              visibility: "visible",
            },
            paint: {
              "circle-color": "#FCFC7A",
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                3,
                22,
                6,
              ],
              "circle-opacity": 0.7,
            },
          });
        });
      }

      return { sources, layers };
    },

    statsMapData() {
      const layers = [];
      const sources = [];

      this.statsSwitched.forEach((geomName) => {
        sources.push({
          id: geomName,
          type: "geojson",
          options: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: this.pipelinesStore.selectedInspectionStats[
                geomName
              ]?.map((x) => {
                return {
                  type: "Feature",
                  properties: {},
                  geometry: x,
                };
              }),
            },
          },
        });

        layers.push({
          id: `layer_${geomName}`,
          type: "circle",
          source: geomName,
          layout: {
            visibility: "visible",
          },
          paint: {
            "circle-radius": {
              base: 1.75,
              stops: [
                [12, 2],
                [22, 180],
              ],
            },
            "circle-color": geomColors[geomName] || "#AB63FA",
            "circle-opacity": 0.7,
          },
        });
      });

      return { sources, layers };
    },

    allPipelinesCoordianates() {
      if (!this.pipelinesStore.groupPipelines.length) {
        return [DEFAULT_CENTER];
      }

      const allCoordinates = this.pipelinesStore.groupPipelines
        .map((pipe) => pipe.geom.coordinates)
        .flat();

      return allCoordinates;
    },
  },

  methods: {
    onClosePipeline() {
      this.setMapMarker(null);
      this.flyToPipelines();
      this.globalStore.clearSelectedPipeData();
    },

    setMapMarker(lngLat) {
      this.mapMarker = lngLat;
    },

    onLoad() {
      if (this.pipelinesStore.selectedPipeline) {
        const coordinates =
          this.pipelinesStore.selectedPipeline.geom.coordinates;
        const middleIndex = Math.floor(coordinates.length / 2);
        this.setMapMarker(coordinates[middleIndex]);
        this.flyToPipe();
      } else if (this.pipelinesStore.groupPipelines.length) {
        this.flyToPipelines();
      }
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
    },

    onLayerClick(target) {
      const clickedPipelineId = target.features[0].source.replace(
        "points_",
        ""
      );

      this.onSelectPipelineId(clickedPipelineId);
    },

    onLayerMouseEnter(target) {
      this.popupPipename = {
        lngLat: target.lngLat,
        name: target.features[0].properties.name,
      };
      target.target.getCanvas().style.cursor = "pointer";
    },

    onLayerMouseLeave(target) {
      this.popupPipename = null;
      target.target.getCanvas().style.cursor = "";
    },

    toggleStatsLayer(geomName) {
      if (this.statsSwitched.includes(geomName)) {
        this.statsSwitched = this.statsSwitched.filter((id) => id !== geomName);
      } else {
        this.statsSwitched.push(geomName);
      }
    },

    highliteSelectedLayer(newId, prevId) {
      if (newId) {
        this.map.setPaintProperty(`layer_${newId}`, "line-color", "#0a9e87");
      }
      if (prevId) {
        this.map.setPaintProperty(`layer_${prevId}`, "line-color", "#24e3c6");
      }
    },

    flyToPipe() {
      const coordinates = this.pipelinesStore.selectedPipeline.geom.coordinates;
      const middleIndex = Math.floor(coordinates.length / 2);
      this.setMapMarker(coordinates[middleIndex]);

      fitMapToCoordinates(this.map, coordinates);
    },

    flyToPipelines() {
      fitMapToCoordinates(this.map, this.allPipelinesCoordianates);
    },
  },

  watch: {
    "pipelinesStore.groupPipelines"(newPipelines) {
      this.setMapMarker(null);
      if (newPipelines.length) {
        this.flyToPipelines();
      }
    },

    "pipelinesStore.selectedPipeline"(newPipeline, prevPipeline) {
      this.statsSwitched = [];
      this.highliteSelectedLayer(newPipeline?.id, prevPipeline?.id);

      if (newPipeline) {
        this.flyToPipe();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.popup-content {
  padding: 10px;
  font-weight: 600;
}
</style>
