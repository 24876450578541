<template>
  <div class="wrapper">
    <span v-if="!mapStore.isMapMode" class="title">{{ title }}</span>
    <div
      v-for="key in legendList"
      :class="computeClass(key)"
      :key="key"
      @click="onItemClick(key)"
    >
      <div
        class="dot"
        :style="{
          backgroundColor: getValues(key, 'color'),
        }"
      ></div>
      <div>{{ getValues(key, "text") }}</div>
    </div>
  </div>
</template>

<script>
import { GradientType } from "@/pages/joints/config";
import {
  anomalyTypeColors,
  getDegradationColorJoints,
} from "@/colorLegends.js";
import { materialColors, jointTypeColors } from "@/colorLegends.js";
import { useJointsStore, useMapStore } from "@/store";

export default {
  name: "JointsLegend",

  setup() {
    return {
      anomalyTypeColors,
      mapStore: useMapStore(),
      jointsStore: useJointsStore(),
    };
  },

  computed: {
    legendList() {
      switch (this.jointsStore.selectedGradientMode) {
        case GradientType.Material:
          return this.jointsStore.materials;

        case GradientType.JointType:
          return this.jointsStore.jointTypes;

        case GradientType.DegradationLevel:
          return getDegradationColorJoints();

        default:
          return [];
      }
    },
  },

  methods: {
    onItemClick(key) {
      if (this.legendList.length === 1) {
        return;
      }

      this.jointsStore.toggleSelectedLegend(this.getValues(key, "text"));
    },

    getValues(key, type) {
      switch (this.jointsStore.selectedGradientMode) {
        case GradientType.Material:
          if (type === "text") {
            return this.$t(
              `jointsStore.materials.${materialColors[key][type]}`
            );
          } else {
            return materialColors[key][type];
          }

        case "degradation_level":
          return key[type];

        case "joint_type":
          if (type === "text") {
            return this.$t(`joints.types.${jointTypeColors[key][type]}`);
          } else {
            return jointTypeColors[key][type];
          }

        default:
          return "";
      }
    },

    computeClass(key) {
      const itemName = this.getValues(key, "text");
      const isSelected =
        !this.jointsStore.selectedLegend ||
        this.jointsStore.selectedLegend === itemName;

      return ["item", "pointer", { selected: isSelected }];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 8px 12px;
  border-radius: 6px;
  width: fit-content;
  background-color: white;

  display: flex;
  flex-direction: column;
  .title {
    font-size: 1rem;
  }

  .item {
    display: flex;
    align-items: center;
    margin-top: 6px;
    opacity: 0.3;
    &.pointer {
      cursor: pointer;
    }
    &.selected {
      opacity: 1;
    }

    .dot {
      margin-right: 6px;
      margin-bottom: 3px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }
}
</style>
