<template>
  <WidgetTabBase v-bind="$attrs">
    <template v-slot:append>
      <FiltersIndicator />
    </template>
  </WidgetTabBase>
</template>

<script>
import WidgetTabBase from "../WidgetTabBase.vue";
import FiltersIndicator from "./FiltersIndicator.vue";

export default {
  name: "FiltersWidgetTab",

  components: {
    WidgetTabBase,
    FiltersIndicator,
  },
};
</script>
