<template>
  <VCard
    class="widget-card"
    :class="{
      'widget-card-active': this.active,
    }"
  >
    <VCardTitle
      v-if="showTitle"
      class="card-title"
      :class="{ 'card-title-draggable': draggable }"
    >
      <div class="title-text font-weight-thin">
        <slot name="title-text">
          {{ title }}
        </slot>
      </div>

      <div class="actions">
        <slot name="actions" />

        <MinimizeWidgetButton
          v-if="minimizable"
          @click="$emit('minimize', $event)"
        />
      </div>
    </VCardTitle>

    <VCardText class="card-text">
      <slot v-if="!loading" />

      <slot name="content-loader" v-else>
        <div class="default-loader-container">
          <VProgressCircular indeterminate />
        </div>
      </slot>
    </VCardText>
  </VCard>
</template>

<script>
import MinimizeWidgetButton from "./MinimizeWidgetButton.vue";

export default {
  name: "WidgetCard",

  emits: ["minimize"],

  components: {
    MinimizeWidgetButton,
  },

  props: {
    draggable: {
      type: Boolean,
      default: true,
    },

    minimizable: {
      type: Boolean,
      default: true,
    },

    resizable: {
      type: Boolean,
      default: true,
    },

    active: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
    },

    showTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.widget-card {
  height: 100%;
  opacity: 0.8;
  z-index: auto;
  display: flex;
  flex-direction: column;
}

.widget-card-active {
  opacity: 1;
  z-index: 100;
}

.card-title {
  display: flex;
  justify-content: space-between;
  user-select: none;
  text-wrap: wrap;
}

.card-title-draggable {
  cursor: move;
}

.actions {
  display: flex;
  align-items: center;
  color: white;
}

.card-text {
  padding: 0 !important;
  overflow-x: hidden;
  flex-grow: 1;
}

.default-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
