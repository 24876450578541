<template>
  <div class="statistics-toolbar-container">
    <VBtn
      variant="outlined"
      color="red"
      size="x-small"
      @click="$emit('click:closePipeline')"
    >
      {{ $t("dashboard.pipelines.close") }}
    </VBtn>
    <VBtn
      :style="{ margin: '0 4px 0 10px' }"
      size="x-small"
      @click="$emit('click:moveToPipeline')"
      >{{ $t("move_to_pipe") }}</VBtn
    >
    <VBtn
      variant="text"
      density="compact"
      icon="mdi-cog"
      @click.stop="$emit('click:settings')"
      @mousedown.stop
    ></VBtn>
  </div>
</template>

<script>
export default {
  name: "StatisticsWidgetToolbar",

  emits: {
    "click:closePipeline": null,
    "click:moveToPipeline": null,
    "click:settings": null,
  },
};
</script>

<style scoped lang="scss">
.statistics-toolbar-container {
  display: flex;
  align-items: center;
}
</style>
