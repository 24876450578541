<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      :zoom="7"
      :wmsLayers="wmsLayers"
      :filteredScannersMapData="filteredScannersMapData"
      @mb-moveend="onMoveend"
      @mb-load="
        centerMapToPipeScanners(
          scannerControlStore.currentFilteredScanners,
          tab
        )
      "
      ref="modifiedMapRef"
      hideBackToPipeButton
    >
      <template v-slot:append>
        <WidgetsList :widgets="widgets" :widgetsBarConfig="widgetsBarConfig">
          <template v-slot:[ScannerControlWidget.ScannersTable]="props">
            <ScannerControlTableWidget
              @scannerSelect="(scanner) => handleTableItemSelect(scanner)"
              @scannerEdit="({ id, type }) => openEditModel(id, type)"
              @scannerDetails="(id) => openDetailedModal(id)"
              v-bind="props"
            />
          </template>

          <template v-slot:[ScannerControlWidget.WMSWidget]="props">
            <WMSWidget v-model:wmsLayers="wmsLayers" v-bind="props" />
          </template>

          <template v-slot:[ScannerControlWidget.WMSLegend]="props">
            <WMSLegendWidget
              :wmsLayers="wmsLayers"
              :currentZoom="currentZoom"
              v-bind="props"
            />
          </template>
        </WidgetsList>

        <ScannerLoaders class="scanner-loaders" :visible="hasLoadingScanners" />
      </template>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { computed } from "vue";
import { ModifiedMapbox } from "@/components";
import { ScannerLoaders } from "./components";
import { WidgetsList } from "@/components/map-componets/widgets";
import { ScannerControlTableWidget } from "./components/widgets";
import {
  WMSWidget,
  WMSLegendWidget,
} from "@/components/map-componets/widgets/shared";

import { getScannersClusterSourceAndLayers } from "@/features/pipescanner/helpers";
import { fitMapToCoordinates } from "@/utils/mapHelpers";

import { ScannerType } from "@/pages/scanner-control/config";
import { ScannerControlWidget } from "./config";
import { usePipeScannersStore, useScannerControlStore } from "@/store";

export default {
  name: "ScannerControlMapmode",

  components: {
    ModifiedMapbox,
    ScannerLoaders,
    WidgetsList,
    ScannerControlTableWidget,
    WMSWidget,
    WMSLegendWidget,
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  setup() {
    return {
      ScannerType,
      ScannerControlWidget,
      pipeScannersStore: usePipeScannersStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  data() {
    return {
      currentZoom: null,
      wmsLayers: [],
      filtersShown: false,
    };
  },

  computed: {
    widgets() {
      return {
        [ScannerControlWidget.ScannersTable]: {
          key: ScannerControlWidget.ScannersTable,
          title: this.$t("pipe_scanner.pipescanners"),
          initialWidth: 840,
          initialHeight: 450,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[ScannerControlWidget.ScannersTable].initialWidth + 5),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[ScannerControlWidget.ScannersTable].initialHeight + 5),
          initiallyMinimized: true,
        },

        [ScannerControlWidget.WMSWidget]: {
          key: ScannerControlWidget.WMSWidget,
          title: this.$t("dashboard.widgets.color.maximize_title"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[ScannerControlWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 5,
          initiallyMinimized: false,

          displayConfig: {
            isResizable: false,
          },
        },

        [ScannerControlWidget.WMSLegend]: {
          key: ScannerControlWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    widgetsBarConfig() {
      return {
        [ScannerControlWidget.ScannersTable]: {
          showFiltersIndicator: this.scannerControlStore.hasFilteredScanners,
        },
      };
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    filteredScannersMapData() {
      return getScannersClusterSourceAndLayers(
        this.scannerControlStore.currentFilteredScanners,
        this.scannerControlStore.selectedScannerType
      );
    },

    hasLoadingScanners() {
      return Object.values(this.pipeScannersStore.isLoading).some(
        (value) => value
      );
    },
  },

  methods: {
    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
    },

    openEditModel(id, status) {
      this.$refs.modifiedMapRef.openEditModel(id, status);
    },

    openDetailedModal(id) {
      this.$refs.modifiedMapRef.openDetailedModal(id);
    },

    handleTableItemSelect(scanner) {
      this.map.jumpTo({
        center: [scanner.longitude, scanner.latitude],
        zoom: 17,
      });
    },

    getPipeScannerCoordinates(pipeScanners) {
      const coordinates = pipeScanners.map((pipeScanner) => [
        pipeScanner.longitude,
        pipeScanner.latitude,
      ]);

      return coordinates;
    },

    centerMapToPipeScanners(pipeScanners) {
      const coordinates = this.getPipeScannerCoordinates(pipeScanners);

      if (coordinates.length === 0) {
        return;
      }

      fitMapToCoordinates(this.map, coordinates, { padding: 200 });
    },
  },

  watch: {
    "pipeScannersStore.pipeScanners": {
      handler() {
        this.centerMapToPipeScanners(
          this.scannerControlStore.currentFilteredScanners
        );
      },

      deep: true,
    },

    "scannerControlStore.selectedScannerType"() {
      if (this.activeScanner) {
        this.centerMapToPipeScanners([this.activeScanner]);
        return;
      }

      this.centerMapToPipeScanners(
        this.scannerControlStore.currentFilteredScanners
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  .scanner-marker {
    cursor: pointer;
  }

  .filter-indicator {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #d9534f;
  }

  .scanner-loaders {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 500px;
  }
}
</style>
