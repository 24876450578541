<template>
  <BaseWidget :title="title" v-bind="$attrs">
    <template v-slot:actions>
      <ColorsLegendToolbar
        @click:info="$emit('click:info')"
        @click:settings="$emit('click:settings')"
      />
    </template>

    <JointsLegend />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { JointsLegend } from "@/pages/joints/components";
import ColorsLegendToolbar from "../ColorsLegendToolbar.vue";

export default {
  name: "ColorsLegendWidget",

  components: {
    BaseWidget,
    JointsLegend,
    ColorsLegendToolbar,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  emits: {
    "click:info": null,
    "click:settings": null,
  },
};
</script>
