<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="jointsStore.isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      forceHideScanners
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <template v-if="jointsStore.selectedJoint">
        <MapboxMarker
          :lng-lat="jointsStore.selectedJoint.geom.coordinates"
          :scale="0.9"
          color="orange"
        >
        </MapboxMarker>
        <MapboxPopup :lng-lat="jointsStore.selectedJoint.geom.coordinates">
          <MapInfoPopup :data="jointsStore.selectedJoint" type="joint" />
        </MapboxPopup>
      </template>

      <template v-slot:sources="{ openDigupMenu }">
        <template
          v-for="{ id, sourceOptions, layerOptions } in jointsMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
            @mb-contextmenu="onLayerContextmenu(layerOptions.id, openDigupMenu)"
          />
        </template>
      </template>

      <template v-slot:append>
        <WidgetsList
          :widgets="widgets"
          :widgetsBarConfig="widgetsBarConfig"
          v-if="!jointsStore.isLoading"
        >
          <template v-slot:[JointsWidget.JointsTable]="props">
            <JointsTableWidget v-bind="props" />
          </template>

          <template v-slot:[JointsWidget.HeightProfileChart]="props">
            <HeightChartWidget
              :visibleDistance="visibleDistance"
              v-bind="props"
            />
          </template>

          <template v-slot:[JointsWidget.Histogram]="props">
            <HistogramWidget :title="histogramTitle" v-bind="props" />
          </template>

          <template v-slot:[JointsWidget.GradientLegend]="props">
            <GradientLegendWidget
              :gradientMode="jointsStore.selectedGradientMode"
              :gradientColor="gradientColor"
              @click:info="showInfoModal()"
              v-bind="props"
            />
          </template>

          <template
            v-slot:[JointsWidget.ColorsLegend]="props"
            v-if="jointsStore.selectedGradientMode === 'degradation_level'"
          >
            <ColorsLegendWidget
              :title="histogramTitle"
              @click:settings="showThresholdsModal"
              @click:info="showInfoModal"
              v-bind="props"
            />
          </template>

          <template v-slot:[JointsWidget.WMSWidget]="props">
            <WMSWidget
              v-model:wmsLayers="wmsLayers"
              :colorMode="jointsStore.selectedGradientMode"
              :colorOptions="gradientOptions"
              @update:colorMode="onSelectGradientMode"
              v-bind="props"
            />
          </template>

          <template v-slot:[JointsWidget.WMSLegend]="props">
            <WMSLegendWidget
              :wmsLayers="wmsLayers"
              :currentZoom="currentZoom"
              v-bind="props"
            />
          </template>
        </WidgetsList>
      </template>
    </ModifiedMapbox>
    <ThresholdsModal ref="refThresholdsModal" />
    <LegendInfoModal
      ref="refLegendInfoModal"
      page="jointsStore.jointsWithIds"
      :selectMode="jointsStore.selectedGradientMode"
    />
  </div>
</template>
<script>
import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { MapInfoPopup, ModifiedMapbox } from "@/components";
import { ThresholdsModal, LegendInfoModal } from "@/components/modals";
import { WidgetsList } from "@/components/map-componets/widgets";
import {
  JointsTableWidget,
  HistogramWidget,
  HeightChartWidget,
  GradientLegendWidget,
  ColorsLegendWidget,
} from "./components/widgets";
import {
  WMSWidget,
  WMSLegendWidget,
} from "@/components/map-componets/widgets/shared";

import { JointsWidget } from "./config";
import { useJointsStore } from "@/store";

export default {
  name: "JointsMapmode",

  components: {
    ModifiedMapbox,
    MapboxMarker,
    MapboxPopup,
    MapboxLayer,
    MapboxSource,
    MapInfoPopup,
    ThresholdsModal,
    LegendInfoModal,
    WidgetsList,

    JointsTableWidget,
    HistogramWidget,
    HeightChartWidget,
    GradientLegendWidget,
    ColorsLegendWidget,
    WMSWidget,
    WMSLegendWidget,
  },

  props: {
    gradientOptions: {
      type: Array,
      required: true,
    },

    gradientColor: {
      type: Array,
      required: true,
    },

    jointsMapData: {
      type: Array,
      required: true,
    },

    histogramTitle: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      JointsWidget,
      jointsStore: useJointsStore(),
    };
  },

  data() {
    return {
      visibleDistance: [],
      wmsLayers: [],
      currentZoom: null,
      showFilter: false,
    };
  },

  computed: {
    widgets() {
      return {
        [JointsWidget.JointsTable]: {
          key: JointsWidget.JointsTable,
          title: this.$t("joints.widgets.table.maximize_title"),
          initialWidth: 600,
          initialHeight: 355,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[JointsWidget.JointsTable].initialWidth - 10),
          getInitialY: () => 100,
          initiallyMinimized: false,
        },

        [JointsWidget.HeightProfileChart]: {
          key: JointsWidget.HeightProfileChart,
          title: this.$t("joints.widgets.heightProfile.maximize_title"),
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[JointsWidget.HeightProfileChart].initialWidth + 30),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[JointsWidget.HeightProfileChart].initialHeight + 30),
          initiallyMinimized: true,
        },

        [JointsWidget.Histogram]: {
          key: JointsWidget.Histogram,
          title: this.histogramTitle,
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[JointsWidget.Histogram].initialHeight + 40),
          initiallyMinimized: false,
        },

        [JointsWidget.GradientLegend]: {
          key: JointsWidget.GradientLegend,
          title: this.$t("joints.widgets.gradient.maximize_title"),
          initialWidth: 130,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: !this.showGradient,
          resizable: false,
          draggable: false,
          minimizable: false,
        },

        [JointsWidget.ColorsLegend]: {
          key: JointsWidget.ColorsLegend,
          title: this.histogramTitle,
          initialWidth: 230,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: this.showGradient,
          resizable: false,
          draggable: false,
          minimizable: false,
        },

        [JointsWidget.WMSWidget]: {
          key: JointsWidget.WMSWidget,
          title: this.$t("dashboard.widgets.color.maximize_title"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[JointsWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 5,
          initiallyMinimized: false,
          resizable: false,
        },

        [JointsWidget.WMSLegend]: {
          key: JointsWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    widgetsBarConfig() {
      return {
        [JointsWidget.JointsTable]: {
          showFiltersIndicator: this.jointsStore.hasActiveFilters,
        },
      };
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    showGradient() {
      return !["degradation_level", "material", "joint_type"].includes(
        this.jointsStore.selectedGradientMode
      );
    },
  },

  methods: {
    showThresholdsModal() {
      this.$refs.refThresholdsModal?.showModal();
    },

    showInfoModal() {
      this.$refs.refLegendInfoModal?.open();
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      if (map.getLayer("layer_joints")) {
        this.visibleDistance = map
          .queryRenderedFeatures({ layers: ["layer_joints"] })
          .map((joint) => joint.properties.distance);
      }
    },

    onLayerContextmenu(layeerId, callback) {
      if (layeerId.includes("selected_joints")) {
        callback();
      }
    },

    onLayerClick({ features, originalEvent }) {
      const { ctrlKey, shiftKey } = originalEvent;

      if (features[0].source.includes("joints")) {
        const selectedId = features[0].properties?.id;
        if (ctrlKey) {
          this.jointsStore.jointsSelector.setByCtrl(selectedId);
        } else if (shiftKey) {
          this.jointsStore.jointsSelector.setByShift(
            selectedId,
            this.jointsStore.filteredJoints
          );
        } else {
          this.jointsStore.setSelectedJointId(null);
          Promise.resolve().then(() =>
            this.jointsStore.setSelectedJointId(selectedId)
          );
        }
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features[0].source.includes("joints")) {
        target.getCanvas().style.cursor = "pointer";
        this.map.boxZoom.disable();
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
      this.map.boxZoom.enable();
    },

    onSelectGradientMode(gradientMode) {
      this.jointsStore.setSelectedGradientMode(gradientMode);
    },
  },

  watch: {
    "jointsStore.selectedJoint"(joint) {
      if (joint) {
        this.map.flyTo({
          center: joint.geom.coordinates,
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
