<template>
  <v-select
    :label="$t('anomalies.filters.all_types')"
    :model-value="anomaliesStore.anomalyFilters.selectedAnomaliesTypes"
    @update:modelValue="
      (value) => anomaliesStore.setFilters({ selectedAnomaliesTypes: value })
    "
    :items="anomaliesTypesOptions"
    multiple
    variant="underlined"
  ></v-select>
</template>

<script>
import { useAnomaliesStore } from "@/store";

export default {
  setup() {
    return {
      anomaliesStore: useAnomaliesStore(),
    };
  },

  computed: {
    anomaliesTypesOptions() {
      return this.anomaliesStore.anomaliesTypes.map((type) => ({
        value: type,
        title: this.$t(`anomalies.types.${type}`),
      }));
    },
  },
};
</script>
