<template>
  <div class="table-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "TableContainerBase",
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/custom-style.scss" as styles;

.table-container {
  height: 100%;
  cursor: pointer;
  overflow: auto hidden;

  @include styles.scrollbar;
}
</style>
