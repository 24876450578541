<template>
  <TableContainer
    v-if="safetyFactorStore.filteredPipeParts.length > 0"
    :resizable="mapStore.isMapMode"
  >
    <SafetyTableMapmode
      v-if="mapStore.isMapMode"
      :filteredPipeParts="safetyFactorStore.filteredPipeParts"
      :isPipePartActive="isPipePartActive"
      @click:row="(pipePartId) => onSelectPipePartId(pipePartId)"
    />
    <SafetyTableRegular
      v-else
      :filteredPipeParts="safetyFactorStore.filteredPipeParts"
      :isPipePartActive="isPipePartActive"
      @click:row="(pipePartId) => onSelectPipePartId(pipePartId)"
    />
  </TableContainer>
</template>

<script>
import SafetyTableMapmode from "./SafetyTableMapmode";
import SafetyTableRegular from "./SafetyTableRegular";
import { TableContainer } from "@/components/table-components/containers";
import { useMapStore, useSafetyFactorStore } from "@/store";

export default {
  name: "SafetyTable",

  setup() {
    return {
      mapStore: useMapStore(),
      safetyFactorStore: useSafetyFactorStore(),
    };
  },

  components: {
    SafetyTableMapmode,
    SafetyTableRegular,
    TableContainer,
  },

  methods: {
    onSelectPipePartId(id) {
      this.safetyFactorStore.setSelectedPipePartId(id);
    },

    isPipePartActive(pipePart) {
      return pipePart.id === this.safetyFactorStore.selectedPipePart?.id;
    },
  },
};
</script>
