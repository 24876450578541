<template>
  <VTooltip :text="$t('common.has_active_filters')" location="top">
    <template v-slot:activator="{ props }">
      <div class="filters-indicator" v-bind="props" />
    </template>
  </VTooltip>
</template>

<script>
export default {
  name: "FiltersIndicator",
};
</script>

<style scoped lang="scss">
.filters-indicator {
  margin-left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d9534f;
}
</style>
