<template>
  <div class="wrapper">
    <div class="map-block">
      <v-select
        :model-value="pipePartsStore.selectedGradientMode"
        @update:modelValue="
          (value) => pipePartsStore.setSelectedGradientMode(value)
        "
        :items="gradientOptions"
        variant="underlined"
        hide-details
      >
        <template v-slot:item="{ item, props }">
          <v-list-item
            v-bind="props"
            :disabled="item.raw.disabled"
          ></v-list-item>
        </template>
      </v-select>
      <div style="height: 450px">
        <ModifiedMapbox
          ref="modifiedMapRef"
          hideMapControls
          style="border-radius: 4px"
        >
          <PipePartsLegend
            v-if="!showGradient"
            class="legend"
            :title="legendTitle"
          />
          <MapboxMarker
            v-if="pipePartsStore.selectedPipePart"
            :lng-lat="pipePartsStore.selectedPipePart.geom.coordinates[0]"
            color="orange"
          />
          <MapInfoPopup
            v-if="pipePartsStore.selectedPipePart && showMapInfo"
            :closePopup="closeMapInfo"
            :data="pipePartsStore.selectedPipePart"
            class="map-info"
            type="pipepart"
            :activeValue="
              gradientOptions.find(
                (option) => option.value === pipePartsStore.selectedGradientMode
              )
            "
          />

          <template v-slot:sources>
            <template
              v-for="{ id, sourceOptions, layerOptions } in pipePartsMapData"
              :key="id"
            >
              <MapboxSource :id="id" :options="sourceOptions" />
              <MapboxLayer
                :id="layerOptions.id"
                :options="layerOptions"
                @mb-mouseenter="onLayerMouseEnter"
                @mb-mouseleave="onLayerMouseLeave"
                @mb-click="onLayerClick"
              />
            </template>
          </template>

          <v-btn
            v-if="pipePartsStore.selectedPipePart && !showMapInfo"
            class="open-info-btn"
            icon="mdi-open-in-new"
            size="x-small"
            @click="openMapInfo"
          ></v-btn>
        </ModifiedMapbox>
      </div>
    </div>
    <div v-if="showGradient">
      <PipePartsGradient :gradientColor="gradientColor" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { MapInfoPopup, ModifiedMapbox } from "@/components";
import { PipePartsGradient } from "@/pages/pipe-parts/components";
import PipePartsLegend from "./PipePartsLegend.vue";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsMap",

  props: {
    gradientColor: Array,
    gradientOptions: Array,
    pipePartsMapData: Array,
    legendTitle: String,
  },

  components: {
    MapboxMarker,
    MapboxLayer,
    MapboxSource,
    PipePartsGradient,
    PipePartsLegend,
    MapInfoPopup,
    ModifiedMapbox,
  },

  setup() {
    const showMapInfo = ref(true);
    const openMapInfo = () => {
      showMapInfo.value = true;
    };

    const closeMapInfo = () => {
      showMapInfo.value = false;
    };

    return {
      showMapInfo,
      openMapInfo,
      closeMapInfo,
      pipePartsStore: usePipePartsStore(),
    };
  },

  computed: {
    showGradient() {
      return ![
        "quality_anomaly",
        "degradation_level",
        "remaining_life_calculation.remaining_life_group",
        "material",
        "remaining_life_calculation.road_type",
        "remaining_life_calculation.soil_type",
      ].includes(this.pipePartsStore.selectedGradientMode);
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    onLayerClick({ features }) {
      if (features && features[0].source.includes("pipe_parts")) {
        this.pipePartsStore.setSelectedPipePartId(features[0].properties.id);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    "pipePartsStore.selectedPipePart"(pipePart) {
      if (pipePart) {
        this.map.flyTo({
          center: pipePart.geom.coordinates[0],
          zoom: 17,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  .map-block {
    width: 100%;
    margin-bottom: 1.2rem;
    position: relative;
    .v-select {
      padding: 0 0.4rem;
      margin-bottom: 0.8rem;
    }
    .legend {
      position: absolute;
      z-index: 5;
      bottom: 6px;
      right: 5px;
      opacity: 0.9;
      user-select: none;
    }
    .map-info {
      position: absolute;
      bottom: 6px;
      left: 5px;
      border-radius: 6px;
      background-color: white;
      opacity: 0.9;
    }
    .open-info-btn {
      position: absolute;
      bottom: 6px;
      left: 5px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
