<template>
  <VBtn
    @click="$emit('click', $event)"
    @mousedown.stop
    variant="text"
    density="compact"
    icon="mdi-arrow-down-drop-circle-outline"
    title="minimize"
    class="minimize-button"
  />
</template>

<script>
export default {
  name: "MinimizeWidgetButton",

  emits: ["click"],
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.minimize-button:hover {
  color: $cyan-main;
}
</style>
