<template>
  <VSelect
    :model-value="pipePartsStore.wallThicknessRemaining[wtLevel].layerNum"
    @update:model-value="(newLayer) => handleLayerChange(newLayer)"
    @click.stop
    :items="items"
    variant="underlined"
    item-title="title"
    item-value="value"
    density="compact"
    hide-details
  />
</template>

<script>
import { usePipePartsStore } from "@/store";

export default {
  name: "LayerSelect",

  props: {
    wtLevel: String,
  },

  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },

  computed: {
    items() {
      const layersCount =
        this.pipePartsStore.wallThicknessRemaining[this.wtLevel].length;

      const items = Array.from({ length: layersCount }, (_, index) => ({
        value: index,
        title: `layer ${index + 1}`,
      }));

      return items;
    },
  },

  methods: {
    handleLayerChange(newLayer) {
      this.pipePartsStore.setWallThicknessForLevel({
        level: this.wtLevel,
        changes: { layerNum: newLayer },
      });
    },
  },
};
</script>
