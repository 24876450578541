import { baseApi } from "@/baseApi";

import CancaledAPI from "@/utils/CanceledApi.js";

export class UserCommentsAPI extends CancaledAPI {
  async getAll(pipelineId, selectedGroup) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("getAll");

    const { data } = await baseApi({
      url: `/inline-inspections/user-comment/${pipelineId}/${selectedGroup}`,
      method: "get",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async create(form, selectedGroup) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("post");

    const { data } = await baseApi({
      url: `/inline-inspections/user-comment/${selectedGroup}`,
      method: "post",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
      data: form,
    });

    return data;
  }

  async remove(commentId, selectedGroup) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("delete");

    const response = await baseApi({
      url: `/inline-inspections/user-comment/${commentId}/${selectedGroup}`,
      method: "delete",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return response;
  }

  async getDocuments(pipelineId, selectedGroup) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("getDocuments");

    const { data } = await baseApi({
      url: `/inline-inspections/documents/${pipelineId}/${selectedGroup}`,
      method: "get",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async uploadDocument(pipelineId, file, onUploadProgress, selectedGroup) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("uploadDocuments");

    const response = await baseApi({
      url: `/inline-inspections/documents/${pipelineId}/${selectedGroup}`,
      method: "post",
      signal,
      onUploadProgress,
      headers: { Authorization: `Bearer ${this.token}` },
      data: file,
    });

    return response;
  }
}

export const userCommentsAPI = new UserCommentsAPI();
