<template>
  <div>
    <RangeSlider
      :title="$t('pipe_parts.filters.ovality_max')"
      :model-value="pipePartsStore.filters.selectedOvalityMax"
      @update:modelValue="
        (value) => pipePartsStore.setFilters({ selectedOvalityMax: value })
      "
      :min="pipePartsStore.minMaxOvalityMax[0]"
      :max="pipePartsStore.minMaxOvalityMax[1]"
      :step="0.01"
    ></RangeSlider>
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { usePipePartsStore } from "@/store";

export default {
  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },
  components: {
    RangeSlider,
  },
};
</script>
