<template>
  <VBtn
    :ripple="false"
    @click="$emit('maximize', tab.key)"
    color="#1c243d"
    size="small"
    class="widget-tab"
  >
    <slot name="prepend" />
    <VIcon icon="mdi-open-in-new" class="widget-tab-icon" />
    {{ tab.title }}
    <slot name="append" />
  </VBtn>
</template>

<script>
export default {
  name: "WidgetTabBase",

  emits: ["maximize"],

  props: {
    tab: {
      type: Object,
      required: true,
    },
  },
};
</script>

// TODO: refactor icon styles
<style scoped lang="scss">
@use "~@/assets/scss/custom-style.scss" as styles;

.widget-tab {
  text-transform: none;
  padding: 0 9px;
  font-size: 0.9rem;
  color: styles.$cyan-main !important;
  box-shadow: 0px 1px 7px 0px rgba(255, 255, 255, 0.26),
    0px 3px 6px 0px rgba(0, 0, 0, 0.215);

  &:hover {
    :deep(.v-icon) {
      color: inherit;
    }
  }
}

.widget-tab-icon {
  color: white;
  transform: scaleX(-1);
  margin-right: 6px;
}
</style>
