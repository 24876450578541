<template>
  <v-select
    :label="$t('pipe_parts.filters.all_types')"
    :model-value="pipePartsStore.filters.selectedPipePartTypes"
    @update:modelValue="
      (value) => pipePartsStore.setFilters({ selectedPipePartTypes: value })
    "
    :items="items"
    multiple
    variant="underlined"
  />
</template>

<script>
import { usePipePartsStore } from "@/store";

export default {
  props: {
    items: Array,
  },
  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },
};
</script>
