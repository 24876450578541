<template>
  <BaseWidget :title="$t('anomalies.details.anomaly_type')" v-bind="$attrs">
    <AnomaliesLegend />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import AnomaliesLegend from "@/pages/anomaly/components/AnomaliesLegend.vue";

export default {
  name: "AnomaliesTableWidget",

  components: {
    BaseWidget,
    AnomaliesLegend,
  },
};
</script>
