<template>
  <div>
    <slot name="title"></slot>
    <RangeSlider
      :title="title"
      :model-value="safetyFactorStore.filters.selectedSafetyRange"
      @update:modelValue="
        (value) => safetyFactorStore.setFilters({ selectedSafetyRange: value })
      "
      :min="safetyFactorStore.minMaxSafetyRange[0]"
      :max="safetyFactorStore.minMaxSafetyRange[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { useSafetyFactorStore } from "@/store";

export default {
  setup() {
    return {
      safetyFactorStore: useSafetyFactorStore(),
    };
  },
  data() {
    return {
      title: undefined,
    };
  },
  mounted() {
    if (!this.$slots.title || !this.$slots.title()?.length) {
      this.title = this.$t("safety_factor.filters.safety_factor");
    }
  },
  components: {
    RangeSlider,
  },
};
</script>
