<template>
  <BaseWidget
    :title="$t('safety_factor.widgets.contributions_chart')"
    v-bind="$attrs"
  >
    <UncertaintyContributionsChart :visibleParts="visibleParts" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { UncertaintyContributionsChart } from "@/pages/safety-factor/components";

export default {
  name: "SafetyFactorContributionsWidget",

  components: {
    BaseWidget,
    UncertaintyContributionsChart,
  },

  props: {
    visibleParts: {
      type: Array,
      required: true,
    },
  },
};
</script>
