<template>
  <BaseWidget v-bind="$attrs">
    <template v-slot:actions>
      <ColorsLegendToolbar
        @click:settings="$emit('click:settings')"
        @click:info="$emit('click:info')"
      />
    </template>

    <PipePartsLegend />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import PipePartsLegend from "../PipePartsLegend";
import ColorsLegendToolbar from "../ColorsLegendToolbar.vue";

export default {
  name: "PipePartsColorsWidget",

  components: {
    BaseWidget,
    PipePartsLegend,
    ColorsLegendToolbar,
  },

  emits: {
    "click:settings": null,
    "click:info": null,
  },
};
</script>
