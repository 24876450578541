<template>
  <div class="container">
    <v-card rounded="lg" class="scanners-table-card">
      <v-card-title class="font-weight-thin">
        {{ `${$t("pipe_scanner.pipescanners")} :` }}
        <ScannersTabs />
      </v-card-title>

      <v-card-text
        v-if="
          pipeScannersStore.isLoading[scannerControlStore.selectedScannerType]
        "
        class="text-center"
      >
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </v-card-text>

      <v-card-text v-else style="padding-top: 6px">
        <ScannerFilters />

        <v-window :model-value="scannerControlStore.selectedScannerType">
          <v-window-item :value="ScannerType.Results">
            <ResultsScannersTable
              @scanner-edit="(id) => openEditModel(id, ScannerType.Results)"
              @scanner-details="(id) => openDetailedModal(id)"
            />
          </v-window-item>
          <v-window-item :value="ScannerType.AnalysisPending">
            <AnalysisScannersTable
              @scanner-edit="
                (id) => openEditModel(id, ScannerType.AnalysisPending)
              "
            />
          </v-window-item>
          <v-window-item :value="ScannerType.InfoPending">
            <InfoScannersTable
              @scanner-edit="(id) => openEditModel(id, ScannerType.InfoPending)"
            />
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <EditModal ref="refEditModal" />
    <DetailedModal ref="refDetailedModal" />
  </div>
</template>

<script>
import {
  ResultsScannersTable,
  AnalysisScannersTable,
  InfoScannersTable,
  ScannerFilters,
  ScannersTabs,
} from "@/pages/scanner-control/components";
import { EditModal, DetailedModal } from "@/features/pipescanner/components";

import { ScannerType } from "@/pages/scanner-control/config";
import { usePipeScannersStore, useScannerControlStore } from "@/store";

export default {
  name: "ScannerControl",

  components: {
    ResultsScannersTable,
    AnalysisScannersTable,
    InfoScannersTable,
    ScannerFilters,
    ScannersTabs,
    EditModal,
    DetailedModal,
  },

  setup() {
    return {
      ScannerType,
      pipeScannersStore: usePipeScannersStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  methods: {
    openEditModel(id, status) {
      this.$refs.refEditModal.showScannerEditor(id, status);
    },

    openDetailedModal(id) {
      this.$refs.refDetailedModal.showScannerDetailed(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.scanners-table-card {
  .v-card-title {
    display: flex;
    align-items: center;
    color: white !important;
  }

  .constuction_year_range {
    text-align: center;
    display: flex;
    align-items: flex-start;
    padding: 0 12px;

    & > div.title {
      height: 40px;
      display: flex;
      align-items: center;
    }

    .v-text-field {
      margin: 0 8px;
    }
  }
}
</style>
