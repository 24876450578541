<template>
  <HeightChart
    :additionalTraces="additionalTraces"
    :layoutChanges="layoutChanges"
    @hover="handleHover"
  />
</template>

<script>
import { HeightChart } from "@/components/charts/shared";

import { materialColors, defectColors } from "@/colorLegends.js";
import { usePipelinesStore } from "@/store";

export default {
  name: "DashboardHeightChart",

  components: {
    HeightChart,
  },

  emits: ["changePoint"],

  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
    };
  },

  computed: {
    distance() {
      return this.pipelinesStore.selectedInspectionStats.height_profile.distance.map(
        (d) => this.$units.convert(d, "m")
      );
    },

    zHeightProfile() {
      return this.pipelinesStore.selectedInspectionStats.height_profile.z.map(
        (zH) => this.$units.convert(zH, "m")
      );
    },

    convertedElevation() {
      if (this.pipelinesStore.pipelineElevation.length === 0) {
        return [];
      }

      return this.pipelinesStore.pipelineElevation.map((e) =>
        this.$units.convert(e, "m")
      );
    },

    convertedDepth() {
      if (this.pipelinesStore.pipelineDepth.length === 0) {
        return [];
      }

      return this.pipelinesStore.pipelineDepth.map((e) =>
        this.$units.convert(e, "m")
      );
    },

    heightRange() {
      const minHeight = Math.min(
        ...this.zHeightProfile,
        ...this.convertedElevation,
        ...this.convertedDepth
      );
      const maxHeight = Math.max(
        ...this.zHeightProfile,
        ...this.convertedElevation,
        ...this.convertedDepth
      );

      return [minHeight, maxHeight];
    },

    zoneDefects() {
      const zoneDefectsList = Object.keys(
        this.pipelinesStore.selectedInspectionStats.defects
      ).filter(
        (defect) =>
          this.pipelinesStore.selectedInspectionStats.defects[defect].zones !==
          undefined
      );

      const traces = [];
      const [minHeight] = this.heightRange;
      const chartHeight = Math.trunc(minHeight) / 2;

      for (const defect of zoneDefectsList) {
        const defectData =
          this.pipelinesStore.selectedInspectionStats.defects[defect];
        let defectIncluded = false;
        const x = [];
        for (const zone of defectData.zones) {
          x.push(...zone, null);
        }

        const defectTrace = {
          x,
          y: Array(x.length).fill(chartHeight),
          type: "scattergl",
          mode: "lines",
          line: {
            color: defectColors[defect],
            width: 200,
          },
          name: this.$t(`anomalies.defects.${defect}`),
          legendgroup: defect,
          opacity: 1.0,
          fillopacity: 1.0,
          showlegend: !defectIncluded,
        };

        traces.push(defectTrace);
      }

      return traces;
    },

    pointDefects() {
      const pointDefects = Object.keys(
        this.pipelinesStore.selectedInspectionStats.defects
      ).filter(
        (defect) =>
          this.pipelinesStore.selectedInspectionStats.defects[defect].points !==
          undefined
      );

      const traces = [];
      const [minHeight] = this.heightRange;
      const chartHeight = Math.trunc(minHeight - 2.0);

      for (const defect of pointDefects) {
        const defectData =
          this.pipelinesStore.selectedInspectionStats.defects[defect];
        const defectXCoords = defectData.points;

        const defectTrace = {
          x: defectXCoords,
          y: new Array(defectXCoords.length).fill(chartHeight),
          type: "scattergl",
          mode: "lines",
          line: {
            color: defectColors[defect],
            width: 20,
          },
          name: this.$t(`anomalies.defects.${defect}`),
          legendgroup: defect,
          opacity: 1.0,
          fillopacity: 1.0,
          showlegend: true,
        };

        traces.push(defectTrace);
      }

      return traces;
    },

    materialZones() {
      const traces = [];
      const presentMaterials = new Set();
      const [minHeight] = this.heightRange;
      const y1 = Math.trunc(minHeight - 5.0);
      const y2 = Math.trunc(minHeight - 4.0);

      for (const zone of this.pipelinesStore.selectedInspectionStats
        .materials) {
        const leftZoneBoundary = {
          x: [
            this.$units.convert(zone.distance[0], "m"),
            this.$units.convert(zone.distance[0], "m"),
          ],
          y: [y1, y2],
          fill: "tozeroy",
          type: "scatter",
          mode: "none",
          legendgroup: zone.material,
          fillcolor: materialColors[zone.material].color,
          line: {
            color: materialColors[zone.material].color,
          },
          name: this.$t(`materials.${zone.material}`),
          opacity: 1.0,
          fillopacity: 1.0,
          showlegend: !presentMaterials.has(zone.material),
        };

        const rightZoneBoundary = {
          x: [
            this.$units.convert(zone.distance[1], "m"),
            this.$units.convert(zone.distance[1], "m"),
          ],
          y: [y1, y2],
          fill: "tonexty",
          type: "scatter",
          name: this.$t(`materials.${zone.material}`),
          legendgroup: zone.material,
          fillcolor: materialColors[zone.material].color,
          line: {
            color: materialColors[zone.material].color,
          },
          opacity: 1.0,
          fillopacity: 1.0,
          mode: "none",
          showlegend: false,
        };

        presentMaterials.add(zone.material);
        traces.push(leftZoneBoundary, rightZoneBoundary);
      }

      return traces;
    },

    additionalTraces() {
      let additionalTraces = [];

      additionalTraces = additionalTraces.concat(
        this.materialZones,
        this.zoneDefects,
        this.pointDefects
      );

      return additionalTraces;
    },

    layoutChanges() {
      const layoutChanges = {
        xaxis: {
          title: `${this.$t(
            "dashboard.pipelines.height_profile.xaxis_name"
          )} (${this.$units.getAbbr("m")})`,
        },

        yaxis: {
          title: `${this.$t(
            "dashboard.pipelines.height_profile.yaxis_name"
          )} (${this.$units.getAbbr("m")})`,
        },
      };

      return layoutChanges;
    },
  },

  methods: {
    handleHover(event) {
      if (typeof event.points[0].x == "number") {
        const markerDistance = event.points[0].x;
        const traceDict = this.pipelinesStore.selectedInspectionStats.map_trace;
        const distanceKeys = Object.keys(traceDict).map((x) => parseFloat(x));
        const convertDistance = distanceKeys.map((d) =>
          this.$units.convert(d, "m")
        );
        var closest = String(
          convertDistance.reduce((prev, curr) => {
            return Math.abs(curr - markerDistance) <
              Math.abs(prev - markerDistance)
              ? curr
              : prev;
          })
        );
        const convertTraceDict = {};
        for (const key in traceDict) {
          const convertKey = this.$units.convert(key, "m");
          convertTraceDict[convertKey] = traceDict[key];
        }
        if (Object.keys(convertTraceDict).includes(closest)) {
          this.$emit("changePoint", convertTraceDict[closest].coordinates);
        }
      }
    },
  },
};
</script>
