<template>
  <div class="wms-widget-container">
    <div v-if="colorMode" class="flex wms-select">
      <div>{{ $t("dashboard.pipelines.pipe") }}</div>
      <v-select
        :model-value="colorMode"
        @update:modelValue="(value) => $emit('update:colorMode', value)"
        :items="colorOptions"
        variant="underlined"
        density="comfortable"
        hide-details
      >
        <template v-slot:item="{ item, props }">
          <v-list-subheader
            v-if="props.header"
            :style="{
              paddingInlineStart: '10px !important',
            }"
          >
            {{ props.header }}
          </v-list-subheader>
          <v-divider v-else-if="props.divider" />
          <v-list-item
            v-else
            v-bind="props"
            :disabled="item.raw.disabled"
          ></v-list-item>
        </template>
      </v-select>
    </div>

    <div class="flex wms-select">
      <div>{{ $t("wms_layers.layers") }}</div>
      <v-select
        :model-value="wmsLayers"
        @update:modelValue="(value) => $emit('update:wmsLayers', value)"
        :items="wmsTitledOptions"
        :placeholder="this.$t('wms_layers.placeholder')"
        variant="underlined"
        density="comfortable"
        multiple
        hide-details
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">{{ item.title.slice(0, 22) }}</span>
          <v-chip v-else-if="index === 1" size="x-small">
            +{{ wmsLayers.length - 1 }}
          </v-chip>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { wmsOptions } from "./WMSWidget.config.js";

export default {
  name: "WMSSelect",

  props: ["wmsLayers", "colorMode", "colorOptions"],

  emits: ["update:wmsLayers", "update:colorMode"],

  computed: {
    wmsTitledOptions() {
      return wmsOptions.map(({ value }) => ({
        title: this.$t(`wms_layers.options.${value}`),
        value,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.wms-widget-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px;
}

.wms-select {
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  :deep(.v-field__input) {
    min-height: unset !important;
    padding-top: unset !important;
  }

  :deep(.v-field__append-inner) {
    padding-top: unset !important;
  }

  & > div:first-child {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
