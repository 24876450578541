<template>
  <div class="slider">
    <RangeSlider
      :title="$t('joints.filters.horizontal_angle')"
      :model-value="jointsStore.jointFilters.selectedHorizontalAngle"
      @update:modelValue="
        (value) => jointsStore.setFilters({ selectedHorizontalAngle: value })
      "
      :max="jointsStore.maxHorizontalAngle"
      :min="0"
      :step="0.01"
      lazy
    />
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { useJointsStore } from "@/store";

export default {
  setup() {
    return {
      jointsStore: useJointsStore(),
    };
  },

  components: {
    RangeSlider,
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 25%;
}
</style>
