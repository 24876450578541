<template>
  <BaseWidget :title="$t('safety_factor.widgets.table')" v-bind="$attrs">
    <template v-slot:actions>
      <ScannersTabs />
      <FilterPopupMenu
        v-model:show="filtersVisible"
        :hasActiveFilters="scannerControlStore.hasFilteredScanners"
      />
    </template>

    <template v-slot:default>
      <div
        v-if="
          pipeScannersStore.isLoading[scannerControlStore.selectedScannerType]
        "
        class="h-100 d-flex align-center justify-center"
      >
        <VProgressCircular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        />
      </div>
      <template v-else>
        <ResultsScannersTable
          v-if="scannerControlStore.selectedScannerType === ScannerType.Results"
          @scannerEdit="
            (id) => $emit('scannerEdit', { id, type: ScannerType.Results })
          "
          @scannerDetails="(id) => $emit('scannerDetails', id)"
          @scannerSelect="(scanner) => $emit('scannerSelect', scanner)"
        />
        <AnalysisScannersTable
          v-if="
            scannerControlStore.selectedScannerType ===
            ScannerType.AnalysisPending
          "
          @scannerSelect="(scanner) => $emit('scannerSelect', scanner)"
          @scannerEdit="
            (id) =>
              $emit('scannerEdit', { id, type: ScannerType.AnalysisPending })
          "
        />
        <InfoScannersTable
          v-if="
            scannerControlStore.selectedScannerType === ScannerType.InfoPending
          "
          @scannerSelect="(scanner) => $emit('scannerSelect', scanner)"
          @scannerEdit="
            (id) => $emit('scannerEdit', { id, type: ScannerType.InfoPending })
          "
        />
      </template>
    </template>
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import {
  ResultsScannersTable,
  AnalysisScannersTable,
  InfoScannersTable,
  ScannersTabs,
} from "@/pages/scanner-control/components";
import FilterPopupMenu from "../FilterPopupMenu";

import { ScannerType } from "@/pages/scanner-control/config";
import { usePipeScannersStore, useScannerControlStore } from "@/store";

export default {
  name: "SafetyFactorTableWidget",

  components: {
    BaseWidget,
    FilterPopupMenu,
    ResultsScannersTable,
    AnalysisScannersTable,
    InfoScannersTable,
    ScannersTabs,
  },

  setup() {
    return {
      ScannerType,
      pipeScannersStore: usePipeScannersStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  data() {
    return {
      filtersVisible: false,
    };
  },

  // TODO: add event payload validators
  emits: ["scannerSelect", "scannerEdit", "scannerDetails"],
};
</script>
