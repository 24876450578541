<template>
  <BaseWidget v-bind="$attrs">
    <SafetyChart :visibleParts="visibleParts" />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { SafetyChart } from "@/pages/safety-factor/components";

export default {
  name: "SafetyFactorChartWidget",

  components: {
    BaseWidget,
    SafetyChart,
  },

  props: {
    visibleParts: {
      type: Array,
      required: true,
    },
  },
};
</script>
