<template>
  <VBtn
    variant="text"
    density="compact"
    icon="mdi-cog"
    @click.stop="$emit('click:settings')"
    @mousedown.stop
  />
  <VBtn
    variant="text"
    density="compact"
    icon="mdi-information-variant-circle-outline"
    @click="$emit('click:info')"
    @mousedown.stop
  />
</template>

<script>
export default {
  name: "ColorsLegendToolbar",

  emits: {
    "click:info": null,
    "click:settings": null,
  },
};
</script>
