<template>
  <BaseWidget :title="$t('safety_factor.widgets.table')" v-bind="$attrs">
    <template v-slot:actions>
      <FilterPopupMenu
        v-model:show="filtersVisible"
        :uncertaintiesOptions="uncertaintiesOptions"
        :hasActiveFilters="safetyFactorStore.hasActiveFilters"
      />
    </template>

    <SafetyTable />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { SafetyTable } from "@/pages/safety-factor/components";
import FilterPopupMenu from "../FilterPopupMenu";
import { useSafetyFactorStore } from "@/store";

export default {
  name: "SafetyFactorTableWidget",

  components: {
    BaseWidget,
    SafetyTable,
    FilterPopupMenu,
  },

  setup() {
    return {
      safetyFactorStore: useSafetyFactorStore(),
    };
  },

  data() {
    return {
      filtersVisible: false,
    };
  },

  props: {
    uncertaintiesOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>
