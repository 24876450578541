<template>
  <HeightChart
    :additionalTraces="additionalTraces"
    :layoutChanges="layoutChanges"
    :sharedTraces="[
      SharedHeightChartTrace.Elevation,
      SharedHeightChartTrace.HeightProfile,
    ]"
  />
</template>

<script>
import { HeightChart } from "@/components/charts/shared";

import { SharedHeightChartTrace } from "@/components/charts/shared/HeightChart/config";

import { usePipelinesStore, usePipePartsStore } from "@/store";

export default {
  name: "SafetyHeightChart",

  props: ["visibleParts"],

  components: {
    HeightChart,
  },

  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
      pipePartsStore: usePipePartsStore(),
      SharedHeightChartTrace,
    };
  },

  computed: {
    waterLevelTraces() {
      const traces = [];
      const pipelineElevation = this.pipelinesStore.convertedPipelineElevation;
      const distance = this.pipelinesStore.convertedHeightProfileDistance;

      if (!this.pipePartsStore.pipeParts) {
        return null;
      }

      const groundWaterLower = new Array(this.pipePartsStore.pipeParts.length);
      const groundWaterUpper = new Array(this.pipePartsStore.pipeParts.length);

      for (let i = 0; i < this.pipePartsStore.pipeParts.length; i++) {
        const elevation = pipelineElevation[i];
        const part = this.pipePartsStore.pipeParts[i];

        if (!part.safety_factor_calculation) {
          continue;
        }

        const lowerLevel =
          elevation -
          this.$units.convert(
            part.safety_factor_calculation.ground_water_level_min,
            "m"
          );
        const upperLevel =
          elevation -
          this.$units.convert(
            part.safety_factor_calculation.ground_water_level_max,
            "m"
          );

        groundWaterLower[i] = lowerLevel;
        groundWaterUpper[i] = upperLevel;
      }

      function getWaterLevelTrace(yValues, name, color) {
        return {
          x: distance,
          y: yValues,
          type: "scatter",
          name,
          line: {
            color,
          },
        };
      }

      traces.push(
        getWaterLevelTrace(
          groundWaterLower,
          this.$t("safety_factor.height_profile.water_lower"),
          "DarkTurquoise"
        )
      );

      traces.push(
        getWaterLevelTrace(
          groundWaterUpper,
          this.$t("safety_factor.height_profile.water_upper"),
          "CornflowerBlue"
        )
      );

      return traces;
    },

    additionalTraces() {
      if (!this.waterLevelTraces) {
        return [];
      }

      return [this.waterLevelTraces];
    },

    layoutChanges() {
      const layoutChanges = {
        xaxis: {
          title: `${this.$t(
            "pipe_parts.details.height_profile.xaxis_name"
          )} (${this.$units.getAbbr("m")})`,
        },

        yaxis: {
          title: `${this.$t(
            "pipe_parts.details.height_profile.yaxis_name"
          )} (${this.$units.getAbbr("m")})`,
        },
      };

      if (this.visibleParts && this.visibleParts.length) {
        const visibleDistance = this.visibleParts.map((pp) => pp.distance);
        const dMax = Math.max(...visibleDistance);
        const dMin = Math.min(...visibleDistance);
        layoutChanges.xaxis["range"] = [dMin, dMax];
      }

      return layoutChanges;
    },
  },
};
</script>
