<template>
  <WidgetCard v-bind="$attrs" ref="widgetCard">
    <template #title-text>
      <slot name="title-text" />
    </template>
    <template #title-prepend>
      <slot name="title-prepend" />
    </template>
    <slot></slot>
  </WidgetCard>
</template>

<script>
import { computed } from "vue";

import { WidgetCard } from "./widgets/WidgetCard";

import { debounce } from "@/utils/debounce";

export default {
  name: "TableWidgetCard",

  components: { WidgetCard },

  data() {
    return {
      resizeObserver: null,
      widgetBodyHeight: 0,
    };
  },

  methods: {
    handleResize(entries) {
      const entry = entries.at(0);
      const newHeight = entry.contentRect.height;

      if (newHeight === 0) {
        return;
      }

      this.widgetBodyHeight = newHeight;
    },
  },

  provide() {
    return {
      widgetBodyHeight: computed(() => this.widgetBodyHeight),
    };
  },

  mounted() {
    const debouncedHandleResize = debounce(this.handleResize, 200);
    const resizeObserver = new ResizeObserver(debouncedHandleResize);
    this.resizeObserver = resizeObserver;

    const widgetCard = this.$refs.widgetCard.$el;
    const widgetBody = widgetCard.querySelector(".v-card-text");
    resizeObserver.observe(widgetBody);

    this.widgetBodyHeight = widgetBody.innerHeight;
  },

  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
};
</script>
