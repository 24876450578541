<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @click.self="onClickGraph"
    @mousedown.stop="$emit('graph-mousedown')"
    @relayout="onHistogramZoom"
  />
</template>

<script>
import { PlotlyGraph } from "@/components";

import { findMainUncertainty } from "../helpers";
import { getUncertaintiesWithColors, soilTypeColors } from "@/colorLegends.js";

import { VIEW_FACTORS_WITHOUT_GRADIENT } from "@/pages/safety-factor/config";
import { usePipelinesStore, useSafetyFactorStore } from "@/store";

export default {
  name: "SafetyChart",

  emits: ["graph-mousedown"],

  props: ["visibleParts"],

  components: {
    PlotlyGraph,
  },

  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
      safetyFactorStore: useSafetyFactorStore(),
    };
  },

  computed: {
    plotTitle() {
      const inspectionName =
        this.pipelinesStore.selectedPipeline.inspections.find(
          (ins) => ins.id === this.pipelinesStore.selectedInspectionId
        )?.project_reference;
      return `${this.pipelinesStore.selectedPipeline.client_pipeline_id} : ${this.pipelinesStore.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },

    upperBounds() {
      return this.safetyFactorStore.filteredPipeParts.map(
        ({ safety_factor_calculation }) => {
          if (safety_factor_calculation) {
            return (
              safety_factor_calculation.safety_factor_upper -
              safety_factor_calculation.safety_factor
            );
          }

          return 0;
        }
      );
    },

    lowerBounds() {
      return this.safetyFactorStore.filteredPipeParts.map(
        ({ safety_factor_calculation }) => {
          if (safety_factor_calculation) {
            return (
              safety_factor_calculation.safety_factor -
              safety_factor_calculation.safety_factor_lower
            );
          }

          return 0;
        }
      );
    },

    plotlyData() {
      if (this.safetyFactorStore.selectedViewFactor.includes("safety_factor")) {
        return [
          {
            y: this.safetyFactorStore.filteredPipeParts.map(
              (part) => part.safety_factor_calculation?.safety_factor
            ),
            marker: {
              color: "rgba(58,200,225,.5)",
              line: {
                color: "rgb(8,48,107)",
                width: 0.5,
              },
            },
            error_y: {
              type: "data",
              symmetric: false,
              array: this.upperBounds,
              arrayminus: this.lowerBounds,
            },
            type: "bar",
          },
        ];
      }

      if (
        ["main_contributor", "soil_type"].includes(
          this.safetyFactorStore.selectedViewFactor
        )
      ) {
        const xdata = [];
        const colors = new Set();
        if (this.safetyFactorStore.selectedViewFactor === "main_contributor") {
          const uncertaintiesWithColors = getUncertaintiesWithColors(this.$t);
          this.safetyFactorStore.filteredPipeParts.forEach((part) => {
            const mainUncertainty = findMainUncertainty(part);
            const { text, color } = uncertaintiesWithColors[mainUncertainty];
            xdata.push(text);
            colors.add(color);
          });
        }
        if (this.safetyFactorStore.selectedViewFactor === "soil_type") {
          this.safetyFactorStore.filteredPipeParts.forEach((part) => {
            if (part.safety_factor_calculation) {
              const { text, color } =
                soilTypeColors[part.safety_factor_calculation.soil_type];
              xdata.push(text);
              colors.add(color);
            }
          });
        }
        return [
          {
            x: xdata,
            showlegend: false,
            histnorm: "count",
            type: "histogram",
            autobinx: true,
            marker: {
              color: Array.from(colors),
            },
          },
        ];
      }

      const xdata = [];
      this.safetyFactorStore.filteredPipeParts.forEach((part) => {
        if (part.safety_factor_calculation) {
          xdata.push(
            part.safety_factor_calculation[
              this.safetyFactorStore.selectedViewFactor
            ]
          );
        }
      });

      return [
        {
          x: xdata,
          showlegend: false,
          histnorm: "count",
          type: "histogram",
          autobinx: true,
        },
      ];
    },

    plotlyLayout() {
      if (this.safetyFactorStore.selectedViewFactor.includes("safety_factor")) {
        const layout = {
          bargap: 0.05,
          bargroupgap: 0.2,
          barmode: "overlay",
          xaxis: {
            title: this.plotTitle,
          },
          yaxis: {
            title: this.$t("safety_factor.title"),
            gridwidth: 1,
          },

          shapes: [
            {
              type: "rect",
              x0: -0.5,
              y0: 0,
              x1: this.safetyFactorStore.filteredPipeParts.length,
              y1: 1,
              line: {
                width: 0,
              },
              fillcolor: "rgba(252, 0, 40, 0.5)",
            },
          ],
          margin: {
            t: 30,
            b: 70,
            l: 60,
            r: 30,
          },
        };

        if (this.visibleParts?.length) {
          const first = this.visibleParts[0].order_index;
          const last = this.visibleParts.at(-1).order_index;
          layout.xaxis["range"] = [first - 0.5, last + 0.5];

          const yMax = Math.max(
            ...this.safetyFactorStore.filteredPipeParts
              .slice(first, last)
              .map(
                (part) => part.safety_factor_calculation?.safety_factor_upper
              )
          );
          layout.yaxis["range"] = [0, yMax];
        }

        return layout;
      }

      return {
        bargap: 0.05,
        bargroupgap: 0.2,
        barmode: "overlay",
        yaxis: {
          title: this.$t("joints.details.plotly.yaxis_name"),
          type: "log",
          tick0: 100,
          dtick: "L200",
        },
        margin: {
          t: 30,
          b: 40,
          l: 70,
          r: 50,
        },
      };
    },
  },

  methods: {
    onClickGraph(e) {
      this.handleSelectChartColumn(e.points[0].x);
    },

    handleSelectChartColumn(legend) {
      if (
        this.safetyFactorStore.selectedViewFactor === "main_contributor" ||
        this.safetyFactorStore.selectedViewFactor === "soil_type"
      ) {
        this.togglesafetyFactorStore.selectedLegend(legend);
      }

      if (this.safetyFactorStore.selectedViewFactor.includes("safety_factor")) {
        this.safetyFactorStore.setSelectedPipePartId(
          this.safetyFactorStore.filteredPipeParts[legend].id
        );
      }
    },

    onHistogramZoom(e) {
      if (
        !VIEW_FACTORS_WITHOUT_GRADIENT.includes(
          this.safetyFactorStore.selectedViewFactor
        )
      ) {
        if (e["xaxis.autorange"]) {
          this.safetyFactorStore.resetPlotlyFilter();
        } else {
          if (e.hasOwnProperty("xaxis.range[0]")) {
            const min = e["xaxis.range[0]"];
            const max = e["xaxis.range[1]"];
            const value = {
              property: this.safetyFactorStore.selectedViewFactor,
              range: [min, max],
            };

            this.safetyFactorStore.setFilters({ plotlyFilter: value });
          }
        }
      }
    },
  },
};
</script>
