export function throttle(f, options) {
  const { delay } = options;

  let isThrottled = false;
  let savedThis;
  let savedArgs;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    isThrottled = true;

    f.apply(this, arguments);

    setTimeout(function () {
      isThrottled = false;

      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, delay);
  }

  return wrapper;
}
