<template>
  <TableContainer
    v-if="pipePartsStore.filteredPipeParts.length > 0"
    :resizable="mapStore.isMapMode"
  >
    <PipePartsTableMapmode
      v-if="mapStore.isMapMode"
      :selectedWTLevel="selectedWTLevel"
      :getLayerName="getLayerName"
      :checkedLength="checkedLength"
      :isPipePartActive="isPipePartActive"
      :wallThicknessRemaining="pipePartsStore.wallThicknessRemaining"
      @click:row="(pipePart) => onRowClick(pipePart.id)"
    />
    <PipePartsTableRegular
      v-else
      :getWTLevel="getWTLevel"
      :getLayerName="getLayerName"
      :checkedLength="checkedLength"
      :isPipePartActive="isPipePartActive"
      :wallThicknessRemaining="pipePartsStore.wallThicknessRemaining"
      @click:row="(pipePart) => onRowClick(pipePart.id)"
    />
    <DigUpTableBtn
      v-if="checkedLength > 0"
      :badgeCount="checkedLength"
      :isMapMode="mapStore.isMapMode"
    />
  </TableContainer>
</template>

<script>
import PipePartsTableMapmode from "./PipePartsTableMapmode.vue";
import PipePartsTableRegular from "./PipePartsTableRegular.vue";
import { DigUpTableBtn } from "@/features/dig-up/components";
import { TableContainer } from "@/components/table-components/containers";
import { useMapStore, usePipePartsStore } from "@/store";

export default {
  name: "PipePartsTable",

  components: {
    DigUpTableBtn,
    PipePartsTableMapmode,
    PipePartsTableRegular,
    TableContainer,
  },

  setup() {
    return {
      mapStore: useMapStore(),
      pipePartsStore: usePipePartsStore(),
    };
  },

  computed: {
    checkedLength() {
      return this.pipePartsStore.partsSelector.size;
    },

    selectedWTLevel() {
      return this.getWTLevel(this.pipePartsStore.selectedGradientMode);
    },
  },

  methods: {
    onRowClick(id) {
      this.pipePartsStore.setSelectedPipePartId(id);
    },

    isPipePartActive(pipePart) {
      return pipePart.id === this.selectedPP?.id;
    },

    getLayerName(pipePart, type) {
      const layersList = [...pipePart.material_layers, "total"];
      return `${
        layersList[this.pipePartsStore.wallThicknessRemaining[type].layerNum]
      }: `;
    },

    getWTLevel(gradientMode) {
      if (!gradientMode.startsWith("remaining")) {
        return;
      }

      const wtLevel = gradientMode.split("_").at(1);

      if (wtLevel === "life") {
        return;
      }

      return wtLevel;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-table {
  tr {
    td:first-child {
      padding-left: 16px !important;
      padding-right: 0 !important;
    }
    th:first-child {
      max-width: 29px;
      padding-left: 14px !important;
      padding-right: 0 !important;
    }
  }
}

.layers-select {
  width: 60%;
  padding: 0px 2px 5px 2px;
  margin: 0 auto;
}

.layers-label {
  color: grey;
  font-size: 0.8625rem;
}
</style>
