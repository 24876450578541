<template>
  <Dashboard v-if="!canShowAnomaliesPage" />
  <AnomaliesMapmode v-else-if="mapStore.isMapMode" />
  <Anomalies v-else />
</template>

<script>
import { computed } from "vue";
import { Anomalies, AnomaliesMapmode } from "./views";
import Dashboard from "@/pages/dashboard/DashboardPage.vue";
import { useAnomaliesStore, useMapStore, usePipelinesStore } from "@/store";

export default {
  name: "AnomaliesPage",
  components: {
    Anomalies,
    AnomaliesMapmode,
    Dashboard,
  },
  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
      mapStore: useMapStore(),
      anomaliesStore: useAnomaliesStore(),
    };
  },
  provide() {
    return {
      onGenerateDigUp: this.anomaliesStore.getDigupSheet, // ContextMenu + DigUpTableBtn
      cancelDigUp: () => {
        this.anomaliesStore.checkedAnomaliesSelector.clear();
      }, // ContextMenu
      isAwaitingDigup: computed(() => this.anomaliesStore.isAwaitingDigup), // DigUpTableBtn + DigUpLoader
    };
  },

  computed: {
    canShowAnomaliesPage() {
      return (
        this.pipelinesStore.selectedInspectionId &&
        this.pipelinesStore.selectedInspectionStats
      );
    },
  },

  watch: {
    "pipelinesStore.selectedInspectionId": {
      handler(id) {
        if (id) this.anomaliesStore.getAnomalies();
      },
      immediate: true,
    },
  },

  beforeUnmount() {
    this.anomaliesStore.cancellAllRequests();
  },
};
</script>
