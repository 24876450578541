<template>
  <BaseWidget
    :title="$t('dashboard.widgets.dashboardStats.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <StatisticsWidgetToolbar
        @click:closePipeline="$emit('click:closePipeline')"
        @click:moveToPipeline="$emit('click:moveToPipeline')"
        @click:settings="$emit('click:settings')"
      />
    </template>

    <PipeStatistics />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { PipeStatistics } from "@/pages/dashboard/components";
import StatisticsWidgetToolbar from "../StatisticsWidgetToolbar.vue";

export default {
  name: "HeightProfileWidget",

  components: {
    BaseWidget,
    PipeStatistics,
    StatisticsWidgetToolbar,
  },

  emits: {
    "click:moveToPipeline": null,
    "click:closePipeline": null,
    "click:settings": null,
  },
};
</script>
