<template>
  <v-select
    :label="$t('joints.filters.materials')"
    :model-value="jointsStore.jointFilters.selectedMaterials"
    @update:modelValue="
      (value) => jointsStore.setFilters({ selectedMaterials: value })
    "
    :items="materialOptions"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { useJointsStore } from "@/store";

export default {
  setup() {
    return {
      jointsStore: useJointsStore(),
    };
  },

  computed: {
    materialOptions() {
      return this.jointsStore.materials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },
};
</script>
