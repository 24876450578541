<template>
  <v-list-group>
    <template v-slot:activator="{ props }">
      <v-list-item
        :active="false"
        v-bind="props"
        prepend-icon="mdi-view-list"
        :title="`${$t('side_bar.groups')}:`"
      ></v-list-item>
    </template>
    <template v-if="showNested">
      <v-list-item
        v-for="group in authStore.serviceGroups"
        :key="group"
        density="compact"
        :class="['nested', { selected: group === authStore.selectedGroup }]"
        :title="group"
        @click="onSelectGroup(group)"
        ><template v-slot:prepend>{{
          group.slice(0, 3).toUpperCase()
        }}</template></v-list-item
      >
    </template>
  </v-list-group>
</template>

<script>
import { useAuthStore } from "@/store";

export default {
  name: "NavBarGroupItem",
  props: ["showNested"],

  setup() {
    return {
      authStore: useAuthStore(),
    };
  },

  methods: {
    onSelectGroup(groupName) {
      this.$emit("onSelect", groupName);
    },
  },
};
</script>

<style lang="scss">
.nested.v-list-item {
  margin: 0 !important;
  .v-list-item__prepend {
    padding-right: 10px;
  }
}
</style>

<style lang="scss" scoped>
.nested.v-list-item {
  margin-left: 14px !important;
  padding-inline-start: 0 !important;
  -webkit-padding-start: 0 !important;
  padding: 4px 12px !important;
  &:not(:last-child) {
    margin-bottom: 4px !important;
  }
}
.selected {
  background: #3b4151;
}
</style>
