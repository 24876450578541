<template>
  <div class="container">
    <PipelinesTable
      :pipelines="pipelinesStore.groupPipelines"
      :onSelect="onSelectPipelineId"
      :selectedPipelineId="pipelinesStore.selectedPipelineId"
      :isLoading="pipelinesStore.isLoadingPipelines"
    />
    <PipelineOverview
      v-if="
        pipelinesStore.selectedPipelineId && !pipelinesStore.isLoadingPipelines
      "
      :key="pipelinesStore.selectedPipelineId"
      :isLoading="pipelinesStore.isLoadingStats"
    />
  </div>
</template>

<script>
import { usePipelinesStore } from "@/store";
import { PipelineOverview, PipelinesTable } from "./components";

export default {
  name: "PipelinesDashboard",
  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
    };
  },
  components: {
    PipelinesTable,
    PipelineOverview,
  },
  props: {
    onSelectPipelineId: Function,
  },
  computed: {},
};
</script>
