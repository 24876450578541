<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="pipePartsStore.hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("pipe_parts.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <PipePartsTypeSelector :items="typeOptions" hide-details />
        <PipePartsMaterialsSelector :items="materialOptions" hide-details />
        <PipePartsDistanceSlider class="slider" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import {
  PipePartsDistanceSlider,
  PipePartsMaterialsSelector,
  PipePartsTypeSelector,
} from "@/pages/pipe-parts/views/PipeParts/components";
import { usePipePartsStore } from "@/store";

export default {
  name: "FilterPopupMenu",

  emits: ["update:show"],

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    typeOptions: {
      type: Array,
      required: true,
    },

    materialOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      pipePartsStore: usePipePartsStore(),
    };
  },

  components: {
    PipePartsDistanceSlider,
    PipePartsMaterialsSelector,
    PipePartsTypeSelector,
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 258px;
  padding: 8px 0px 10px;
}
</style>
