<template>
  <HeightChart
    :additionalTraces="anomaliesTraces"
    :layoutChanges="layoutChanges"
  />
</template>

<script setup>
import { HeightChart } from "@/components/charts/shared";

import { anomalyTypeColors } from "@/colorLegends.js";
import { useAnomaliesStore, usePipelinesStore } from "@/store";
import { computed, inject, ref } from "vue";
import { watchDebounced } from "@vueuse/core";
import { useI18n } from "vue-i18n";

const props = defineProps(["visibleDistance"]);
defineEmits(["changePoint", "graph-mousedown"]);

const pipelinesStore = usePipelinesStore();
const anomaliesStore = useAnomaliesStore();

const { t } = useI18n();

const units = inject("units");

const pipeDistance = computed(() => {
  return pipelinesStore.selectedInspectionStats.height_profile.distance.map(
    (value) => units.convert(value, "m")
  );
});

const yRange = computed(() => {
  const zAnomalies = anomaliesStore.filteredAnomalies.map(
    (an) => an.z_coordinate
  );
  const zHeightProfile =
    pipelinesStore.selectedInspectionStats.height_profile.z.map((value) =>
      units.convert(value, "m")
    );
  const hMax = Math.max(...zHeightProfile, ...zAnomalies);
  const hMin = Math.min(...zHeightProfile, ...zAnomalies);

  return [hMin - 5.0, hMax + 2.0];
});

const layoutChanges = ref({});

watchDebounced(
  () => props.visibleDistance,
  () => {
    const newLayoutChanges = {
      xaxis: {
        title: `${t(
          "anomalies.details.height_profile.xaxis_name"
        )} (${units.getAbbr("m")})`,
      },

      yaxis: {
        title: `${t(
          "anomalies.details.height_profile.yaxis_name"
        )} (${units.getAbbr("m")})`,
      },
    };

    if (props.visibleDistance?.length > 0) {
      const dMax = Math.max(...props.visibleDistance);
      const dMin = Math.min(...props.visibleDistance);
      newLayoutChanges.xaxis.range = [dMin, dMax];
    } else if (pipeDistance.value.length > 0) {
      const dMax = Math.max(...pipeDistance.value);
      const dMin = Math.min(...pipeDistance.value);
      newLayoutChanges.xaxis.range = [dMin, dMax];
    }

    layoutChanges.value = newLayoutChanges;
  },
  { debounce: 1000, immediate: true }
);

const anomaliesTraces = computed(() => {
  const data = [];
  const anomalyXPositionsByTypes = {};

  if (anomaliesStore.filteredAnomalies.length > 0) {
    anomaliesStore.filteredAnomalies.forEach(({ anomaly_type, distance }) => {
      if (!anomalyXPositionsByTypes[anomaly_type]) {
        anomalyXPositionsByTypes[anomaly_type] = [];
      }
      anomalyXPositionsByTypes[anomaly_type].push(distance);
    });
  }

  const middle = (yRange.value[1] + yRange.value[0]) / 2;

  const errorValue =
    Math.abs(yRange.value[0]) > Math.abs(yRange.value[1])
      ? yRange.value[0]
      : yRange.value[1];

  for (const type in anomalyXPositionsByTypes) {
    const x = anomalyXPositionsByTypes[type];
    const newData = {
      x,
      y: Array(x.length).fill(middle),
      mode: "markers",
      name: t(`anomalies.types.${type}`),
      marker: {
        color: anomalyTypeColors[type],
      },
      error_y: {
        type: "constant",
        value: errorValue,
        width: 0,
        thickness: 1,
      },
    };

    data.push(newData);
  }

  return data;
});
</script>
