<template>
  <div class="wrapper">
    <table v-if="degradationsWithPlastic">
      <tr>
        <th></th>
        <th>{{ $t("color_legends.other_materials") }}</th>
        <th>{{ $t("color_legends.plastic_materials") }}</th>
      </tr>
      <tr
        v-for="key in legendList"
        :class="['row', ...computeClass(key)]"
        :key="key.text"
        @click="onItemClick(key)"
      >
        <td>
          <div
            class="dot"
            :style="{
              backgroundColor: key.color,
            }"
          ></div>
        </td>
        <td>{{ key.text }}</td>
        <td>{{ key.textForPlastic }}</td>
      </tr>
    </table>
    <table v-else>
      <tr
        v-for="key in legendList"
        :class="['row', ...computeClass(key)]"
        :key="key.text"
        @click="onItemClick(key)"
      >
        <td>
          <div
            class="dot"
            :style="{
              backgroundColor: getValues(key, 'color'),
            }"
          ></div>
        </td>
        <td style="text-transform: capitalize">
          {{ getValues(key, "text") }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  materialColors,
  roadTypeColors,
  soilTypeColors,
  jointTypeColors,
  getDegradationColorPP,
  qualityInfluenceColors,
  anomalyTypeColors,
  getDegradationColorJoints,
} from "@/colorLegends.js";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsLegend",

  setup() {
    return {
      anomalyTypeColors,
      getDegradationColorPP,
      pipePartsStore: usePipePartsStore(),
    };
  },

  computed: {
    hasPlasticInMaterials() {
      const plasticMaterials = ["PVC", "HDPE", "HPPE", "PE"];
      return this.pipePartsStore.materials.some((material) =>
        plasticMaterials.includes(material)
      );
    },

    degradationsWithPlastic() {
      return (
        this.pipePartsStore.selectedGradientMode === "degradation_level" &&
        this.hasPlasticInMaterials
      );
    },

    qualityInfluences() {
      const uniqueInfluences = new Set();

      this.pipePartsStore.filteredPipeParts.forEach(({ quality_anomaly }) => {
        const influences = quality_anomaly.split(", ");

        for (const influence of influences) {
          uniqueInfluences.add(influence);
        }
      });

      return uniqueInfluences;
    },

    legendList() {
      switch (this.pipePartsStore.selectedGradientMode) {
        case "material":
          return this.pipePartsStore.materials;
        case "quality_anomaly":
          return this.qualityInfluences;

        case "joint_type":
          return this.pipePartsStore.jointTypes;

        case "degradation_level":
          return this.degradationsWithPlastic
            ? getDegradationColorPP(this.$t)
            : getDegradationColorJoints(this.$t);

        default:
          return [];
      }
    },
  },

  methods: {
    onItemClick(key) {
      this.pipePartsStore.toggleSelectedLegend(this.getValues(key, "text"));
    },

    getValues(key, type) {
      switch (this.pipePartsStore.selectedGradientMode) {
        case "material":
          if (type === "text") {
            return this.$t(
              `pipePartsStore.materials.${materialColors[key][type]}`
            );
          } else {
            return materialColors[key][type];
          }

        case "remaining_life_calculation.soil_type":
          return soilTypeColors[key][type];

        case "remaining_life_calculation.road_type":
          return roadTypeColors[key][type];

        case "quality_anomaly":
          if (type === "text") {
            return key;
          } else {
            return qualityInfluenceColors[key.toLowerCase()];
          }

        case "degradation_level":
        case "remaining_life_calculation.remaining_life_group":
          return key[type];

        case "joint_type":
          if (type === "text") {
            return this.$t(`joints.types.${jointTypeColors[key][type]}`);
          } else {
            return jointTypeColors[key][type];
          }

        default:
          return "";
      }
    },

    computeClass(key) {
      const classes = [];
      const itemName = this.getValues(key, "text").toLowerCase();
      const isSelected =
        !this.pipePartsStore.selectedLegend ||
        this.pipePartsStore.selectedLegend.toLowerCase() === itemName;
      if (!this.degradationsWithPlastic) {
        classes.push("item");
      }
      if (isSelected) {
        classes.push("selected");
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 8px 12px;
  border-radius: 6px;
  width: fit-content;
  background-color: white;

  display: flex;
  flex-direction: column;

  th {
    font-size: 0.75rem;
    padding-right: 6px;
  }

  .row {
    cursor: pointer;
    opacity: 0.3;
    &.selected {
      opacity: 1;
    }
  }
  .dot {
    margin-right: 6px;
    margin-bottom: 3px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
}
</style>
