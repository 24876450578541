<template>
  <v-select
    :label="$t('safety_factor.filters.main_contributor')"
    :model-value="safetyFactorStore.filters.selectedMainUncertainties"
    @update:modelValue="
      (value) =>
        safetyFactorStore.setFilters({ selectedMainUncertainties: value })
    "
    :items="items"
    item-title="text"
    item-value="text"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { useSafetyFactorStore } from "@/store";

export default {
  props: {
    items: Array,
  },
  setup() {
    return {
      safetyFactorStore: useSafetyFactorStore(),
    };
  },
};
</script>
