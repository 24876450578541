<template>
  <SubtablePopupContainer>
    <AnomaliesSubtable
      v-if="pipePartsStore.anomaliesByPipePart.length > 0"
      :anomalies="pipePartsStore.anomaliesByPipePart"
    />
    <p v-else class="no-pipe-parts-message">No anomalies!</p>
  </SubtablePopupContainer>
</template>

<script>
import { SubtablePopupContainer } from "@/components";
import AnomaliesSubtable from "./AnomaliesSubtable.vue";
import { usePipePartsStore } from "@/store";

export default {
  name: "AnomaliesSubtablePopup",

  props: {
    selectedPipePart: Object,
  },

  components: { SubtablePopupContainer, AnomaliesSubtable },

  setup() {
    return { pipePartsStore: usePipePartsStore() };
  },
};
</script>

<style scoped lang="scss">
.no-pipe-parts-message {
  margin: 10px 20px;
}
</style>
