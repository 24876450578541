<template>
  <VBtn v-bind="$attrs" class="table-button" />
</template>

<script>
export default {
  name: "TableButton",
};
</script>

<style scoped lang="scss">
@use "./variables" as *;

.table-button.v-btn {
  width: $table-control-width;
  height: $table-control-height;
}

:deep(.v-icon) {
  opacity: $table-control-opacity;
}
</style>
