export const PipePartsWidget = {
  PipePartsTable: "pipePartsTable",
  Histogram: "histogram",
  HeightProfileChart: "heightProfileChart",
  Radar: "radar",
  GradientLegend: "gradientLegend",
  ColorsLegend: "colorsLegend",
  WMSWidget: "wmsWidget",
  WMSLegend: "wmsLegend",
};
