<template>
  <BaseWidget
    :title="$t('joints.widgets.table.maximize_title')"
    v-bind="$attrs"
  >
    <template v-slot:actions>
      <FilterPopupMenu
        v-model:show="filtersVisible"
        :hasActiveFilters="jointsStore.hasActiveFilters"
      />
    </template>

    <JointsTable />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { JointsTable } from "@/pages/joints/components";
import FilterPopupMenu from "../FilterPopupMenu";
import { useJointsStore } from "@/store";

export default {
  name: "JointsTableWidget",

  components: {
    BaseWidget,
    JointsTable,
    FilterPopupMenu,
  },

  setup() {
    return {
      jointsStore: useJointsStore(),
    };
  },

  data() {
    return {
      filtersVisible: false,
    };
  },
};
</script>
