<template>
  <BaseWidget v-bind="$attrs">
    <SafetyColorsLegend />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { SafetyColorsLegend } from "@/pages/safety-factor/components";

export default {
  name: "SafetyFactorColorsWidget",

  components: {
    BaseWidget,
    SafetyColorsLegend,
  },
};
</script>
