<template>
  <VirtualTable
    :headers="displayedHeaders"
    :items="pipePartsStore.filteredPipeParts"
    :header-slots="headerSlots"
    :item-slots="itemSlots"
    :is-item-active="isPipePartActive"
    @click:row="(pipePart) => $emit('click:row', pipePart.id)"
  >
    <template v-slot:[`header.select`]>
      <v-icon
        v-if="checkedLength !== 0"
        icon="mdi-minus-box-outline"
        size="small"
        @click="pipePartsStore.partsSelector.clear()"
      />
    </template>

    <template v-slot:[`item.select`]="{ item: pipePart }">
      <input
        :value="pipePart.id"
        :disabled="
          shouldDisableCheckbox(
            pipePart.id,
            pipePartsStore.filteredPipeParts,
            pipePartsStore.partsSelector
          )
        "
        :checked="pipePartsStore.partsSelector.has(pipePart.id)"
        @input="pipePartsStore.partsSelector.setByCtrl(pipePart.id)"
        @click.stop
        type="checkbox"
      />
    </template>

    <template v-slot:[`header.expand`]>
      <ExpandButton
        :is-expanded="headersExpanded"
        @click="headersExpanded = !headersExpanded"
      />
    </template>

    <template
      v-for="mode in MODES_WITH_LAYER_SELECT"
      :key="mode"
      v-slot:[`header.${mode}`]="{ column, getSortIcon, toggleSort }"
    >
      <SortableCustomHeader
        :column="column"
        :getSortIcon="getSortIcon"
        :toggleSort="toggleSort"
      >
        {{ $t(`pipe_parts.table_titles.wall_thickness.${mode}`) }}
        <LayerSelect
          v-if="wallThicknessRemaining[getWTLevel(mode)].isMultipleLayers"
          :wtLevel="getWTLevel(mode)"
        />
      </SortableCustomHeader>
    </template>

    <template
      v-for="mode in MODES_WITH_LAYER_SELECT"
      v-slot:[`item.${mode}`]="{ item: pipePart }"
    >
      <span
        :key="mode"
        class="layers-label"
        v-if="pipePart.wall_thickness_remaining_max[0].length > 1"
      >
        {{ `${getLayerName(pipePart, getWTLevel(mode))}` }}
      </span>
      {{ pipePart[mode] }}
    </template>
  </VirtualTable>
</template>

<script>
import { VirtualTable, ExpandButton, SortableCustomHeader } from "@/components";
import LayerSelect from "./LayerSelect.vue";

import { shouldDisableCheckbox } from "@/features/dig-up/helpers";
import { filterExpandableHeaders } from "@/utils/tableHelpers";

import { MODES_WITH_LAYER_SELECT } from "./config";
import { usePipePartsStore } from "@/store";

export default {
  name: "PipePartsTableRegular",

  components: { VirtualTable, LayerSelect, ExpandButton, SortableCustomHeader },

  props: {
    wallThicknessRemaining: Object,
    getWTLevel: Function,

    checkedLength: Number,
    getLayerName: Function,
    isPipePartActive: Function,
  },

  setup() {
    return {
      shouldDisableCheckbox,
      MODES_WITH_LAYER_SELECT,
      pipePartsStore: usePipePartsStore(),
    };
  },

  data() {
    return {
      headersExpanded: false,

      headers: [
        {
          title: this.$t("pipe_parts.table_titles.distance"),
          key: "distance",
          value: "distance",
          units: "m",
        },
        {
          title: this.$t("pipe_parts.table_titles.pipe_part_type"),
          key: "pipe_part_type",
          value: (pipePart) =>
            this.$t(`pipe_parts.types.${pipePart.pipe_part_type}`),
        },
        {
          title: this.$t("pipe_parts.table_titles.material"),
          key: "material",
          value: (pipePart) => this.$t(`materials.${pipePart.material}`),
        },
        {
          title: this.$t("pipe_parts.table_titles.length"),
          key: "length",
          value: "length",
          units: "m",
        },
        {
          title: this.$t("pipe_parts.table_titles.degradation_level"),
          key: "degradation_level",
          value: "degradation_level",
        },
        {
          title: this.$t("pipe_parts.table_titles.diameter_class"),
          key: "diameter_class",
          value: "diameter_class",
        },
        {
          title: this.$t("pipe_parts.table_titles.quality"),
          key: "quality",
          value: "quality",
        },
        {
          title: this.$t("pipe_parts.table_titles.quality_anomaly"),
          key: "quality_anomaly",
          value: "quality_anomaly",
        },
        {
          title: this.$t("pipe_parts.table_titles.remaining_life"),
          key: "remaining_life",
          value: "remaining_life_calculation.remaining_life",
          condition: false,
        },

        {
          title: this.$t("pipe_parts.table_titles.ovality.title"),
          key: "ovality_parent",
          children: [
            {
              title: this.$t("pipe_parts.table_titles.ovality.mean"),
              key: "ovality_mean",
              value: "ovality_mean",
            },
            {
              title: this.$t("pipe_parts.table_titles.ovality.max"),
              key: "ovality_max",
              value: "ovality_max",
            },
          ],
        },

        {
          title: this.$t("pipe_parts.table_titles.axial_deformation.title"),
          key: "axial_deformation_parent",
          units: "m",
          children: [
            {
              title: this.$t(
                "pipe_parts.table_titles.axial_deformation.horizontal"
              ),
              key: "horizontal_out_of_straight",
              value: "horizontal_out_of_straight",
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.axial_deformation.vertical"
              ),
              key: "vertical_out_of_straight",
              value: "vertical_out_of_straight",
            },
          ],
        },

        {
          title: this.$t("pipe_parts.table_titles.wall_thickness.title"),
          key: "wall_thickness_parent",
          units: "mm",
          children: [
            {
              title: this.$t("pipe_parts.table_titles.wall_thickness.original"),
              key: "wall_thickness_original",
              value: "wall_thickness_original",
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.remaining_mean"
              ),
              key: "remaining_mean",
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.remaining_min"
              ),
              key: "remaining_min",
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.remaining_std"
              ),
              key: "remaining_std",
              expandable: true,
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.remaining_max"
              ),
              key: "remaining_max",
              expandable: true,
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.degradated_mean"
              ),
              key: "degradated_total_mean",
              value: "degradated_total_mean",
              expandable: true,
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.degradated_min"
              ),
              key: "degradated_total_min",
              value: "degradated_total_min",
              expandable: true,
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.degradated_std"
              ),
              key: "degradated_total_std",
              value: "degradated_total_std",
              expandable: true,
            },
            {
              title: this.$t(
                "pipe_parts.table_titles.wall_thickness.degradated_max"
              ),
              key: "degradated_total_max",
              value: "degradated_total_max",
              expandable: true,
            },
          ],
        },

        {
          title: "",
          key: "expand",
        },

        {
          title: this.$t("pipe_parts.table_titles.comment"),
          key: "comment",
          value: "comment",
        },
      ],
    };
  },

  computed: {
    isRemainingLife() {
      if (this.pipePartsStore.filteredPipeParts.length) {
        return Boolean(
          this.pipePartsStore.filteredPipeParts[0].remaining_life_calculation
        );
      }

      return false;
    },

    displayedHeaders() {
      const expandableHeadersState = {
        wall_thickness_parent: this.headersExpanded,
      };

      return filterExpandableHeaders(this.headers, expandableHeadersState);
    },

    headerSlots() {
      const headerSlots = MODES_WITH_LAYER_SELECT.map(
        (mode) => `header.${mode}`
      );
      headerSlots.push("header.expand");
      return headerSlots;
    },

    itemSlots() {
      return MODES_WITH_LAYER_SELECT.map((mode) => `item.${mode}`);
    },
  },
};
</script>

<style scoped lang="scss">
.show-icon {
  width: 25px;
  height: 25px;

  &.hide {
    transform: rotate(180deg);
  }
}
</style>
