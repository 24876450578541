<template>
  <VueDragResize
    v-show="!minimized"
    :x="x"
    :y="y"
    :w="w"
    :h="h"
    :sticks="internalHandles"
    :isDraggable="draggable"
    :isResizable="resizable"
    :parentW="parentW"
    :parentH="parentH"
    :class="{ 'content-height-auto': h === 'auto' }"
    @dragging="dragWidget($event)"
    @resizing="resizeWidget($event)"
    class="acq-widget"
    parentLimitation
  >
    <slot />
  </VueDragResize>
</template>

<script>
import VueDragResize from "vue-drag-resize-via";

export default {
  name: "AcqWidget",

  emits: ["drag", "resize", "minimize"],

  components: {
    VueDragResize,
  },

  props: {
    draggable: {
      type: Boolean,
      default: true,
    },

    resizable: {
      type: Boolean,
      default: true,
    },

    minimizable: {
      type: Boolean,
      default: true,
    },

    minimized: {
      type: Boolean,
      required: true,
    },

    x: {
      type: Number,
      required: true,
    },

    y: {
      type: Number,
      required: true,
    },

    w: {
      type: [Number, String],
      required: true,
    },

    h: {
      type: [Number, String],
      required: true,
    },

    parentH: {
      type: Number,
      required: true,
    },

    parentW: {
      type: Number,
      required: true,
    },

    handles: {
      type: Array,
      default: () => ["tl", "tm", "tr", "mr", "ml", "br", "bm", "bl"],
    },
  },

  provide() {
    return {
      dragWidget: this.dragWidget,
      resizeWidget: this.resizeWidget,
      minimizeWidget: this.minimizeWidget,
      widgetConfig: this.widgetConfig,
    };
  },

  computed: {
    internalHandles() {
      if (!this.resizable) {
        return [];
      }

      return this.handles;
    },

    widgetConfig() {
      return {
        minimizable: this.minimizable,
        draggable: this.draggable,
        resizable: this.resizable,
      };
    },
  },

  methods: {
    dragWidget(newPosition) {
      this.$emit("drag", newPosition);
    },

    resizeWidget(newPosition) {
      this.$emit("resize", newPosition);
    },

    minimizeWidget() {
      this.$emit("minimize");
    },
  },
};
</script>

<style scoped lang="scss">
.acq-widget {
  // TODO: refactor z-index management
  :deep(.vdr-stick) {
    z-index: 1000;
  }

  :deep(.vdr-stick-tm),
  :deep(.vdr-stick-tl),
  :deep(.vdr-stick-tr) {
    top: 0px !important;
  }

  :deep(.vdr-stick-bm),
  :deep(.vdr-stick-bl),
  :deep(.vdr-stick-br) {
    bottom: 0px !important;
  }
}

.content-height-auto {
  :deep(.content-container) {
    height: fit-content !important;
  }
}
</style>
