<template>
  <TableContainer
    v-if="jointsStore.filteredJoints.length > 0"
    :resizable="mapStore.isMapMode"
  >
    <JointsTableMapmode
      v-if="mapStore.isMapMode"
      :checked-length="checkedLength"
      :is-joint-active="isJointActive"
      @click:row="onSelect"
    />
    <JointsTableRegular
      v-else
      :checked-length="checkedLength"
      :is-joint-active="isJointActive"
      @click:row="onSelect"
    />
    <DigUpTableBtn
      v-if="checkedLength > 0"
      :badgeCount="checkedLength"
      :isMapMode="mapStore.isMapMode"
    />
  </TableContainer>
</template>

<script>
import { TableContainer } from "@/components/table-components/containers";
import JointsTableRegular from "./JointsTableRegular.vue";
import JointsTableMapmode from "./JointsTableMapmode.vue";

import { DigUpTableBtn } from "@/features/dig-up/components";
import { useJointsStore, useMapStore } from "@/store";

export default {
  name: "JointsTable",

  components: {
    DigUpTableBtn,
    JointsTableRegular,
    JointsTableMapmode,
    TableContainer,
  },

  provide() {
    return {
      localePath: "joints.table_titles.",
    };
  },

  setup() {
    return {
      mapStore: useMapStore(),
      jointsStore: useJointsStore(),
    };
  },

  computed: {
    computedCellUnit() {
      if (this.jointsStore.selectedGradientMode.includes("angle")) {
        return "°";
      }
      if (this.jointsStore.selectedGradientMode.includes("gap_width")) {
        return "mm";
      }

      return undefined;
    },

    checkedLength() {
      return this.jointsStore.jointsSelector.size;
    },
  },

  methods: {
    isJointActive(joint) {
      return joint.id === this.jointsStore.selectedJoint?.id;
    },

    onSelect(id) {
      this.jointsStore.setSelectedJointId(id);
    },
  },
};
</script>
